import { Injectable } from "@angular/core";
import { Router, CanActivate, UrlTree } from "@angular/router";
import { OSRoles } from "@app/shared/enums/user-type-enum";
import { UserService } from "@app/shared/services/user.service";
import { UtilService } from "@app/shared/services/util.service";
import { UserExtended } from "@app/user/models/UserExtended";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class NewUserGuard implements CanActivate {
  constructor(public router: Router, public userService: UserService) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.userService
      .getUserExtended()
      .pipe(
        map((user) =>
          !user.new_user ? true : this.navigateToOnboardingPage(user)
        )
      );
  }

  protected navigateToOnboardingPage(user: UserExtended): UrlTree {
    if (
      (user.role === OSRoles.Teacher || user.role === OSRoles.DemoTeacher) &&
      !UtilService.isSchool24()
    ) {
      return this.buildUrlTree([`/scholen/welkom`]);
    } else {
      return this.buildUrlTree([`/onboarding`]);
    }
  }

  private buildUrlTree(fragments: string[]): UrlTree {
    return this.router.createUrlTree(fragments);
  }
}
