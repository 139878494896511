import { PublicUser } from "@app/user/models/PublicUser";

export class Group {
  id?: number;
  name!: string;
  level!: number;
  year!: number;
  school_year?: string;
  school!: number;

  constructor(group: Group) {
    Object.assign(this, group);
  }
}

export class GroupV3 {
  id!: number;
  name!: string;
  level!: number;
  year!: number;
  school_year!: string;
  school!: number;
  students!: PublicUser[];

  constructor(group: GroupV3) {
    Object.assign(this, group);
  }

  public static Factory(group: GroupV3): GroupV3 {
    return new GroupV3({
      ...group,
      students: group.students.map(student => PublicUser.Factory(student))
    });
  };
}
