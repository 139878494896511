import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@env/environment";
import { UtilService } from "./util.service";

@Injectable({
  providedIn: "root",
})
export class UserChatGroupService {
  userChatGroupUrl: string = environment.apiEndpoint + "/user-chat-groups/";

  constructor(private http: HttpClient, private utilService: UtilService) {}

  getUserChatGroups(params: Record<string, any>): Observable<string[]> {
    return this.http.get<string[]>(
      this.userChatGroupUrl +
        "?" +
        this.utilService.dictionaryToQueryParams(params)
    );
  }
}
