<div
  *ngIf="cell.type === TableCellType.Text"
  class="d-flex align-items-center font-14"
  [ngClass]="{
    'os-link': cell.url && cell.url !== '',
    'os-link-dark': cell.url && cell.url !== ''
  }"
  (click)="onLinkClicked($event, cell.url)"
  (mousedown)="onMiddleClick($event, cell.url)"
  [innerHTML]="cell.text"
></div>

<div
  *ngIf="cell.type === TableCellType.Number"
  class="d-flex align-items-center font-14"
>
  {{ cell.value | number : "1.0-1" }}
</div>

<div *ngIf="cell.type === TableCellType.Percentage" class="pe-3">
  <progress-bar
    class="d-none d-xl-block"
    [achieved]="cell.value ? cell.value : 0"
    [potential]="100"
    [label]="cell.value ? cell.value + '%' : '0%'"
  >
  </progress-bar>
  <span class="d-block d-xl-none">{{
    cell.value ? cell.value + "%" : "0%"
  }}</span>
</div>

<div
  *ngIf="cell.type === TableCellType.Grade"
  class="os-semi-bold"
  [ngClass]="{
    'os-text-red': cell.value < 5.5,
    'os-text-orange': cell.value >= 5.5 && cell.value < 6.5,
    'os-text-lightgreen': cell.value >= 6.5,
    'os-text-lightgrey': !cell.value
  }"
>
  {{ cell.value ? (cell.value | number : "1.1-1") : "-" }}
</div>

<div
  *ngIf="cell.type === TableCellType.Date"
  [ngClass]="{
    'os-text-lightgrey': cell.value === undefined
  }"
>
  {{ cell.value ? (cell.value | date : "dd MMM HH:mm") : "-" }}
</div>

<div *ngIf="cell.type === TableCellType.Status">
  <span
    *ngIf="cell.value === 0"
    [ngbPopover]="empty"
    popoverPlacement="top"
    triggers="mouseenter:mouseleave"
    >-</span
  >
  <i
    *ngIf="cell.value === 1"
    class="fa-solid fa-hourglass"
    [ngbPopover]="busy"
    popoverPlacement="top"
    triggers="mouseenter:mouseleave"
  ></i>
  <i
    *ngIf="cell.value === 2"
    class="fa-regular fa-circle-check"
    [ngbPopover]="done"
    popoverPlacement="top"
    triggers="mouseenter:mouseleave"
  ></i>

  <ng-template #empty>Niet begonnen</ng-template>
  <ng-template #busy>Bezig</ng-template>
  <ng-template #done>Afgerond</ng-template>
</div>

<div
  *ngIf="cell.type === TableCellType.Points"
  style="width: 42px; padding: 6px"
  class="text-center br-6 {{ cell.borderStyle }}"
  [ngClass]="{
    'os-text-red os-red-border os-background-light-red': cell.value === 0,
    'os-text-orange os-border-orange os-background-light-orange':
      cell.value > 0 && cell.value < cell.maxValue,
    'os-text-lightgreen os-lightgreen-border os-background-light-green':
      cell.value !== undefined && cell.value >= cell.maxValue,
    'os-text-lightgrey os-light-grey-border os-background-light-grey':
      cell.value === undefined
  }"
>
  <span *ngIf="cell.value === undefined">-</span>
  <span *ngIf="cell.value !== undefined"
    >{{ cell.value | number : "1.0-1" }}p</span
  >
</div>
