import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterLink } from "@angular/router";

type AvatarSize = "small" | "medium" | "medium-large" | "large" | "extra-large";

@Component({
  standalone: true,
  selector: "shared-avatar",
  templateUrl: "./avatar.component.html",
  styleUrls: ["./avatar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, RouterLink]
})
export class AvatarComponent implements OnInit {
  @Input() avatarUrl?: string;
  @Input() userName!: string;
  @Input() size: AvatarSize = "medium";
  @Input() displayEditButton = false;

  protected readonly colorClasses = ['blue', 'orange', 'pink'];
  // , , 'yellow', 'green',
  protected colorClass = '';

  ngOnInit(): void {
    this.colorClass = this.getColorFromName(this.userName, this.colorClasses);
  }

  private getColorFromName(name: string, colors: string[]): string {
    const hash = this.hashString(name);
    const colorIndex = Math.abs(hash) % colors.length;

    return colors[colorIndex];
  }

  private hashString(str: string): number {
    if (!str) return 0;

    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }
}
