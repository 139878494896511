import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonModule } from "@angular/common";
import { ClickOutsideDirective } from "@app/shared/directives";
import { BaseModalComponent, SubSubTitleComponent, SubTitleComponent } from "@app/shared/basic-components";
import { XPGainAction } from "@app/shared/services/xp.service";

@Component({
  standalone: true,
  selector: "xp-info-modal",
  templateUrl: "./xp-info-modal.component.html",
  styleUrls: ["./xp-info-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ClickOutsideDirective, BaseModalComponent, SubSubTitleComponent, SubTitleComponent]
})
export class XpInfoModalComponent implements OnInit {
  @Input() xpGainActions: XPGainAction[] = [];

  protected isLoading = false;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.isLoading = true;

    this.getData();
  }

  private getData() {
  }

  protected closeModal() {
    this.activeModal.close();
  }
}
