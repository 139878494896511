import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

@Component({
  standalone: true,
  selector: "shared-input",
  templateUrl: "./input.component.html",
  styleUrls: ["./input.component.scss"],
  imports: [CommonModule, ReactiveFormsModule]
})
export class InputComponent implements OnInit {
  @Input() group!: FormGroup;
  @Input() controlName!: string;
  @Input() maxLength!: number;
  @Input() errorMessage: string = "Dit veld is verplicht.";
  @Input() type: string = "text";
  @Input() placeHolder!: string;
  @Input() dataset!: string[];
  @Input() focus: boolean = false;
  @Input() autocomplete: string = "other";
  @Input() dataFormType: string = "other";
  @Input() size: string = "medium";

  @Output() enterPress: EventEmitter<void> = new EventEmitter();
  @Output() valueEntered: EventEmitter<void> = new EventEmitter();

  showPassword: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (this.focus) {
      const inputElement = document.getElementById(
        this.controlName
      ) as HTMLElement;
      setTimeout(() => {
        inputElement.focus();
      }, 0);
    }
  }

  onShowPassword() {
    this.showPassword = !this.showPassword;
    if (this.showPassword) {
      this.type === "text";
    } else {
      this.type === "password";
    }
  }

  onKeyUp(event: KeyboardEvent) {
    if (event.key === "Enter") {
      this.enterPress.emit();
    } else {
      this.valueEntered.emit();
    }
  }
}
