<div class="d-flex align-items-center">
  <div
    class="d-none d-xl-flex align-items-center"
    *ngFor="let breadcrumb of breadcrumbs; let i = index"
  >
    <div
      *ngIf="breadcrumb.url === ''"
      class="font-14 unselectable-breadcrumb os-semi-bold"
    >
      {{ breadcrumb.title }}
    </div>
    <a
      *ngIf="breadcrumb.url !== ''"
      [routerLink]="[getUrlWithoutQueryParams(breadcrumb.url)]"
      [queryParams]="getQueryParams(breadcrumb.url)"
      class="font-14 os-link"
    >
      {{ breadcrumb.title }}
    </a>
    <i
      *ngIf="i < breadcrumbs.length - 1"
      class="fa-solid fa-angle-right mx-1 mx-xl-2 font-8"
      style="margin-top: 2px"
    ></i>
  </div>
</div>

<div *ngIf="previousBreadcrumb" class="d-block d-xl-none">
  <a
    [routerLink]="[getUrlWithoutQueryParams(previousBreadcrumb.url)]"
    [queryParams]="getQueryParams(previousBreadcrumb.url)"
    class="font-14 os-link"
  >
    <i class="fa fa-chevron-left me-2"></i>{{ previousBreadcrumb.title }}
  </a>
</div>
