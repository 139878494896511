import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { Observable } from "rxjs";

import { environment } from "@env/environment";

import { PreSignUpUser } from "@app/models/PreSignUpUser";
import { OneTimeAuthCode } from "./models/OneTimeAuthCode";
import { UtilService } from "@app/shared/services/util.service";
import { UserProductToken } from "./models/UserProductToken";
import { AccountTypeEnum } from "@app/shared/enums/account-type-enum";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  // Login URL for OnlineSlagen Wordpress identity provider
  baseUrl: string = environment.apiEndpoint;
  wordpressLoginUrl: string = environment.apiEndpoint + "/login/";

  // Login URL for Learning Portal
  portalLoginUrl: string = environment.apiEndpoint + "/rest-auth/login/";

  // Login URL for Learning Portal
  portalLogoutUrl: string = environment.apiEndpoint + "/rest-auth/logout/";

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private utilService: UtilService
  ) {}

  createDemoStudentUser(data: {
    email: string;
    product: string;
    password?: string;
    first_name?: string;
    last_name?: string;
    school?: string;
  }) {
    return this.http.post<any>(
      environment.apiEndpoint + "/create-demo-student/",
      data,
      {
        observe: "response",
      }
    );
  }

  createDemoTeacherUser(firstName: string, lastName: string, email: string) {
    return this.http.post<any>(
      environment.apiEndpoint + "/create-demo-teacher/",
      {
        first_name: firstName,
        last_name: lastName,
        email: email,
        django_api_key: environment.djangoApiKey,
      }
    );
  }

  wordpressLogin(user: PreSignUpUser): Observable<any> {
    return this.http.post<any>(this.wordpressLoginUrl, user, {
      observe: "response",
    });
  }

  wordpressLoginS24(user: PreSignUpUser): Observable<any> {
    return this.http.post<any>(environment.apiEndpoint + "/s24-login", user, {
      observe: "response",
    });
  }

  learningPortalLogin(user: PreSignUpUser): Observable<any> {
    return this.http.post<any>(this.portalLoginUrl, {
      username: user["email"],
      password: user["password"],
    });
  }

  logout(): boolean {
    this.cookieService.delete("token", "/");
    return this.cookieService.get("token") == "";
  }

  getAccountType() {
    if (!this.isAuthenticated()) {
      if (UtilService.isSchool24()) {
        return AccountTypeEnum.S24PublicStudent;
      } else {
        return AccountTypeEnum.NoAccess;
      }
    } else {
      if (UtilService.isSchool24()) {
        return AccountTypeEnum.S24Student;
      } else {
        return AccountTypeEnum.OSStudent;
      }
    }
  }

  isAuthenticated(): boolean {
    return this.cookieService.get("token") != "";
  }

  createOneTimeAuthCode(username: string, mailType: string) {
    return this.http.post<any>(
      this.baseUrl + "/user/create-one-time-auth-code?mail_type=" + mailType,
      {
        username: username,
      }
    );
  }

  getOneTimeAuthCode(code: string): Observable<OneTimeAuthCode> {
    return this.http.post<OneTimeAuthCode>(
      this.baseUrl + "/user/one-time-auth-code",
      {
        one_time_auth_code: code,
      }
    );
  }

  getUserDirectLogin(code: string): Observable<string> {
    return this.http.post<string>(this.baseUrl + "/user/direct-login", {
      one_time_auth_code: code,
    });
  }

  resetPassword(code: string, password: string): Observable<string> {
    return this.http.post<string>(this.baseUrl + "/user/reset-password", {
      one_time_auth_code: code,
      password: password,
    });
  }

  eduixCallback(params: {
    redirectSessionID: string;
    signature: string;
  }): Observable<string> {
    return this.http.get<string>(
      this.baseUrl +
        "/osauth/eduix/callback?" +
        this.utilService.dictionaryToQueryParams(params)
    );
  }

  eduixUpdateCredits(): Observable<UserProductToken[]> {
    return this.http.get<UserProductToken[]>(
      this.baseUrl + "/osauth/eduix/update-credits"
    );
  }
}
