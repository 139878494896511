<div
  *ngIf="errorResponse"
  class="os-page-background scroll-padding pt-5"
>
  <div class="justify-content-center d-flex mb-3">
    <a href="#">
      <img
        *ngIf="!isSchool24"
        src="../../../../../assets/brand/os/logo/dark.svg"
        class="logo"
        alt="OnlineSlagen logo" />
      <img
        *ngIf="isSchool24"
        src="../../../../../assets/brand/s24/logo/dark.svg"
        class="logo"
        alt="School24 logo"
        width="160"
        height="50"
    /></a>
  </div>
  <h1 class="mb-3 text-center col-12">{{ errorTitle }}</h1>
  <h5 class="mb-4 text-center col-12 os-subtitle">
    {{ errorSubtitle }}
  </h5>

  <div class="col-12 text-center">
    <shared-button
      class="me-3"
      [text]="'Contactpagina'"
      [style]="'secondary'"
      (click)="goToContact()"
    >
    </shared-button>
    <shared-button [text]="'Vorige pagina'" (click)="goToPreviousPage()">
    </shared-button>
  </div>

  <div class="justify-content-center d-flex mt-2">
    <img [src]="imagePath" class="col-12 col-md-5" />
  </div>
</div>
