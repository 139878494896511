import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BaseModalComponent, InfoLabelComponent, TextInputAreaComponent } from "@app/shared/basic-components";
import { FormsModule } from "@angular/forms";

@Component({
  standalone: true,
  selector: "shared-rating",
  templateUrl: "./rating.component.html",
  styleUrls: ["./rating.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, BaseModalComponent, InfoLabelComponent, TextInputAreaComponent, FormsModule]
})
export class RatingComponent {
  @Input() rating!: number

  @Output() ratingChange = new EventEmitter<number>();
  @Output() commentAdded = new EventEmitter<string>();

  protected stars = Array(5);

  protected currentHoveredRating: number | null = null;
  protected isCommentModalOpen = false;
  protected comment = "";

  protected getIconClass(rating: number): string {
    return (rating <= (this.currentHoveredRating || this.rating)) ? 'fa-star' : 'fa-star-o';
  }

  protected onRatingHover(rating: number | null): void {
    this.currentHoveredRating = rating;
  }

  protected onRatingClicked(rating: number): void {
    if (rating === this.rating) return;

    this.rating = rating;
    this.ratingChange.emit(rating);

    if (rating < 3) {
      this.isCommentModalOpen = true;
    }
  }

  protected onSubmitRatingComment(): void {
    this.commentAdded.emit(this.comment);
    this.isCommentModalOpen = false;
    this.comment = "";
  }
}
