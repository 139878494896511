import { Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";

export type ProgressBarSize = "small" | "medium" | "large";

@Component({
  standalone: true,
  selector: "progress-bar",
  templateUrl: "./progress-bar.component.html",
  styleUrls: ["./progress-bar.component.scss"],
  imports: [CommonModule]
})
export class ProgressBarComponent implements OnInit {
  @Input() set achieved(achieved: number) {
    this._achieved = achieved;
    this.width = this.calculateBarWidth(this._achieved, this.potential);
  }
  @Input() potential!: number;
  @Input() caption!: string;
  @Input() label!: string;
  @Input() icon!: string;
  @Input() size: ProgressBarSize = "medium";

  _achieved!: number;
  width!: number;

  constructor() {}

  ngOnInit(): void {
    this.width = this.calculateBarWidth(this._achieved, this.potential);
  }

  calculateBarWidth(achievedPoints: number, potentialPoints: number) {
    if (potentialPoints === 0) return 0;
    return (achievedPoints / potentialPoints) * 100;
  }
}
