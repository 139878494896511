import {
  Directive,
  Output,
  EventEmitter,
  HostBinding,
  HostListener, Input,
} from "@angular/core";

@Directive({
  standalone: true,
  selector: "[appDnd]",
})
export class DndDirective {
  @HostBinding("class.drag-hover") dragHover!: boolean;
  @Output() fileDropped = new EventEmitter<any>();

  @HostListener("dragover", ["$event"]) onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dragHover = true;
  }

  @HostListener("dragleave", ["$event"]) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dragHover = false;
  }

  @HostListener("drop", ["$event"]) public ondrop(evt: InputEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dragHover = false;
    let files = evt.dataTransfer?.files ?? [];
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }
}
