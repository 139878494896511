<div class="flex-between-center search-bar mx-auto">
  <i
    class="d-flex align-items-center search-icon fa-solid fa-magnifying-glass font-20"
    style="color: #cbd5e0"
  ></i>
  <i
    *ngIf="searchValue"
    class="d-flex align-items-center clear-icon fa-solid fa-circle-xmark font-20 cursor-pointer"
    style="color: #cbd5e0"
    (click)="onClearClicked()"
  ></i>
  <input
    class="form-control"
    type="text"
    [placeholder]="placeholder"
    [(ngModel)]="searchValue"
    (ngModelChange)="onValueUpdated()"
    (keyup.enter)="onSearch()"
  />
</div>
