<div class="justify-content-start align-items-center d-flex">
  <div *ngIf="label" class="me-3">
    {{ label }}
  </div>
  <div
    class="os-text-secondary p-0 col-4 col-md-1 d-inline-block me-3 cursor-pointer"
    (clickOutside)="selectDayToggled = false"
    (click)="selectDayToggled = !selectDayToggled"
  >
    <div class="select">
      <div class="flex-between-center clearfix">
        <div class="float-start m-0 font-16 os-semi-bold w-100">
          {{ selectedDate?.getDate() }}
        </div>
        <i
          width="20px"
          [ngClass]="
            selectDayToggled ? 'fa fa-chevron-down' : 'fa fa-chevron-right'
          "
        ></i>
      </div>
      <div class="font-16 os-semi-bold select-unfolded" *ngIf="selectDayToggled">
        <div
          *ngFor="let day of getDayOptions()"
          (click)="onDaySelected(day)"
          class="unfold-item"
        >
          <span *ngIf="day !== selectedDate.day">{{ day }}</span>
        </div>
      </div>
    </div>
  </div>

  <div
    class="os-text-secondary p-0 col-7 col-md-2 d-inline-block me-3 cursor-pointer"
    (click)="selectMonthToggled = !selectMonthToggled"
    (clickOutside)="selectMonthToggled = false"
  >
    <div class="select">
      <div class="flex-between-center clearfix">
        <div class="float-start m-0 font-16 os-semi-bold w-100">
          {{ monthOptions[selectedDate?.getMonth()].name }}
        </div>
        <i
          width="20px"
          [ngClass]="
            selectMonthToggled ? 'fa fa-chevron-down' : 'fa fa-chevron-right'
          "
        ></i>
      </div>
      <div class="font-16 os-semi-bold select-unfolded" *ngIf="selectMonthToggled">
        <div
          *ngFor="let month of monthOptions"
          (click)="onMonthSelected(month)"
          class="unfold-item"
        >
          <span *ngIf="month !== selectedDate.month">{{ month.name }}</span>
        </div>
      </div>
    </div>
  </div>

  <div
    class="os-text-secondary p-0 col-12 col-md-2 d-block d-md-inline-block me-3 cursor-pointer"
    (clickOutside)="selectYearToggled = false"
    (click)="selectYearToggled = !selectYearToggled"
  >
    <div class="select">
      <div class="flex-between-center clearfix">
        <div class="float-start m-0 font-16 os-semi-bold w-100">
          {{ selectedDate?.getFullYear() }}
        </div>
        <i
          width="20px"
          [ngClass]="
            selectYearToggled ? 'fa fa-chevron-down' : 'fa fa-chevron-right'
          "
        ></i>
      </div>
      <div class="font-16 os-semi-bold select-unfolded" *ngIf="selectYearToggled">
        <div
          *ngFor="let year of yearOptions"
          (click)="onYearSelected(year)"
          class="unfold-item"
        >
          <span *ngIf="year !== selectedDate.year">{{ year }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
