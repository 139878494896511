import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  inject,
  Inject,
  Input,
  Output
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { MAT_SNACK_BAR_DATA, MatSnackBarModule, MatSnackBarRef } from "@angular/material/snack-bar";
import { MatButtonModule } from "@angular/material/button";
import { XpInfoModalComponent } from "@app/shared/feature-components/modals/xp-info-modal/xp-info-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { XPService } from "@app/shared/services/xp.service";

@Component({
  standalone: true,
  selector: "shared-xp-notification",
  templateUrl: "./xp-notification.component.html",
  styleUrls: ["./xp-notification.component.scss"],
  imports: [CommonModule, MatSnackBarModule, MatButtonModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class XpNotificationComponent {
  @Input() text!: string;

  @Output() mouseHovered = new EventEmitter<boolean>();

  @HostListener('mouseenter') protected onMouseEnter() {
      this.mouseHovered.emit(true);
  }

  @HostListener('mouseleave') protected onMouseLeave() {
      this.mouseHovered.emit(false);
  }

  protected snackBarRef = inject(MatSnackBarRef);
  private modalService = inject(NgbModal);
  private XPService = inject(XPService);

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string) { }

  protected onOpenXpInfoModal(): void {
    this.XPService.getXpGainActions().subscribe(actions => {
      const modalRef = this.modalService.open(XpInfoModalComponent, { animation: false, backdrop: false });
      (modalRef.componentInstance as XpInfoModalComponent).xpGainActions = actions;
    })
  }
}
