import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
  standalone: true,
  selector: "shared-checkbox-list",
  templateUrl: "./checkbox-list.component.html",
  styleUrls: ["./checkbox-list.component.scss"],
  imports: [CommonModule]
})
export class CheckboxListComponent<TItem> implements OnInit {
  @Input() title!: string;
  @Input() items!: TItem[];
  @Input() selectedItems!: TItem[];
  @Input() itemKey!: string;
  @Input() idKey!: string;

  @Output() listChanged: EventEmitter<any[]> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {}

  onItemSelected(item: TItem) {
    var index = this.selectedItems.indexOf(item);
    if (index > -1) {
      this.selectedItems.splice(index, 1);
    } else {
      this.selectedItems.push(item);
    }
    this.listChanged.emit(this.selectedItems);
  }

  toggleSelectAll() {
    if (this.selectedItems.length > 0) {
      this.selectedItems = [];
    } else {
      this.selectedItems = [...this.items];
    }
    this.listChanged.emit(this.selectedItems);
  }
}
