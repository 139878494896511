<div class="col-12 col-xl-6 px-2 px-md-3 px-xl-0 mx-auto mt-4 mt-xl-4">
  <shared-title [title]="attachment?.title" class="d-block mb-3"></shared-title>
  <div *ngFor="let file of attachment?.files" class="mb-2">
    <img *ngIf="file.type === 'image'" src="{{ file.url }}" />

    <div *ngIf="file.type === 'audio'" class="audio-box">
      <h5 *ngIf="file.title" class="os-bold font-18 mb-2">{{ file.title }}</h5>
      <audio
        class="col-12 col-md-9 outline-none"
        #audio
        controlsList="nodownload"
        controls
        [hidden]="!file.url"
      >
        <source [src]="file.url" />
      </audio>
    </div>

    <div *ngIf="file.type === 'video'" class="video-box">
      <h5 *ngIf="file.title" class="os-bold font-18 mb-2">{{ file.title }}</h5>
      <video
        *ngIf="file.type === 'video'"
        class="col-12 col-md-9 outline-none"
        #video
        playsinline
        controls
        crossorigin
      >
        <source
          [src]="file.url | safe"
          type="video/mp4"
          (error)="this.isError = true"
        />
      </video>
    </div>

    <div *ngIf="file.type === 'pdf'" class="file-box">
      <div class="os-link" (click)="onPdfClicked(file.url)">
        {{ file.title }}
      </div>
    </div>
  </div>
</div>
