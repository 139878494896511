import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TableCellType } from "@app/shared/enums/table-cell-types-enum";
import { TableCell } from "@app/shared/models/TableCell";
import { CommonModule } from "@angular/common";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { ProgressBarComponent } from "@app/shared/basic-components/progress/progress-bar/progress-bar.component";

@Component({
  standalone: true,
  selector: "table-cell",
  templateUrl: "./cell.component.html",
  styleUrls: ["./cell.component.scss"],
  imports: [CommonModule, NgbPopover, ProgressBarComponent],
})
export class TableCellComponent implements OnInit {
  @Input() cell!: TableCell;
  @Input() column!: number;

  TableCellType = TableCellType;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  onMiddleClick(event: MouseEvent, url: string): void {
    if (!url) return;

    if (event.button === 1) {
      window.open(url, "_blank");
    }
  }

  onLinkClicked(event: MouseEvent, url: string) {
    if (!url) return;

    if (event.ctrlKey) {
      window.open(url, "_blank");
    } else {
      this.router.navigateByUrl(url);
    }
  }
}
