<div class="d-flex align-items-center gap-2">
  <div class="flex-grow-1 d-flex align-items-center">
    <div class="icon-wrapper flex-shrink-0 flex-center-center" [ngbTooltip]="tooltipText"><i class="{{ iconClass }}"></i></div>
    <div class="progress-bar flex-grow-1">
      <span
        class="progress-bar-piece filled"
        [style.width.%]="width"
      ></span>
      <span
        class="progress-bar-piece empty"
        [style.width.%]="100 - width"
      ></span>
    </div>
  </div>
  <div *ngIf="showProgressCaption" class="flex-center-center font-14 os-semi-bold">
    <div>{{ _currentValue | number : "1.0-1" }}<span class="mx-1">/</span>{{ maxValue | number : "1.0-1" }}
    </div>
  </div>
</div>
