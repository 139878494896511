import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "employee-nr-modal",
  templateUrl: "./employee-nr-modal.component.html",
  styleUrls: ["./employee-nr-modal.component.scss"],
})
export class EmployeeNrModalComponent implements OnInit {
  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit() {}

  closeModal() {
    this.activeModal.close();
  }
}
