<h1 class="os-h6-arvo d-block d-md-none mb-0">
  <span class="os-bold">{{ title }}</span>
  <span *ngIf="titlePart2">
    | <span class="os-text-lightgrey">{{ titlePart2 }}</span></span
  >
</h1>
<h1 class="os-h5-arvo d-none d-md-block d-xl-none mb-0">
  <span class="os-bold">{{ title }}</span>
  <span *ngIf="titlePart2">
    | <span class="os-text-lightgrey">{{ titlePart2 }}</span></span
  >
</h1>
<h1 class="os-h4-arvo d-none d-xl-block mb-0">
  <span class="os-bold">{{ title }}</span>
  <span *ngIf="titlePart2">
    | <span class="os-text-lightgrey">{{ titlePart2 }}</span></span
  >
</h1>
