<div class="dark-background"></div>

<div
  class="no-background"
  [ngClass]="{ 'full-screen': modalSize === 'fullScreen' }"
>
  <div
    (clickOutside)="onClickOutside(); $event.stopPropagation()"
    class="modal-body mx-2 mx-md-auto {{ modalBodyClass }}"
  >
    <div *ngIf="showCloseIcon" class="col-12 px-0 flex-between-center mb-4">
      <shared-sub-title [title]="title"></shared-sub-title>
      <i
        class="fa-solid fa-xmark os-text-red cursor-pointer"
        (click)="onCloseWindow()"
      ></i>
    </div>
    <div class="content">
      <ng-container *ngIf="!isLoading; else loadingTemplate">
        <ng-content></ng-content>
      </ng-container>

      <ng-template #loadingTemplate>
        <loading></loading>
      </ng-template>
    </div>
    <div *ngIf="showFooter" class="buttons-container mt-4">
      <shared-button
        [text]="'Sluiten'"
        [style]="'secondary'"
        (click)="onCloseWindow()"
      ></shared-button>
      <shared-button
        [text]="verificationButtonText"
        [isDisabled]="submitDisabled"
        (click)="onSubmit()"
      ></shared-button>
    </div>
  </div>
</div>
