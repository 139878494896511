import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable } from "rxjs";

import { UserFeedbackForm } from "@app/feedback/models/UserFeedbackForm";
import { FeedbackFormQuestion } from "@app/feedback/models/FeedbackFormQuestion";
import { UserFeedbackAnswer } from "@app/feedback/models/UserFeedbackAnswer";

import { environment } from "@env/environment";
import { ContentBugReport } from "./models/ContentBugReport";

@Injectable({
  providedIn: "root",
})
export class FeedbackService {
  feedbackFormUrl: string = environment.apiEndpoint + "/feedback/";

  constructor(private http: HttpClient) {}

  getUserFeedbackForm(id: number): Observable<UserFeedbackForm> {
    return this.http.get<UserFeedbackForm>(
      this.feedbackFormUrl + "user-feedback-form/" + id + "/"
    );
  }

  updateUserFeedbackFormShown(
    id: number,
    shown: boolean
  ): Observable<UserFeedbackForm> {
    return this.http.patch<UserFeedbackForm>(
      this.feedbackFormUrl + "user-feedback-form/" + id + "/",
      { shown: shown }
    );
  }

  getFeedbackFormQuestions(id: number): Observable<FeedbackFormQuestion[]> {
    return this.http.get<FeedbackFormQuestion[]>(
      this.feedbackFormUrl + "feedback-form-questions/" + id + "/"
    );
  }

  createUserFeedbackFormAnswer(
    questionId: number,
    answer: any
  ): Observable<UserFeedbackAnswer> {
    return this.http.post<UserFeedbackAnswer>(
      this.feedbackFormUrl + "user-feedback-answer/",
      {
        feedbackQuestionId: questionId,
        answer: answer,
      }
    );
  }

  getAlluserFeedbackFormAnswers(): Observable<UserFeedbackAnswer[]> {
    return this.http.get<UserFeedbackAnswer[]>(
      this.feedbackFormUrl + "user-feedback-answer/"
    );
  }

  createContentBugReport(trainingId: number, data: ContentBugReport): Observable<ContentBugReport> {
    return this.http.post<ContentBugReport>(
      environment.apiEndpoint + "/feedback/trainings/" + trainingId + "/content-bug-reports",
      data
    );
  }

  uploadBugReportFile(file: FormData): Observable<{ file_url: string }> {
    return this.http.post<{ file_url: string }>(
      this.feedbackFormUrl + "content-bug-reports/files",
      file
    );
  }
}
