import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ButtonComponent } from "../button/button.component";
import { ClickOutsideDirective } from "@app/shared/directives/click-outside.directive";

@Component({
  standalone: true,
  selector: "shared-button-selector",
  templateUrl: "./button-selector.component.html",
  styleUrls: ["./button-selector.component.scss"],
  imports: [CommonModule, ButtonComponent, ClickOutsideDirective]
})
export class ButtonSelectorComponent implements OnInit {
  @Input() list!: any[];
  @Input() key!: string;
  @Input() text!: string;
  @Input() direction: "right" | "left" = "right";

  @Output() itemSelect: EventEmitter<any> = new EventEmitter();

  selectedItem!: any;
  showList!: boolean;

  constructor() {}

  ngOnInit(): void {}

  onItemSelected(item: any) {
    this.selectedItem = item;
    this.itemSelect.emit(item);
  }

  onShowListClicked() {
    if (this.list.length > 0) {
      this.showList = !this.showList;
    }
  }
}
