<div class="d-flex align-items-center flex-wrap">
  <div
    *ngFor="let chip of chips"
    class="flex-between-center chip  me-2 mb-2 os-semi-bold"
    [ngClass]="{
      'chip-active': _selectedChips?.includes(chip),
      'chip-disabled': disabled && !_selectedChips?.includes(chip),
      'br-20': mode === 'single',
      'br-6': mode === 'multiple'
    }"
    (click)="onChipClicked(chip)"
  >
    <i
      *ngIf="_selectedChips?.includes(chip)"
      class="fa-regular pe-2 font-14"
      [ngClass]="{
        'fa-square-check': mode === 'multiple',
        'fa-circle-check': mode === 'single'
      }"
    ></i>
    <i
      *ngIf="!_selectedChips?.includes(chip)"
      class="pe-2 font-14 fa-regular"
      [ngClass]="{
        'fa-square': !disabled && mode === 'multiple',
        'fa-circle': !disabled && mode === 'single',
        'fa-solid fa-ban': disabled
      }"
    ></i>
    <ng-container *ngTemplateOutlet="customChipTemplate ? customChipTemplate : defaultChipTemplate; context: { $implicit: chip }"></ng-container>
  </div>
</div>

<ng-content></ng-content>

<ng-template #defaultChipTemplate let-chip>
  {{ chip }}
</ng-template>
