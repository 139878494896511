import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AttachmentService } from "@app/shared/services/attachment.service";
import { File, FileTypes } from "@app/shared/models/File";
import { CommonModule } from "@angular/common";
import { ButtonComponent, InputComponent, SelectorComponent } from "@app/shared/basic-components";

@Component({
  standalone: true,
  selector: "shared-uploaded-file",
  templateUrl: "./uploaded-file.component.html",
  styleUrls: ["./uploaded-file.component.scss"],
  imports: [CommonModule, SelectorComponent, InputComponent, ButtonComponent]
})
export class UploadedFileComponent implements OnInit {
  @Input() file!: File;
  @Output() fileDelete: EventEmitter<File> = new EventEmitter();
  @Output() fileUpdate: EventEmitter<File> = new EventEmitter();

  fileForm = new FormGroup({
    index: new FormControl("", [Validators.required]),
    width: new FormControl<number | undefined>(undefined),
    title: new FormControl("", [Validators.required]),
  });

  fileTypes: FileTypes[] = ["image", "audio", "video", "pdf"];
  fileEditToggle!: boolean;

  constructor(private attachmentService: AttachmentService) {}

  ngOnInit(): void {
    this.fileForm.get("index")?.setValue(this.file.index.toString());
    this.fileForm.get("title")?.setValue(this.file.title);
    this.fileForm.get("width")?.setValue(this.file.width as number);
  }

  onFileTypeSelected(fileType: FileTypes) {
    this.file.type = fileType;
    this.updateFile();
  }

  submitFile() {
    this.fileEditToggle = false;
    this.file.index = +(this.fileForm.get("index")?.value as string);
    this.file.title = this.fileForm.get("title")?.value as string;
    this.file.width = +(this.fileForm.get("width")?.value as number);
    if (this.file.width === 0) this.file.width = null;
    this.updateFile();
  }

  updateFile() {
    this.attachmentService.updateFile(this.file).subscribe({
      next: (file) => {
        this.fileUpdate.emit(file);
      },
    });
  }

  onDeleteFileClicked() {
    this.fileDelete.emit(this.file);
  }

  onFileClicked(file: File) {
    window.open(file.url, "blank");
  }
}
