<div *ngIf="currentOnboardingVideo">
  <div class="flex-between-top border-bottom py-3 px-4">
    <div class="d-none d-xl-block os-bold font-26">Uitlegvideo's</div>
    <div class="d-block d-xl-none os-bold font-26">
      {{ currentOnboardingVideo.title }}
    </div>

    <i
      class="cursor-pointer fa fa-times os-text-red"
      (click)="closeModal()"
    ></i>
  </div>
  <div class="d-flex pb-3 pb-xl-0">
    <div
      class="border-end d-none d-xl-block"
      style="height: 570px; overflow-y: auto; width: 20%"
    >
      <div
        *ngFor="let onboardingVideo of onboardingVideos"
        class="py-3 px-4 border-bottom os-semi-bold cursor-pointer"
        [ngClass]="{
          'os-background-light-grey':
            onboardingVideo.id === currentOnboardingVideo.id
        }"
        (click)="onOnboardingVideoClicked(onboardingVideo)"
      >
        {{ onboardingVideo.index }}. {{ onboardingVideo.title }}
      </div>
    </div>
    <div class="content">
      <div class="col-xl-10 mx-auto text-center">
        <shared-video
          *ngIf="video"
          class="d-block mb-3"
          [video]="video"
          [startTime]="0"
          (videoStatusChanged)="onVideoStatusChanged($event)"
          (timeChange)="onTimeChanged($event)"
        ></shared-video>

        <div *ngIf="userExtended.show_onboarding_videos">
          <shared-button
            class="me-3"
            [text]="'Laat geen video\'s meer zien'"
            [style]="'secondary'"
            (click)="onDisableVideosClicked()"
          ></shared-button>
          <shared-button
            [text]="'Verder'"
            (click)="closeModal()"
          ></shared-button>
        </div>
      </div>
    </div>
  </div>
</div>
