<div class="tile os-text-dark">
  <div class="flex-grow-1 flex-between-center p-3 cursor-pointer os-block-3" [class.selected]="isSelected">
    <div class="d-flex align-items-center gap-3">
      <div class="flex-center-center" style="width: 32px; height: 32px">
        <ng-container *ngIf="tile.iconUrl; else iconTemplate">
          <img [src]="tile.iconUrl" width="32px"/>
        </ng-container>
      </div>

      <div>
        <div class="os-semi-bold font-16 os-2-lines" [class.text-uppercase]="isUppercase">
          {{ tile.text }}
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #iconTemplate>
  <i class="{{ tile.iconClass ? tile.iconClass : isSelected ? 'fa fa-check-square-o' : 'fa fa-square-o' }} font-24"></i>
</ng-template>
