import { Directive, HostListener, inject, InjectionToken, Input, OnDestroy, OnInit, } from "@angular/core";

export interface ExpandableComponent {
  isExpanded: boolean;
}

export const EXPANDABLE_COMPONENT = new InjectionToken<ExpandableComponent>('Expandable Component');

@Directive({
  standalone: true,
  selector: "[saveExpandedState]",
})
export class SaveExpandedStateDirective implements OnInit, OnDestroy {
  @Input() stateKey!: string;

  private readonly hostComponent = inject(EXPANDABLE_COMPONENT);
  private readonly directiveKeyPrefix = "save-expanded-state-";

  @HostListener('window:beforeunload') onBeforeUnload(): void {
    this.ngOnDestroy();
  }

  ngOnInit(): void {
    const localStorageItem = localStorage.getItem(`${this.directiveKeyPrefix}${this.stateKey}`);
    this.hostComponent.isExpanded = localStorageItem ? JSON.parse(localStorageItem) : this.hostComponent.isExpanded;
  }

  ngOnDestroy(): void {
    localStorage.setItem(`${this.directiveKeyPrefix}${this.stateKey}`, JSON.stringify(this.hostComponent.isExpanded));
  }
}
