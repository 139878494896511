import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

import { Observable } from "rxjs";

import { environment } from "@env/environment";

import { Place } from "@app/shared/enums/place-enum";

import { UtilService } from "./util.service";

import { Category } from "@app/models/onboarding/Category";
import { UserAction } from "@app/models/onboarding/UserAction";
import { OnboardingVideoComponent } from "@app/shared/feature-components/modals";
import { UserOnboardingVideo } from "@app/models/onboarding/UserOnboardingVideo";
import { OnboardingVideo } from "@app/models/onboarding/OnboardingVideo";
import { UserExtended } from "@app/user/models/UserExtended";

@Injectable({
  providedIn: "root",
})
export class OnboardingService {
  onboardingUrl: string = environment.apiEndpoint + "/onboarding/";

  videoModalRef!: NgbModalRef;

  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    private modalService: NgbModal
  ) {}

  getCheckLists(params?: {
    parent?: boolean;
    user_role?: string;
    product?: string;
  }): Observable<Category[]> {
    return this.http.get<Category[]>(
      this.onboardingUrl +
        "categories/?" +
        this.utilService.dictionaryToQueryParams(params)
    );
  }

  getUserActions(params?: { user_role?: string }): Observable<UserAction[]> {
    return this.http.get<UserAction[]>(
      this.onboardingUrl +
        "user-actions/?" +
        this.utilService.dictionaryToQueryParams(params)
    );
  }

  updateUserAction(userAction: UserAction): Observable<UserAction> {
    return this.http.patch<UserAction>(
      this.onboardingUrl + "user-actions/" + userAction.id,
      userAction
    );
  }

  getOnboardingVideos(params?: {
    place?: Place;
    user_role?: string;
  }): Observable<OnboardingVideo[]> {
    return this.http.get<OnboardingVideo[]>(
      this.onboardingUrl +
        "videos/?" +
        this.utilService.dictionaryToQueryParams(params)
    );
  }

  createOnboardingUserVideo(body: Partial<UserOnboardingVideo>): Observable<UserOnboardingVideo> {
    return this.http.post<UserOnboardingVideo>(
      this.onboardingUrl + "user-videos",
      body
    );
  }

  showOnboardingVideo(place: Place, userExtended: UserExtended) {
    if (userExtended.show_onboarding_videos && !UtilService.isSchool24()) {
      this.getOnboardingVideos({
        place: place,
        user_role: userExtended.role,
      }).subscribe({
        next: (onboardingVideos: OnboardingVideo[]) => {
          if (onboardingVideos[0]?.watch_records.length < 1) {
            this.openOnboardingVideoModal(userExtended, onboardingVideos[0]);
            this.createOnboardingUserVideo({
              onboarding_video: onboardingVideos[0].id,
            }).subscribe({
              next: () => {},
            });
          }
        },
      });
    }
  }

  openOnboardingVideoModal(
    userExtended: UserExtended,
    onboardingVideo?: OnboardingVideo
  ) {
    this.videoModalRef = this.modalService.open(OnboardingVideoComponent, {
      size: "xxl",
    });
    this.videoModalRef.componentInstance.userExtended = userExtended;
    this.videoModalRef.componentInstance.onboardingVideo = onboardingVideo;
  }
}
