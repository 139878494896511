import { Component, Input } from "@angular/core";

@Component({
  standalone: true,
  selector: "shared-sub-sub-title",
  templateUrl: "./sub-sub-title.component.html",
  styleUrls: ["./sub-sub-title.component.scss"]
})
export class SubSubTitleComponent {
  @Input() title!: string;
}
