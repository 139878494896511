import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { UtilService } from "@app/shared/services/util.service";

import { Observable, of, tap } from "rxjs";

import { environment } from "@env/environment";

import { Product } from "../../shop/models/Product";
import { Order } from "../../shop/models/Order";
import { Subscription } from "../../shop/models/Subscription";

interface ProductsState {
  [key: string]: Product[];
}

@Injectable({
  providedIn: "root",
})
export class ShopService {
  protected productsState: ProductsState = {};

  constructor(private http: HttpClient, private utilService: UtilService) {}

  getProducts(params?: { product_line: string }): Observable<Product[]> {
    return params && this.productsState[params.product_line]
      ? of(this.productsState[params.product_line])
      : this.http.get<Product[]>(
      environment.apiEndpoint +
        "/sales/products?" +
        this.utilService.dictionaryToQueryParams(params)
    ).pipe(tap(products => { if (params?.product_line) this.productsState[params.product_line] = products }));
  }

  getOrder(id: number): Observable<Order> {
    return this.http.get<Order>(
      environment.apiEndpoint + "/sales/orders/" + id
    );
  }

  createOrder(data: { email: string; products: number[], django_api_key?: string }) {
    data["django_api_key"] = environment.djangoApiKey;
    return this.http.post<string>(
      environment.apiEndpoint + "/sales/orders",
      data
    );
  }

  createSubscription(orderId: number) {
    return this.http.post<Order>(
      environment.apiEndpoint + "/sales/orders/" + orderId + "/subscriptions",
      {}
    );
  }

  getUserOrders(params?: { status?: number }): Observable<Order[]> {
    return this.http.get<Order[]>(
      environment.apiEndpoint +
        "/sales/user/orders?" +
        this.utilService.dictionaryToQueryParams(params)
    );
  }

  getUserSubscriptions(): Observable<Subscription[]> {
    return this.http.get<Subscription[]>(
      environment.apiEndpoint + "/sales/user/subscriptions"
    );
  }

  updateSubscription(subscription: Partial<Subscription>): Observable<Subscription> {
    return this.http.put<Subscription>(
      environment.apiEndpoint + "/sales/subscriptions/" + subscription.id,
      subscription
    );
  }
}
