export class GlobalConstants {
  public static DEMO_SCHOOL_ID: number = 3;
  public static ONLINESLAGEN_COLLEGE_SCHOOL_ID: number = 730;
  public static S24_DEMO_SCHOOL_ID: number = 781;
  public static DemoSchoolIds: number[] = [3, 781];
  public static DemoGroupIds: number[] = [15, 16];
  public static DemoSchoolNames: string[] = ["Demo school", "Demo School24"];

  public static NO_ACCESS: number = 0;
  public static DEMO_ACCESS: number = 1;
  public static CE_ACCESS: number = 2;
  public static CE_AND_SE_ACCESS: number = 3;

  public static CURRENT_SCHOOL_YEAR: string = "2023/2024";

  public static FirstFeedbackFormId: number = 1;
  public static SecondFeedbackFormId: number = 2;

  public static BiologieVwoId: number = 7;
  public static EconomieVwoId: number = 5;
}
