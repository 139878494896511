import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ManagementService } from "@app/management/management.service";
import { CommonModule } from "@angular/common";
import { ErrorMessageComponent } from "@app/shared/basic-components";
import { DndDirective } from "@app/shared/directives";

@Component({
  standalone: true,
  selector: "upload-file-component",
  templateUrl: "./upload-file.component.html",
  styleUrls: ["./upload-file.component.scss"],
  imports: [CommonModule, ErrorMessageComponent, DndDirective]
})
export class UploadFileComponent implements OnInit {
  @Input() trainingId!: number;
  @Input() fileUrl!: string;

  @Output() fileUpload: EventEmitter<string> = new EventEmitter();
  @Output() fileRemove: EventEmitter<void> = new EventEmitter();

  message!: string;
  isError!: boolean;
  isLoading!: boolean;
  MAX_SIZE = 5e7;
  fileTypes = ["application/pdf", "image/png", "image/jpg"];

  constructor(private managementService: ManagementService) {}

  ngOnInit(): void {}

  onFileDropped(event: any) {
    this.checkFiles(event);
  }

  onUrlClicked(url: string) {
    window.open(url, "_blank");
  }

  checkFiles(files: FileList) {
    if (files.length > 1) {
      this.message = "Slechts één bestand is toegestaan";
    } else {
      const fileFile = files[0];
      this.isValidFile(fileFile);
    }
  }

  onFileAdded(event: any) {
    const file = event.target.files[0];
    this.isValidFile(file);
  }

  isValidFile(file: File) {
    if (this.validFileType(file) && this.validFileSize(file)) {
      this.message = "";
      this.setFormData(file);
    } else if (!this.validFileType(file)) {
      this.message = "Formaat is niet geldig";
    } else if (!this.validFileSize(file)) {
      this.message = "Video is groter dan 50MB";
    }
  }

  validFileType(file: File) {
    return this.fileTypes.includes(file.type);
  }

  validFileSize(videoFile: File) {
    return videoFile.size < this.MAX_SIZE;
  }

  setFormData(file: File) {
    this.isLoading = true;
    const formData: FormData = new FormData();
    formData.append("file", file, file.name);
    this.uploadFile(formData);
  }

  uploadFile(formData: FormData) {
    this.managementService.uploadFile(formData, this.trainingId).subscribe({
      next: (createdVideo: any) => {
        this.cleanErrorAndMessage();
        this.fileUrl = createdVideo.file_url;
        this.fileUpload.emit(this.fileUrl);
      },
      error: () => {
        this.message = "Er ging iets fout";
        this.isError = true;
        this.isLoading = false;
      },
    });
  }

  removeFile() {
    this.fileRemove.emit();
    this.fileUrl = "";
    this.cleanErrorAndMessage();
  }

  cleanErrorAndMessage() {
    this.message = "";
    this.isLoading = false;
    this.isError = false;
  }
}
