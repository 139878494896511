<div class="checklists os-block-3">
  <div *ngIf="currentPage === 'welcome'">
    <header class="border-bottom pb-3 mb-3">
      <p class="os-text-dark os-bold font-22 mb-0">Acties</p>
    </header>
    <div class="border-bottom pb-3 mb-3">
      <p>Hier vind je jouw openstaande en afgeronde acties.</p>
      <p>
        Je hebt momenteel 1 checklist genaamd 'Introductie checklist'. De acties
        in deze lijst geven je een rondleiding door de hele leeromgeving. Veel
        succes!
      </p>
    </div>
    <footer>
      <shared-button
        [text]="'Ik snap het'"
        (click)="onUnderstandClicked()"
      ></shared-button>
    </footer>
  </div>

  <div *ngIf="currentPage === 'overview'">
    <header class="mb-4">
      <div class="d-flex justify-content-between">
        <p class="os-text-dark os-bold font-22 mb-1">Checklist</p>
        <i
          class="fa-solid fa-rotate-right cursor-pointer"
          (click)="onReloadClicked()"
        ></i>
      </div>
      <p *ngIf="nrTotalActions - finishedActionIds?.length > 0">
        Je hebt nog
        {{ nrTotalActions - finishedActionIds?.length }} openstaande
        {{
          nrTotalActions - finishedActionIds?.length === 1 ? "actie" : "acties"
        }}.
      </p>
      <p *ngIf="nrTotalActions - finishedActionIds?.length === 0">
        Je hebt alle acties voltooid!
      </p>
    </header>
    <div *ngFor="let category of categories" class="pb-2">
      <div
        class="d-flex align-items-center justify-content-between pb-1 mb-2 border-bottom os-bold"
        [ngClass]="category.actions.length > 0 ? 'cursor-pointer' : ''"
        (click)="category.toggled = !category.toggled"
      >
        <div class="d-flex align-items-center cursor-pointer">
          <div class="">{{ category.title }}</div>
          <i
            *ngIf="category.video"
            class="fa-regular fa-circle-play font-18 mx-2"
            (click)="onOpenVideoClicked(category.video)"
          ></i>
        </div>
        <div *ngIf="category.actions.length > 0">
          <i
            [ngClass]="
              category.toggled ? 'fa fa-chevron-down' : 'fa fa-chevron-right'
            "
          ></i>
        </div>
      </div>
      <div class="ps-3">
        <div class="mb-2" *ngIf="category.toggled">
          <div
            *ngFor="let action of category.actions"
            class="d-flex align-items-center justify-content-between pb-2"
          >
            <div
              class="d-flex align-items-center"
              (click)="onOpenVideoClicked(action.video)"
            >
              <span class="me-2">{{ action.title }}</span>
              <i
                *ngIf="action.video"
                class="fa-regular fa-circle-play font-18 mx-2"
              ></i>
            </div>
            <i
              *ngIf="finishedActionIds?.indexOf(action.id) > -1"
              class="fa-regular fa-circle-check os-text-lightgreen font-20"
            ></i>
            <i
              *ngIf="finishedActionIds?.indexOf(action.id) === -1"
              class="fa-regular fa-circle font-20"
            ></i>
          </div>
        </div>
        <div *ngFor="let category of category.children">
          <div
            *ngIf="
              category.product === 'any' ||
              category.product === productName.toLowerCase()
            "
            class="d-flex align-items-center justify-content-between pb-1 mb-2 border-bottom os-bold"
          >
            <div class="d-flex align-items-center cursor-pointer">
              <div class="">{{ category.title }}</div>
              <i
                *ngIf="category.video"
                class="fa-regular fa-circle-play font-18 mx-2"
                (click)="onOpenVideoClicked(category.video)"
              ></i>
            </div>
            <div
              class="d-flex align-items-center cursor-pointer"
              (click)="category.toggled = !category.toggled"
            >
              <span
                class="me-1"
                [ngClass]="
                  category.nrCompleted === category.actions.length
                    ? 'os-text-success'
                    : ''
                "
              >
                ({{ category.nrCompleted }}/{{ category.actions.length }})
              </span>

              <i
                [ngClass]="
                  category.toggled
                    ? 'fa fa-chevron-down'
                    : 'fa fa-chevron-right'
                "
              ></i>
            </div>
          </div>
          <div class="mb-2 ps-2" *ngIf="category.toggled" @fadeIn>
            <div
              *ngFor="let action of category.actions"
              class="d-flex align-items-center justify-content-between pb-2"
              [ngClass]="action.video ? 'cursor-pointer' : ''"
            >
              <div
                class="d-flex align-items-center"
                (click)="onOpenVideoClicked(action.video)"
              >
                <span class="me-2">{{ action.title }}</span>
                <i
                  *ngIf="action.video"
                  class="fa-regular fa-circle-play font-18 mx-2"
                ></i>
              </div>
              <i
                *ngIf="finishedActionIds?.indexOf(action.id) > -1"
                class="fa-regular fa-circle-check os-text-lightgreen font-20"
              ></i>
              <i
                *ngIf="finishedActionIds?.indexOf(action.id) === -1"
                class="fa-regular fa-circle font-20"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <shared-button
      [text]="'Sluiten'"
      (click)="onCloseChecklistsClicked()"
    ></shared-button>
  </div>

  <div *ngIf="currentPage === 'actions-finished'">
    <header class="border-bottom pb-3 mb-3">
      <p class="os-text-dark os-bold font-18 mb-0">
        Goed gedaan! Je hebt
        {{
          newFinishedUserActions.length === 1
            ? newFinishedUserActions.length + " actie "
            : newFinishedUserActions.length + " acties "
        }}
        voltooid.
      </p>
    </header>
    <div class="border-bottom pb-3 mb-3" @fadeIn>
      <div
        *ngFor="let userAction of newFinishedUserActions"
        class="d-flex align-items-center justify-content-between pb-2"
      >
        <div>
          <p class="mb-0 os-bold">{{ userAction.action.title }}</p>
          <small>{{
            userAction.finished_at | date : "dd-MM-yyyy HH:mm"
          }}</small>
        </div>
        <img
          width="20px"
          src="../../../../../../assets/button_icons/check_circle_green.svg"
        />
      </div>
    </div>
    <footer>
      <shared-button
        class="me-2"
        [text]="'Bekijk acties'"
        (click)="setCurrentPage('overview')"
      ></shared-button>
      <shared-button
        [text]="'Sluiten'"
        [style]="'secondary'"
        (click)="onCloseChecklistsClicked()"
      ></shared-button>
    </footer>
  </div>

  <div *ngIf="currentPage === 'checklist-completed'">
    <header class="border-bottom pb-3 mb-3">
      <p class="os-text-dark os-bold font-22 mb-0">
        Je hebt de checklist voltooid!
      </p>
    </header>
    <div class="border-bottom pb-3 mb-3">
      <p>Houd deze plek in de gaten voor nieuwe acties.</p>
    </div>
    <footer>
      <shared-button
        class="me-2"
        [text]="'Bekijk acties'"
        (click)="setCurrentPage('overview')"
      ></shared-button>
      <shared-button
        [text]="'Sluiten'"
        [style]="'secondary'"
        (click)="onCloseChecklistsClicked()"
      ></shared-button>
    </footer>
  </div>

  <loading *ngIf="isLoading"></loading>
</div>
