import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  OnDestroy,
  HostListener,
  OnChanges,
  SimpleChanges,
} from "@angular/core";

import * as Plyr from "plyr";

import { VideoStatus } from "@app/training/shared/models/VideoStatus";
import { Video } from "@app/training/shared/models/Video";
import { CommonModule } from "@angular/common";
import { SafePipe } from "@app/shared/pipes";

@Component({
  standalone: true,
  selector: "shared-video",
  templateUrl: "./video.component.html",
  styleUrls: ["./video.component.scss"],
  imports: [CommonModule, SafePipe],
})
export class VideoPlayerComponent implements OnInit, OnDestroy, OnChanges {
  @Input() video!: Video;
  @Input() startTime!: number;

  @Output() videoStatusChanged: EventEmitter<VideoStatus> = new EventEmitter();
  @Output() timeChange: EventEmitter<number> = new EventEmitter();
  @Output() videoEnded: EventEmitter<void> = new EventEmitter();

  @ViewChild("videoPlayer")
  private videoPlayer!: ElementRef;

  player!: any;

  secondsWatchedTimer!: ReturnType<typeof setInterval>;
  isError!: boolean;

  videoStatus!: VideoStatus;

  @HostListener("window:beforeunload", ["$event"])
  unloadHandler() {
    clearInterval(this.secondsWatchedTimer);
    this.videoStatus.status = "paused";
    this.videoStatus.secondsWatched =
      this.videoPlayer.nativeElement.currentTime;
    this.videoStatusChanged.emit(this.videoStatus);
  }

  constructor() {}

  ngOnInit(): void {
    this.initializeVideoStatus();
    this.configureVideoPlayer();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ("startTime" in changes && this.videoPlayer) {
      this.videoPlayer.nativeElement.currentTime =
        changes["startTime"].currentValue;
    }
  }

  initializeVideoStatus() {
    this.videoStatus = {
      status: "standby",
      secondsWatched: this.startTime,
    };
    this.video.url + "?t=" + this.startTime;
  }

  configureVideoPlayer() {
    this.player = new Plyr("video", {
      captions: { active: true },
      invertTime: false,
      speed: {
        selected: 1,
        options: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.5, 3],
      },
    });
  }

  onMetadata() {
    if (this.videoStatus.secondsWatched > 0) {
      this.videoPlayer.nativeElement.currentTime =
        this.videoStatus.secondsWatched;
    }
  }

  onVideoStarted() {
    this.videoStatus.status = "playing";
    this.videoStatusChanged.emit(this.videoStatus);
  }

  onVideoPaused() {
    clearInterval(this.secondsWatchedTimer);
    this.videoStatus.status = "paused";
    this.videoStatus.secondsWatched =
      this.videoPlayer.nativeElement.currentTime;
    this.videoStatusChanged.emit(this.videoStatus);
  }

  onVideoSeeking() {
    this.updateVideoStatus();
    this.videoStatus.secondsWatched =
      this.videoPlayer.nativeElement.currentTime;
    this.videoStatusChanged.emit(this.videoStatus);
  }

  onTimeUpdate() {
    this.updateVideoStatus();
    this.timeChange.emit(+this.videoPlayer.nativeElement.currentTime);
  }

  protected onVideoEnded() {

    if (this.startTime + 2 < this.videoPlayer.nativeElement.duration) {
      this.videoEnded.emit();
    }
  }

  updateVideoStatus() {
    this.videoStatus.secondsWatched =
      this.videoPlayer.nativeElement.currentTime;
    const duration = this.videoPlayer.nativeElement.duration;
    if (
      this.videoIsFinished(duration, this.videoStatus.secondsWatched) &&
      this.videoStatus.status !== "finished"
    ) {
      this.videoStatus.status = "finished";
      clearInterval(this.secondsWatchedTimer);
      this.videoStatusChanged.emit(this.videoStatus);
    }
  }

  videoIsFinished(duration: number, currentTime: number) {
    return duration - currentTime <= 40;
  }

  ngOnDestroy() {
    clearInterval(this.secondsWatchedTimer);
  }
}
