import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TileComponent, TileItem } from "@app/shared/basic-components";

@Component({
  standalone: true,
  selector: "shared-tile-options",
  templateUrl: "./tile-options.component.html",
  styleUrls: ["./tile-options.component.scss"],
  imports: [CommonModule, TileComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TileOptionsComponent {
  @Input() tileOptions: TileItem[] = [];
  @Input() selectedOptions: TileItem[] = [];
  @Input() maxSelectedOptions?: number;
  @Input() fullWidthOptions = false;
  @Input() isUppercase = false;

  @Output() selectedOptionsChange = new EventEmitter<TileItem[]>();
  @Output() optionSelected = new EventEmitter<TileItem>();

  protected getIsOptionSelected(id: number): boolean {
    return !!this.selectedOptions.find(option => option.id === id);
  }

  protected onOptionClicked(option: TileItem): void {
    if (this.getIsOptionSelected(option.id)) {
      this.selectedOptions = this.selectedOptions.filter(selectedOption => selectedOption.id !== option.id);
    } else {
      if (this.maxSelectedOptions && this.selectedOptions.length === this.maxSelectedOptions) {
        this.selectedOptions.shift();
      }

      this.selectedOptions.push(option);
      this.optionSelected.emit(option)
    }

    this.selectedOptionsChange.emit(this.selectedOptions);
  }
}
