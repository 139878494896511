<!-- Desktop layout -->
<div class="d-none d-md-block w-100 os-block-3 menu-wrapper">
  <ng-container *ngTemplateOutlet="menuItemsTemplate"></ng-container>
  <div class="pt-3 border-top flex-between-center my-2">
    <div class="font-12 font-weight-600 os-text-blue cursor-pointer">
      <span
        *ngIf="displayPreviousButton"
        (click)="navigationButtonClicked.emit(false)"
      >
        <i class="fa fa-chevron-left me-2"></i>Vorige
      </span>
    </div>
    <div class="font-12 font-weight-600 os-text-blue cursor-pointer text-end">
      <span
        *ngIf="displayNextButton"
        (click)="navigationButtonClicked.emit(true)"
      >
        Volgende<i class="fa fa-chevron-right ms-2"></i>
      </span>
    </div>
  </div>
</div>
<div class="d-none d-md-block">
  <ng-container *ngTemplateOutlet="linksTemplate"></ng-container>
</div>

<!-- Mobile layout -->
<div class="d-xl-none">
  <div
    *ngIf="selectedMenuItem"
    class="d-flex justify-content-end mb-4"
  >
    <div
      class="cursor-pointer"
      data-bs-toggle="offcanvas"
      data-bs-target="#sharedOffcanvasSidebar"
      aria-controls="offcanvasNavbar"
    >
      {{ selectedMenuItem.text }}
      <img src="../../../assets/button_icons/menu.svg" class="mx-3" />
    </div>
  </div>

  <shared-offcanvas-sidebar class="d-block">
    <ng-container *ngTemplateOutlet="menuItemsTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="linksTemplate"></ng-container>
  </shared-offcanvas-sidebar>
</div>

<!-- Shared templates -->
<ng-template #menuItemsTemplate>
  <div>
    <ng-container *ngFor="let menuItem of menuItems; let i = index">
      <ng-container *ngTemplateOutlet="upperMenuItemTemplate; context: { $implicit: menuItem }"></ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #upperMenuItemTemplate let-menuItem let-isChild="isChild">
  <ng-container *ngTemplateOutlet="menuItemTemplate; context: { $implicit: menuItem, isChild: isChild }"></ng-container>
  <div *ngIf="menuItem.children && menuItem.expanded">
    <ng-container *ngFor="let childMenuItem of menuItem.children; let i = index">
      <ng-container
        *ngTemplateOutlet="upperMenuItemTemplate; context: { $implicit: childMenuItem, isChild: true }"></ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #menuItemTemplate let-menuItem let-isChild="isChild">
  <div
    class="d-flex align-items-center gap-3 menu-item {{ isChild ? 'sub-menu-item' : 'mb-2'}}"
    [ngClass]="{
    active: menuItem.id === selectedMenuItem?.id,
    'child-item': isChild,
    'has-children': menuItem.children
    }"
    (click)="onMenuItemClicked(menuItem)"
  >
    <i
      *ngIf="menuItem.leftIconClass"
      style="width: 25px;"
      class="font-22 {{ menuItem.leftIconClass }}"
    ></i>
    <span>{{ menuItem.text }}</span>
    <div class="ms-auto flex-center-center gap-3">
      <span class="d-none d-md-block font-14 {{ menuItem.additionalTextClass || '' }}">{{ menuItem.additionalText }}</span>
      <i
        *ngIf="menuItem.rightIconClass"
        style="width: 20px"
        class="d-none d-md-flex fa align-fa-icon {{ isChild ? 'font-16' : 'font-20'}} {{ menuItem.rightIconClass }}"
      ></i>
      <!-- <i
        *ngIf="menuItem.children || isChild"
        style="width: 16px"
        class="font-16 {{ (isChild && !menuItem.children) ? '' : menuItem.expanded ? 'fa fa-chevron-down' : 'fa fa-chevron-left' }}"
      ></i> -->
    </div>
  </div>
</ng-template>

<ng-template #linksTemplate>
  <div class="d-flex flex-column gap-1">
    <shared-button
      *ngFor="let link of links"
      [style]="'link-blue'"
      [text]="link.text" (click)="link.handler()"
    ></shared-button>
  </div>
</ng-template>
