<img
  [ngbTooltip]="isArchived ? 'Dearchiveren' : 'Archiveren'"
  width="20"
  height="20"
  class="cursor-pointer"
  alt="Archiveren"
  [src]="
            isArchived
              ? '../../../assets/button_icons/unarchive.svg'
              : '../../../assets/button_icons/archive.svg'
          "
  (click)="clicked.emit()"
/>
