import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarRef } from "@angular/material/snack-bar";
import { delay, first, of, Subscription } from "rxjs";
import {
  XpNotificationComponent
} from "@app/shared/feature-components/notification/xp-notification/xp-notification.component";

@Injectable({
  providedIn: "root",
})
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {}

  showSuccessMessage(
    message: string,
    hideAutomatically = true,
    duration: number = 3500
  ): void {
    this.snackBar.open(message, " ", {
      duration: hideAutomatically ? duration : undefined,
      panelClass: "os-snackbar-success",
    });
  }

  showErrorMessage(message: string): void {
    this.snackBar.open(message, " ", {
      duration: 4500,
      panelClass: "os-snackbar-error",
    });
  }

  showInfoMessage(message: string): void {
    this.snackBar.open(message, " ", {
      duration: 5000,
      panelClass: "os-snackbar-info",
    });
  }

  showLevelUpMessage(level: number): void {
    this.snackBar.open(`Gefeliciteerd, je bent nu level ${level}!`, " ", {
      duration: 3000,
      panelClass: "os-snackbar-level-up",
    });
  }

  showXPGainedMessage(XP: number): void {
    const hideAfter = 2000;
    const message = `${XP} XP verdiend`;
    const snackBarRef = this.snackBar.openFromComponent(XpNotificationComponent, {
      data: message,
      panelClass: "os-snackbar-xp"
    });

    let snackBarHideSub = this.getHideAfterSubscription(hideAfter, snackBarRef);
    snackBarRef.instance.mouseHovered.subscribe(isHovered => {
      if (isHovered) {
        snackBarHideSub?.unsubscribe();
      } else {
        snackBarHideSub = this.getHideAfterSubscription(hideAfter, snackBarRef);
      }
    })
  }

  private getHideAfterSubscription<T>(hideAfter: number, snackBarRef: MatSnackBarRef<T>): Subscription {
    return of(null)
      .pipe(first(), delay(hideAfter))
      .subscribe(() => snackBarRef.dismiss());
  }
}
