import {
  ChangeDetectionStrategy,
  Component,
  Input, OnInit,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  BaseControlValueAccessorComponent
} from "../value-accessors/base-control-value-accessor";
import { FormsModule } from "@angular/forms";
import { getValueAccessorProvider } from "../value-accessors/value-accessor-provider";

@Component({
  standalone: true,
  selector: "shared-number-input",
  templateUrl: "./number-input.component.html",
  styleUrls: ["./number-input.component.scss"],
  providers: [getValueAccessorProvider(NumberInputComponent)],
  imports: [CommonModule, FormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumberInputComponent extends BaseControlValueAccessorComponent<number> implements OnInit {
  @Input() min: number = 0;
  @Input() max: number = 100;

  protected onBlur(): void {
    if (this.value < this.min) {
      this.value = this.min;
    }

    if (this.value > this.max) {
      this.value = this.max;
    }

    this.onValueChange(this.value);
  }
}
