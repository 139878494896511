import { Component, Input, OnInit } from "@angular/core";
import { TableHeader } from "@app/shared/models/TableHeader";
import { CommonModule } from "@angular/common";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";

@Component({
  standalone: true,
  selector: "shared-table-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  imports: [CommonModule, NgbPopover]
})
export class TableHeaderComponent implements OnInit {
  @Input() header!: TableHeader;
  @Input() sorting: "ascending" | "descending" | "none" | "disabled" = "none";

  constructor() {}

  ngOnInit(): void {}
}
