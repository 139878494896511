import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
} from "@angular/core";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { UserService } from "@app/shared/services/user.service";
import { Profile } from "../models/Profile";
import { SnackBarService } from "@app/shared/services/utility-services/snack-bar.service";
import { CommonModule } from "@angular/common";
import { NavbarComponent } from "@app/shared/feature-components/navbar";
import { Training } from "@app/training/shared/models/Training";
import { NgbModal, NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { StatItem } from "./models/StatItem";
import { StatBlockComponent } from "./components/stat-block/stat-block.component";
import {
  AvatarComponent,
  LoadingComponent,
  ProgressBarComponent,
  SubTitleComponent,
} from "@app/shared/basic-components";
import { UtilService } from "@app/shared/services/util.service";
import { RouterService } from "@app/shared/services/utility-services/router.service";
import { zip } from "rxjs";
import { XPService } from "@app/shared/services/xp.service";
import { XpInfoModalComponent } from "@app/shared/feature-components/modals/xp-info-modal/xp-info-modal.component";

@Component({
  selector: "user-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    NavbarComponent,
    NgbTooltip,
    StatBlockComponent,
    LoadingComponent,
    SubTitleComponent,
    AvatarComponent,
    RouterLink,
    ProgressBarComponent,
  ],
})
export class ProfileComponent implements OnInit {
  private userId!: number;

  protected profile!: Profile;
  protected isProfileOwner: boolean = false;
  protected isLoading: boolean = false;

  protected uniqueTrainings: Training[] = [];
  protected displayedTrainings: Training[] = [];

  protected statItems!: StatItem[];

  private XPService = inject(XPService);

  get allTrainingsShown(): boolean {
    return this.displayedTrainings.length === this.uniqueTrainings.length;
  }

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private snackBarService: SnackBarService,
    private routerService: RouterService,
    private modalService: NgbModal,
    private readonly cdr: ChangeDetectorRef,
    private readonly utilService: UtilService
  ) {}

  ngOnInit(): void {
    this.getParams();
    this.utilService.setTitle(`Profiel`);
  }

  private getParams() {
    this.route.params.subscribe((params) => {
      this.userId = params["userId"];
      this.getData();
    });
  }

  private getData() {
    this.isLoading = true;

    zip([
      this.userService.getUser(),
      this.userService.getProfile(this.userId),
    ]).subscribe({
      next: ([user, profile]) => {
        this.isProfileOwner = user.id === profile.user.id;
        this.profile = Profile.Factory(profile);
        this.utilService.setTitle(`Profiel - ${this.profile.user.name}`);
        this.setUniqueTrainings();
        this.setStatItems();
      },
      error: (error) => {
        if (error.status === 404) {
          this.snackBarService.showErrorMessage("Deze gebruiker bestaat niet");
        } else {
          this.snackBarService.showErrorMessage(
            "Er is iets misgegaan bij het ophalen van de gebruiker"
          );
        }
      },
      complete: () => {
        this.isLoading = false;
        this.cdr.markForCheck();
      },
    });
  }

  private setUniqueTrainings() {
    this.uniqueTrainings = this.profile.active_trainings
      .filter((training) => training.icon)
      .filter(
        (training, index, self) =>
          index ===
          self.findIndex((t) => t.training_name === training.training_name)
      );

    this.displayedTrainings =
      this.uniqueTrainings.length > 7
        ? this.uniqueTrainings.slice(0, 8)
        : this.uniqueTrainings;
  }

  private setStatItems() {
    const {
      user: {
        level: { level, xp_total },
      },
      highest_grade,
      nr_exams_finished,
      nr_people_helped,
      nr_answers,
      nr_summaries_written,
      nr_notes_made,
    } = this.profile;

    this.statItems = [
      {
        iconUrl:
          "https://os-file-storage.ams3.cdn.digitaloceanspaces.com/media/uploads/branding/icons/star.png",
        title: `Level ${level}`,
        subtitle: `${xp_total} XP`,
      },
      {
        iconUrl:
          "https://os-file-storage.ams3.cdn.digitaloceanspaces.com/media/uploads/branding/icons/grade.png",
        title: `Hoogste cijfer - ${highest_grade}`,
        subtitle: `${nr_exams_finished} ${
          UtilService.isSchool24() ? "toetsen" : "examens"
        } gemaakt`,
      },
      {
        iconUrl:
          "https://os-file-storage.ams3.cdn.digitaloceanspaces.com/media/uploads/branding/icons/help.png",
        title: `${nr_people_helped} ${
          nr_people_helped === 1 ? "iemand" : "mensen"
        } geholpen`,
        subtitle: `${nr_answers} ${
          nr_answers === 1 ? "vraag" : "vragen"
        } beantwoord`,
      },
      {
        iconUrl:
          "https://os-file-storage.ams3.cdn.digitaloceanspaces.com/media/uploads/branding/icons/note.png",
        title: `${nr_summaries_written} samenvattingen`,
        subtitle: `${nr_notes_made} notities gemaakt`,
      },
    ];
  }

  protected onCloseProfile(): void {
    this.routerService.navigateBack();
  }

  protected onShowAllTrainings(): void {
    this.displayedTrainings = this.uniqueTrainings;
  }

  protected onOpenXpInfoModal(): void {
    this.XPService.getXpGainActions().subscribe((actions) => {
      const modalRef = this.modalService.open(XpInfoModalComponent, {
        animation: false,
        backdrop: false,
      });
      (modalRef.componentInstance as XpInfoModalComponent).xpGainActions =
        actions;
    });
  }
}
