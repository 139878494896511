<div class="d-flex justify-content-end align-items-top px-3 pt-3">
  <i
    class="fa-solid fa-xmark os-text-red cursor-pointer"
    (click)="closeWindow()"
  ></i>
</div>
<div
  class="d-xl-flex align-items-center justify-content-between p-5 px-4 px-xl-5 pt-4"
>
  <div class="content-left">
    <img
      src="https://os-file-storage.ams3.cdn.digitaloceanspaces.com/media/uploads/marketing/2%20boekjes-min.png"
    />
  </div>
  <div class="content-right ps-xl-5">
    <div class="font-24 os-bold mb-3">Nieuw! OnlineSlagen examenboekjes</div>
    <p>Oefen nu ook examens op papier met onze examenboekjes:</p>
    <ul class="mb-4">
      <li>Oriëntatietoets</li>
      <li>Examenvragen per onderdeel</li>
      <li>Volledige examens</li>
      <li>Stap voor stap uitleg</li>
      <li>Inzicht in sterke en zwakke onderwerpen</li>
    </ul>
    <div>
      <shared-button
        [text]="'Bekijk examenboekjes'"
        (click)="onViewBooksClicked()"
      ></shared-button>
    </div>
  </div>
</div>
