<div class="dark-background"></div>

<div class="no-background py-5 ">
  <div
    (clickOutside)="closeWindow(); $event.stopPropagation()"
    class="modal-task d-flex justify-content-center mx-auto flex-wrap"
  >
    <div class="col-12 px-0 text-end">
      <i
        class="cursor-pointer fa fa-times os-text-red"
        (click)="closeWindow()"
      ></i>
    </div>
    <div class="col-12 px-0 scrollable">
      <div class="pb-4 px-0 col-12 col-xl-11 mx-auto">
        <div class="os-bold font-22 text-start mb-3">{{ title }}</div>
        <div
          style="display: block !important"
          class="text-start text-from-text-editor"
          [innerHTML]="content | safeHtml"
        ></div>
      </div>
    </div>
  </div>
</div>
