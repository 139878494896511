import { Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
  standalone: true,
  selector: "shared-grade-box",
  templateUrl: "./grade-box.component.html",
  styleUrls: ["./grade-box.component.scss"],
  imports: [CommonModule]
})
export class GradeBoxComponent implements OnInit {
  @Input() grade!: number;
  @Input() size: string = "regular";

  constructor() {}

  ngOnInit(): void {}
}
