import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
  standalone: true,
  selector: "stepper",
  templateUrl: "./stepper.component.html",
  styleUrls: ["./stepper.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule]
})
export class StepperComponent {
  @Input() steps!: string[];
  @Input() activatedSteps: string[] = [];

  @Input() currentStep!: string;
  @Output() stepClicked = new EventEmitter<string>;

  onStepClicked(step: string): void {
    if (this.activatedSteps.includes(step)) {
      this.stepClicked.emit(step);
    }
  }
}
