import { SubscriptionStatus } from "@app/shared/enums/subscription-status";
import { Product } from "./Product";

export class Subscription {
  public isActive!: boolean;

  constructor(
    public id: number,
    public mollie_subscription_id: string,
    public product: Product,
    public status: SubscriptionStatus,
    public cancellation_reason: string,
    public created_at: Date,
    public user: number,
    public cancelled_at?: Date,
    public reactivated_at?: Date,
    public expiry_date?: Date
  ) {
    this.setIsActive();
  }

  public static Factory(subscription: Subscription): Subscription {
    return new Subscription(
      subscription.id,
      subscription.mollie_subscription_id,
      subscription.product,
      subscription.status,
      subscription.cancellation_reason,
      new Date(subscription.created_at),
      subscription.user,
      subscription.cancelled_at
        ? new Date(subscription.cancelled_at)
        : undefined,
      subscription.reactivated_at
        ? new Date(subscription.reactivated_at)
        : undefined,
      subscription.expiry_date ? new Date(subscription.expiry_date) : undefined
    );
  }

  private setIsActive() {
    this.isActive =
      this.status === SubscriptionStatus.Active ||
      (this.status === SubscriptionStatus.Cancelled &&
        !this.hasPassedExpiryDate());
  }

  public hasPassedExpiryDate(): boolean {
    if (!this.expiry_date) {
      return false;
    }

    return this.expiry_date > new Date();
  }

  public static getNextPaymentDate(subscription: Subscription): Date {
    let nextPaymentDate: Date;

    if (subscription.reactivated_at) {
      nextPaymentDate = new Date(subscription.reactivated_at);
    } else {
      const completedAt = new Date(subscription.created_at);
      nextPaymentDate = new Date(
        completedAt.setDate(
          completedAt.getDate() + subscription.product.free_trial_period
        )
      );
    }

    const interval =
      subscription.product.interval === "month" ? "Month" : "FullYear";

    while (nextPaymentDate < new Date()) {
      nextPaymentDate[`set${interval}`](
        nextPaymentDate[`get${interval}`]() + 1
      );
    }

    return nextPaymentDate;
  }
}
