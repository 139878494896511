import { Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ButtonComponent } from "@app/shared/basic-components";

@Component({
  standalone: true,
  selector: "exam-book-modal",
  templateUrl: "./exam-book-modal.component.html",
  styleUrls: ["./exam-book-modal.component.scss"],
  imports: [ButtonComponent]
})
export class ExamBookModalComponent {
  constructor(public activeModal: NgbActiveModal) {}

  protected onViewBooksClicked(): void {
    window.open(
      "https://www.onlineslagen.nl/examenboekjes-voor-leerlingen/",
      "_blank"
    );
  }

  protected closeWindow() {
    this.activeModal.close();
  }
}
