import { Directive, HostListener, Output, EventEmitter } from "@angular/core";

@Directive({
  standalone: true,
  selector: "[clickImg]"
})
export class ClickImgDirective {
  @Output()
  public clickImg = new EventEmitter<string>();

  @HostListener("click", ["$event.target"])
  onClick(targetElement: HTMLElement) {
    const sourceAttribute = targetElement.getAttribute("src");
    if (sourceAttribute) {
      this.clickImg.emit(sourceAttribute);
    }
  }
}
