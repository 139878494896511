import { Injectable } from "@angular/core";
import { Router, CanActivate, UrlTree } from "@angular/router";
import { UserService } from "@app/shared/services/user.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class ExistingUserGuard implements CanActivate {
  constructor(public router: Router, public userService: UserService) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.userService.getUserExtended().pipe(
      map((user) => user.new_user ? true : this.navigateToHomePage())
    )
  }

  protected navigateToHomePage(): UrlTree {
    return this.buildUrlTree([`/home`]);
  }

  private buildUrlTree(fragments: string[]): UrlTree {
    return this.router.createUrlTree(fragments);
  }
}
