import {
  Component, ContentChild,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output, TemplateRef, ViewChild,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { ButtonComponent } from "../../buttons/button/button.component";
import { ClickOutsideDirective } from "@app/shared/directives/click-outside.directive";

@Component({
  standalone: true,
  selector: "shared-verify-modal",
  templateUrl: "./verify-modal.component.html",
  styleUrls: ["./verify-modal.component.scss"],
  imports: [CommonModule, ButtonComponent, ClickOutsideDirective]
})
export class VerifyModal implements OnInit {
  @Input() title!: string;
  @Input() verifyButtonText!: string;
  @Input() cancelButtonText: string = "Annuleren";

  @Output() windowClose = new EventEmitter<void>();
  @Output() clickOutside = new EventEmitter<void>();
  @Output() verifyClick = new EventEmitter<void>();

  isLoading!: boolean;

  @HostListener("document:keydown.escape", ["$event"]) onEscKeydownHandler(
    event: KeyboardEvent
  ) {
    this.closeWindow();
  }

  @HostListener("document:keydown.enter", ["$event"]) onEnterKeyDownHandler(
    event: KeyboardEvent
  ) {
    this.onVerifyClicked();
  }

  @ContentChild('customButtonsTemplate') customButtonsTemplate?: TemplateRef<void>;

  constructor() {}

  ngOnInit(): void {}

  closeWindow() {
    this.windowClose.emit();
  }

  onClickOutside() {
    this.clickOutside.emit();
  }

  onVerifyClicked() {
    this.isLoading = true;
    this.verifyClick.emit();
  }
}
