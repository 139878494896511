<input
  class="font-16 os-input-blue"
  type="number"
  [min]="min"
  [max]="max"
  [(ngModel)]="value"
  (ngModelChange)="onValueChange(value)"
  (blur)="onBlur()"
  (mouseup)="$event.stopPropagation()"
/>
