import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";

export interface TileItem {
  id: number;
  text: string | number;
  value?: any;
  iconClass?: string;
  iconUrl?: string
}

@Component({
  standalone: true,
  selector: "shared-tile",
  templateUrl: "./tile.component.html",
  styleUrls: ["./tile.component.scss"],
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TileComponent {
  @Input() tile!: TileItem;
  @Input() isSelected = false;
  @Input() isUppercase = false;
}
