<div class=" p-4 p-xl-5">
  <div
    *ngIf="!editAttachmentTitleToggle"
    autoFocus
    class="flex-between-center mb-3 cursor-pointer"
    (click)="editAttachmentTitleToggle = true"
  >
    <shared-sub-sub-title [title]="attachment?.title"></shared-sub-sub-title>
    <span class="os-link" (click)="closeModal()">Sluiten</span>
  </div>
  <div *ngIf="editAttachmentTitleToggle" class="mb-3">
    <div
      *ngIf="!attachment?.title"
      class="flex-between align-items-top"
    >
      <shared-sub-sub-title
        class="d-block mb-2"
        [title]="'Geef je bijlage een titel'"
      ></shared-sub-sub-title>
      <span class="os-link" (click)="closeModal()">Sluiten</span>
    </div>
    <div class="flex-between align-items-top">
      <shared-input
        class="w-100 pe-2"
        [group]="attachmentTitleForm"
        [controlName]="'title'"
        [type]="'text'"
        [placeHolder]="'Vul titel van bijlage in...'"
        [errorMessage]="'Vul een titel in van maximaal 200 tekens in.'"
        (enterPress)="onSubmitAttachmentTitle()"
      ></shared-input>
      <shared-button
        class="d-inline-block pe-4"
        [text]="'Opslaan'"
        [isDisabled]="!attachmentTitleForm.valid"
        (click)="onSubmitAttachmentTitle()"
      ></shared-button>
    </div>
  </div>
  <div *ngIf="attachment">
    <div
      *ngIf="attachment.files"
      class="mb-3"
    >
      <shared-uploaded-file
        *ngFor="let file of attachment.files"
        class="d-block mb-1"
        [file]="file"
        (fileDelete)="onFileDeleted($event)"
        (fileUpdate)="onFileUpdated($event)"
      ></shared-uploaded-file>
    </div>
    <shared-sub-sub-title
      *ngIf="attachment.files"
      [title]="'Kies of drop bestand(en)'"
    ></shared-sub-sub-title>
    <input
      appDnd
      class="container-input text-center my-3 cursor-pointer os-block-dashed"
      type="file"
      #fileDropRef
      id="fileDropRef"
      accept=".mp3, .mp4, .wav, .opus, .pdf, .png, .jpg, .webm, .pptx"
      multiple="multiple"
      (change)="onFilesAdded($event)"
      (fileDropped)="onFilesDropped($event)"
    />
    <div class="os-text-red mb-3">{{ uploadErrorMessage }}</div>
  </div>
  <div *ngIf="attachment && attachment?.files.length > 0">
    <shared-button
      [text]="'Opslaan'"
      (click)="onCreateAttachmentClicked()"
    ></shared-button>
  </div>
</div>
