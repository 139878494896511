import { Training } from "@app/training/shared/models/Training";
import { PublicUser } from "./PublicUser";

export class Profile {
  constructor(
    public user: PublicUser,
    public active_trainings: Training[],
    public nr_answers: number,
    public nr_people_helped: number,
    public nr_exams_finished: number,
    public highest_grade: number,
    public nr_summaries_written: number,
    public nr_notes_made: number
  ) {}

  public static Factory(profile: Profile): Profile {
    return new Profile(
      PublicUser.Factory(profile.user),
      profile.active_trainings.map((training: any) => new Training(training)),
      profile.nr_answers,
      profile.nr_people_helped,
      profile.nr_exams_finished,
      profile.highest_grade,
      profile.nr_summaries_written,
      profile.nr_notes_made
    );
  }
}
