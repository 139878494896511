<div class="dark-background"></div>

<div class="no-background ">
  <div
    (clickOutside)="closeWindow(); $event.stopPropagation()"
    class="col-md-8 col-xl-4 col-xxl-3 modal-body mx-2 mx-md-auto p-5 scrollable"
  >
    <div>
      <div class="col-12 px-0 flex-between-center">
        <shared-sub-title [title]="title"></shared-sub-title>
        <i
          class="fa-solid fa-xmark os-text-red cursor-pointer"
          (click)="closeWindow()"
        ></i>
      </div>
      <div class="mb-4 font-12 os-link" (click)="toggleSelectAll()">
        {{ nrSelectedItems > 0 ? "Alles uitvinken" : "Selecteer alles" }}
      </div>
      <div class="mb-3 overflow-auto" style="max-height: 400px">
        <div
          *ngFor="let option of options; let i = index"
          class="d-flex justify-content-start mb-2"
          (click)="onOptionClicked(option)"
        >
          <input
            type="checkbox"
            class="me-3 cursor-pointer"
            id="item-checkbox-{{ i }}"
            name="checkbox"
            [checked]="isCheckedKey ? option[isCheckedKey] : true"
          />
          <label class="font-14 cursor-pointer">{{ option[key] }}</label>
        </div>
      </div>
      <div class="text-center">
        <shared-button
          [text]="'Opslaan'"
          (click)="onSubmitClicked()"
        ></shared-button>
      </div>
    </div>
  </div>
</div>
