import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "@env/environment";
import { Attachment } from "@app/shared/models/Attachment";
import { File as OSFile } from "@app/shared/models/File";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AttachmentService {
  private url: string = environment.apiEndpoint;

  constructor(private http: HttpClient) {}

  createAttachment(attachment: {
    title: string;
    object_id: number;
    content_type: number;
  }): Observable<Attachment> {
    return this.http.post<Attachment>(this.url + "/attachments", attachment);
  }

  getAttachment(attachmentId: number): Observable<Attachment> {
    return this.http.get<Attachment>(this.url + "/attachments/" + attachmentId);
  }

  updateAttachment(attachment: Attachment): Observable<Attachment> {
    return this.http.put<Attachment>(
      this.url + "/attachments/" + attachment.id,
      attachment
    );
  }

  getFile(fileId: number): Observable<OSFile> {
    return this.http.get<OSFile>(this.url + "/open-files/" + fileId);
  }

  createFile(file: OSFile): Observable<OSFile> {
    return this.http.post<OSFile>(this.url + "/files", file);
  }

  updateFile(file: OSFile): Observable<OSFile> {
    return this.http.put<OSFile>(this.url + "/files/" + file.id, file);
  }
}
