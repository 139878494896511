<nav class="d-flex navbar navbar-dark fixed-top">
  <div class="container-fluid">
    <div
      class="offcanvas offcanvas-end px-2"
      tabindex="-1"
      [id]="id"
      aria-labelledby="offcanvasNavbarLabel"
    >
      <div class="offcanvas-header">
        <div class="d-flex">
          <h5 class="offcanvas-title " id="offcanvasNavbarLabel">
            {{ title }}
          </h5>
        </div>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">
        <ul
          *ngFor="let list of menuItems"
          class="navbar-nav justify-content-end flex-grow-1"
        >
          <li
            *ngFor="let item of list; let i = index"
            class="course-menu-item py-2 px-2 mb-2 d-flex align-item-center"
            [ngClass]="{
              'mb-2': i < list.length - 1,
              active: selectedMenuItem.id === item.id
            }"
            (click)="onMenuItemSelected(item)"
            data-bs-toggle="offcanvas"
          >
            <i
              *ngIf="item.faIcon"
              [ngClass]="{
                'os-text-white': item.id === selectedMenuItem?.id
              }"
              class="font-22 {{ item.faIcon }}"
              style="width: 20%"
            ></i>
            <img
              *ngIf="item.activeIcon && item.icon && !item.faIcon"
              [src]="
                selectedMenuItem.id === item.id ? item.activeIcon : item.icon
              "
              class="me-3"
            />
            <span class="d-md-inline">{{ item.title }}</span>
          </li>
        </ul>
        <ng-content></ng-content>
        <shared-button
          *ngFor="let link of links"
          class="d-block py-1"
          [text]="link.title"
          [style]="'link-blue'"
          (click)="onLinkClicked(link)"
          data-bs-toggle="offcanvas"
        ></shared-button>
      </div>
    </div>
  </div>
</nav>
