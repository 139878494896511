import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  standalone: true,
  name: "keyvalue",
})
export class KeyValuePipe implements PipeTransform {
  transform(value: any): any {
    return Object.entries(value);
  }
}
