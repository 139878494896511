import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent {
  @Input() small = false;
}
