<div class="button-group os-bg-white p-2 d-flex gap-2 br-6">
  <div
    *ngFor="let buttonName of buttonNames"
    class="p-2 font-weight-500 br-6 cursor-pointer"
    [class.selected]="selectedButton === buttonName"
    (click)="selectedButtonChange.emit(buttonName)"
  >
    {{ buttonName }}
  </div>
</div>
