import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoadingDotsBlueComponent } from "../../loading/loading-dots-blue/loading-dots-blue.component";
import { LoadingSpinnerSmallWhiteComponent } from "../../loading/loading-spinner-small-white/loading-spinner-small-white.component";

type ButtonStyle = 'primary' | 'green' | 'red' | 'orange' | 'secondary' | 'link' | 'link-blue' | 'link-red';
type ButtonSize = 'extra-small' | 'small' | 'medium' | 'large';

@Component({
  standalone: true,
  selector: "shared-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
  imports: [CommonModule, LoadingDotsBlueComponent, LoadingSpinnerSmallWhiteComponent]
})
export class ButtonComponent implements OnInit {
  @Input() text!: string;
  @Input() style: ButtonStyle = "primary";
  @Input() borderStyle!: string;
  @Input() icon!: string;
  @Input() faIcon!: string;
  @Input() padding: string = "regular";
  @Input() size: ButtonSize = "small";
  @Input() fullWidth: boolean = false;

  @Input() isDisabled: boolean = false;
  @Input() isLoading: boolean = false;

  @Output() clicked = new EventEmitter<void>();

  iconSrc!: string;

  constructor() {}

  ngOnInit(): void {
    if (this.icon) {
      if (this.style === "link-blue") {
        this.iconSrc =
          "../../../assets/button_icons/" + this.icon + "_secondary.svg";
      } else {
        this.iconSrc =
          "../../../assets/button_icons/" +
          this.icon +
          "_" +
          this.style +
          ".svg";
      }
    }
  }

  protected onClick(): void {
    if (!this.isDisabled && !this.isLoading) {
      this.clicked.emit();
    }
  }
}
