import { Component, Input, OnInit } from "@angular/core";

import { Attachment } from "@app/shared/models/Attachment";
import { File as OSFile } from "@app/shared/models/File";
import { CommonModule } from "@angular/common";
import { QRCodeModule } from "angular2-qrcode";

@Component({
  standalone: true,
  selector: "shared-book-attachment",
  templateUrl: "./book-attachment.component.html",
  styleUrls: ["./book-attachment.component.scss"],
  imports: [CommonModule, QRCodeModule]
})
export class BookAttachmentComponent implements OnInit {
  @Input() attachment!: Attachment;
  @Input() isLanguageAttachment!: boolean;
  @Input() extraSmall!: boolean;

  hasImages: boolean = false;

  constructor() {}

  ngOnInit(): void {
    for (let file of this.attachment.files || []) {
      if (file.type === "image") {
        this.hasImages = true;
      }
    }
  }

  onAttachmentClicked(file: OSFile) {
    window.open(file.url, "_blank");
  }
}
