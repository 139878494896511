import { Component, OnInit } from "@angular/core";
import { UserExtended } from "@app/user/models/UserExtended";
import { UserService } from "@app/shared/services/user.service";
import { NavbarComponent } from "@app/shared/feature-components/navbar";

@Component({
  standalone: true,
  selector: "app-no-admin-access",
  templateUrl: "./no-admin-access.component.html",
  styleUrls: ["./no-admin-access.component.scss"],
  imports: [NavbarComponent]
})
export class NoAdminAccessComponent implements OnInit {
  userExtended!: UserExtended;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.getUser();
  }

  getUser() {
    this.userService.getUserExtended().subscribe({
      next: (userExtended) => {
        this.userExtended = userExtended;
      },
      error: () => {},
    });
  }
}
