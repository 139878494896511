import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FeedbackService } from "@app/feedback/feedback.service";
import { ContentBugReport } from "@app/feedback/models/ContentBugReport";
import { ContentBugType } from "@app/shared/enums/content-bug-type.enum";
import {
  SubTitleComponent,
  SelectorComponent,
  TextInputAreaComponent,
  TitleComponent,
  ButtonComponent
} from "@app/shared/basic-components";
import { CommonModule } from "@angular/common";
import { ClickOutsideDirective } from "@app/shared/directives/click-outside.directive";

@Component({
  standalone: true,
  selector: "shared-bug-report-modal",
  templateUrl: "./bug-report-modal.component.html",
  styleUrls: ["./bug-report-modal.component.scss"],
  imports: [CommonModule, TextInputAreaComponent, TitleComponent, SubTitleComponent, SelectorComponent, ButtonComponent, ClickOutsideDirective]
})
export class BugReportModalComponent implements OnInit {
  @Input() describedLocation!: string;
  @Input() contentType!: number;
  @Input() objectId!: number;
  @Input() trainingId!: number;
  @Input() selectedType!: string;

  @Output() bugReportClose = new EventEmitter<void>();

  protected ContentBugType = ContentBugType;

  protected bugReportTypes!: string[];

  protected bug: string = "";
  protected placeHolder: string =
    "Vertel zoveel mogelijk details zodat wij het probleem zo snel mogelijk kunnen verhelpen.";

  protected contentBugReport!: ContentBugReport;

  protected showThankYouPage!: boolean;
  protected noTypeErrorMessage!: string;
  protected noDescribedLocationErrorMessage!: string;
  protected isLoading!: boolean;

  constructor(private feedbackService: FeedbackService) {}

  ngOnInit(): void {
    this.bugReportTypes = Object.values(ContentBugType);
  }

  protected closeWindow() {
    this.bugReportClose.emit();
  }

  protected onSubmitClicked() {
    if (this.isLoading) return;
    if (this.selectedType === undefined) {
      this.noTypeErrorMessage = "Selecteer een type";
    } else if (
      this.describedLocation === "" ||
      this.describedLocation === undefined
    ) {
      this.noDescribedLocationErrorMessage = "Vul een locatie in";
    } else {
      this.isLoading = true;
      const contentBugReportData = this.createContentBugReportData();
      this.feedbackService
        .createContentBugReport(this.trainingId, contentBugReportData)
        .subscribe({
          next: (contentBugReport) => {
            this.isLoading = false;
            this.contentBugReport = contentBugReport;
            this.showThankYouPage = true;
          },
          error: (error) => {
            this.isLoading = false;
          },
        });
    }
  }

  protected onTypeSelected(type: string) {
    if (type === ContentBugType.TeachingMethodBug) {
      this.placeHolder =
        "Geef aan om welke hoofdstukken en paragrafen het gaat. Hoe meer informatie je geeft, hoe sneller we je kunnen helpen!";
    } else {
      this.placeHolder =
        "Vertel zoveel mogelijk details zodat wij het probleem zo snel mogelijk kunnen verhelpen.";
    }
    this.selectedType = type;
  }

  protected onDescribedLocationEntered(describedLocation: string) {
    this.describedLocation = describedLocation;
  }

  protected onBugEntered(bug: string) {
    this.bug = bug;
  }

  private createContentBugReportData(): ContentBugReport {
    return {
      type: this.bugReportTypes.indexOf(this.selectedType),
      bug: this.bug,
      described_location: this.describedLocation,
      reported_from_url: new URL(window.location.href),
      content_type: this.contentType,
      object_id: this.objectId,
    };
  }
}
