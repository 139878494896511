import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input, OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  BaseControlValueAccessorComponent
} from "../value-accessors/base-control-value-accessor";
import { AbstractControl, FormGroup, FormsModule } from "@angular/forms";
import { getValueAccessorProvider } from "../value-accessors/value-accessor-provider";
import { takeUntil } from "rxjs/operators";
import { InputErrorComponent } from "../input-error/input-error.component";

type InputSize = "small" | "medium";

@Component({
  standalone: true,
  selector: "base-input",
  templateUrl: "./base-input.component.html",
  styleUrls: ["./base-input.component.scss"],
  providers: [getValueAccessorProvider(BaseInputComponent)],
  imports: [CommonModule, FormsModule, InputErrorComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseInputComponent extends BaseControlValueAccessorComponent<string> implements OnInit {
  @Input() placeholder: string = "";
  @Input() size: InputSize = "medium";
  @Input() autocomplete: string = "other";
  @Input() dataFormType: string = "other";
  @Input() autocompleteData?: string[];
  @Input() formControlName?: string;
  @Input() formGroup?: FormGroup;

  @Output() enterPress: EventEmitter<void> = new EventEmitter();

  @ViewChild('input') input!: ElementRef<HTMLElement>;

  get isControlInvalid(): boolean {
    return !!this.control && !!this.control.errors && this.control.touched;
  }

  get control(): AbstractControl | null {
    return (this.formControlName && this.formGroup) ? this.formGroup.get(this.formControlName) : null;
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.control) {
      this.control.statusChanges.pipe(takeUntil(this.destroyed)).subscribe(() => this.cd.markForCheck())
    }
  }

  override onEdit(): void {
    super.onEdit();
    this.input.nativeElement.focus();
  }

  override onConfirmEdit(): void {
    if (this.control?.errors) return;

    super.onConfirmEdit();
  }

  protected onKeyUp(event: KeyboardEvent) {
    if (event.key === "Enter") {
      this.enterPress.emit();
    }
  }
}
