<div
  class="table-responsive os-block-light-blue-outline p-3 p-xl-4 pt-xl-3 mb-4"
>
  <table class="table table-borderless bg-white mb-0">
    <thead>
      <tr>
        <th
          *ngFor="let header of headers; let columnIndex = index"
          [ngClass]="{
            'first-column': columnIndex === 0,
            'col-xl-1': header.type === TableCellType.Grade
          }"
          (click)="onColumnClicked(columnIndex)"
        >
          <shared-table-header
            [header]="header"
            [sorting]="
              columnIndex !== sortedColumn
                ? 'none'
                : sortOrder === 'ascending'
                ? 'ascending'
                : 'descending'
            "
          ></shared-table-header>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let row of _rows; let rowIndex = index"
        class="table-row"
        [ngClass]="{
          'table-row-blue': rowIndex % 2 === 0
        }"
      >
        <td
          class="os-semi-bold"
          [ngClass]="{
            'os-link': row.url && row.url !== ''
          }"
          [innerHTML]="row.title"
          (click)="onLinkClicked($event, row.url)"
          (mousedown)="onMiddleClick($event, row.url)"
        ></td>
        <td
          *ngFor="let cell of row.cells; let columnIndex = index"
          [ngClass]="{
            'col-2': cell.type === TableCellType.Percentage,
            'cursor-pointer': cell.is_clickable
          }"
          (click)="onCellClicked(cell, rowIndex, columnIndex)"
        >
          <table-cell [cell]="cell" [column]="columnIndex"></table-cell>
        </td>
        <td *ngIf="row.hasDeleteButton">
          <i
            class="fa-solid fa-xmark os-text-red font-16 cursor-pointer"
            (click)="onDeleteClicked(row)"
          ></i>
        </td>
      </tr>

      <tr *ngIf="_rows.length === 0">
        <td class="col-4">{{ noDataMessage }}</td>
        <td *ngFor="let h of headers.slice(1)"></td>
      </tr>
    </tbody>
    <tfoot *ngIf="columns && _rows?.length > 0">
      <tr
        class="table-row border-top table-row-grey"
        style="border-color: #d0d0d0 !important"
      >
        <td class="os-semi-bold">{{ footerTitle }}</td>
        <td *ngFor="let column of columns; let columnIndex = index">
          <table-cell
            [cell]="column.summary"
            [column]="columnIndex"
          ></table-cell>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
