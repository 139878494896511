<div class="table-header one-line cursor-pointer">
  <div
    class="d-flex align-items-center"
    [ngbPopover]="description"
    popoverPlacement="top"
    triggers="mouseenter:mouseleave"
    [disablePopover]="!header.hoverText"
  >
    <i
      *ngIf="header.icon"
      class="d-none d-xl-block me-1 me-xl-2 {{ header.icon }}"
    ></i>
    {{ header.value }}
  </div>
  <i
    *ngIf="sorting === 'none'"
    class="ms-1 ms-xl-2 fa-solid fa-sort os-text-lightblue"
  ></i>
  <i
    *ngIf="sorting === 'ascending'"
    class="ms-1 ms-xl-2 fa-solid fa-sort-up os-text-blue"
  ></i>
  <i
    *ngIf="sorting === 'descending'"
    class="ms-1 ms-xl-2 fa-solid fa-sort-down os-text-blue"
  ></i>
</div>

<ng-template #description>
  <p class="mb-0" style="white-space: pre-wrap">
    {{ header.hoverText }}
  </p>
</ng-template>
