import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { Attachment } from "@app/shared/models/Attachment";
import { CommonModule } from "@angular/common";
import { SafePipe } from "@app/shared/pipes";

@Component({
  standalone: true,
  selector: "shared-attachment",
  templateUrl: "./attachment.component.html",
  styleUrls: ["./attachment.component.scss"],
  imports: [CommonModule, SafePipe]
})
export class AttachmentComponent implements OnInit {
  @Input() attachment!: Attachment;

  hasOnlyImages!: boolean;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.hasOnlyImages = true;
    this.attachment.files?.forEach((file) => {
      if (file.type !== "image") {
        this.hasOnlyImages = false;
      }
    });
  }

  onAttachmentClicked(attachment: Attachment) {
    var url = this.router.serializeUrl(
      this.router.createUrlTree(["bijlage", attachment.id])
    );
    window.open(url, "_blank");
  }

  onPdfClicked(url: string) {
    window.open(url, "_blank");
  }
}
