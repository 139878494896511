import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ImageModalComponent } from "@app/shared/feature-components/modals";
import { CommonModule } from "@angular/common";
import { SafeHtmlPipe } from "@app/shared/pipes";

@Component({
  standalone: true,
  selector: "shared-text-block",
  templateUrl: "./text-block.component.html",
  styleUrls: ["./text-block.component.scss"],
  imports: [CommonModule, SafeHtmlPipe]
})
export class TextBlockComponent implements OnInit {
  @Input() title!: string;
  @Input() text!: string;
  @Input() collapsable!: boolean;

  isCollapsed!: boolean;
  blockId!: string;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {
    this.isCollapsed = this.collapsable;
    if (this.text) {
      this.blockId =
        this.text.length + (Math.random() + 1).toString(36).substring(7);
    } else {
      this.blockId = 0 + (Math.random() + 1).toString(36).substring(7);
    }
  }

  ngAfterViewInit() {
    var imageNodes = document.getElementById(this.blockId)?.getElementsByTagName("img") as HTMLCollection;

    for (var i = 0; i < imageNodes.length; i++) {
      imageNodes[i].addEventListener("click", this.enlargeImage.bind(this));
    }
  }

  enlargeImage(imgElement: Event) {
    const imageModalRef = this.modalService.open(ImageModalComponent, {
      windowClass: "image-modal",
    });
    imageModalRef.componentInstance.imageSrc = (imgElement.target as HTMLImageElement).src;
  }

  toggleIsCollapse() {
    if (this.collapsable) {
      this.isCollapsed = !this.isCollapsed;
    }
  }
}
