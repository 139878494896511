import { UtilService } from "@app/shared/services/util.service";
import { PublicUserExtended } from "./PublicUserExtended";
import { Level } from "./Level";

export class PublicUser {
  public name!: string;
  public levelYear!: string;

  constructor(
    public id: number,
    private first_name: string,
    public user_extended: PublicUserExtended,
    public level: Level
  ) {
    this.setName();
    this.setLevelYear();
  }

  public static Factory(user: PublicUser): PublicUser {
    return new PublicUser(
      user.id,
      user.first_name,
      user.user_extended,
      user.level
    );
  }

  private setName() {
    if (this.user_extended.username) {
      this.name = this.user_extended.username;
    } else if (this.first_name) {
      this.name = this.first_name;
    } else {
      this.name = "Onbekend";
    }
  }

  private setLevelYear() {
    if (this.user_extended.level && this.user_extended.year) {
      this.levelYear = `${UtilService.LevelToString(
        this.user_extended.level
      )} - ${this.user_extended.year}`;
    } else {
      this.levelYear = "Geen niveau";
    }
  }
}
