import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, ElementRef, EventEmitter, Input, Output
} from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

@Component({
  standalone: true,
  selector: "shared-foldable-block",
  templateUrl: "./foldable-block.component.html",
  styleUrls: ["./foldable-block.component.scss"],
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("openClose", [
      state("closed", style({ height: "0", overflow: "hidden" })),
      state("opened", style({ height: "*", overflow: "hidden" })),
      transition("closed <=> opened", animate("0.3s ease-out")),
    ]),
  ],
})
export class FoldableBlockComponent implements AfterViewInit {
  @Input() set isFoldable(isFoldable: boolean) {
    this._isFoldable = isFoldable;

    if (!isFoldable) this.isExpanded = true;
  }
  @Input() hasBackground = true;
  @Input() isExpanded = true;

  @Output() animationDone = new EventEmitter<void>();
  @Output() elementInView = new EventEmitter<void>();

  protected _isFoldable = false;

  private observer!: IntersectionObserver;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    const options = {
      threshold: 0.5
    };

    this.observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.elementInView.emit();
        }
      })
    }, options);
    this.observer.observe(this.elementRef.nativeElement);
  }

  protected onAnimationDone(): void {
    this.animationDone.emit();
  }
}
