import { AvatarObject } from "./AvatarObject";

type OptionalColorItem = string | null;

export class AvatarConfiguration {
  id!: number;
  user!: number;
  url!: string;
  skin_color!: string;
  hair!: AvatarObject | null;
  hair_color!: string;
  facial_hair!: AvatarObject | null;
  facial_hair_color!: OptionalColorItem;
  eyes!: AvatarObject;
  eye_brows!: AvatarObject;
  mouth!: AvatarObject;
  clothes!: AvatarObject;
  clothes_color!: OptionalColorItem;
  hat!: AvatarObject | null;
  glasses!: AvatarObject | null;
  clothes_icon!: AvatarObject | null;
  clothes_icon_color!: OptionalColorItem;
  background!: AvatarObject | null;
  body?: AvatarObject;

  constructor(avatarConfig: AvatarConfiguration) {
    Object.assign(this, avatarConfig);

    const avatarConfigKeys: (keyof AvatarConfiguration)[] = [
      'hair',
      'facial_hair',
      'eyes',
      'eye_brows',
      'mouth',
      'clothes',
      'hat',
      'glasses',
      'clothes_icon'
    ];

    avatarConfigKeys.forEach(key => {
      if (avatarConfig[key]) {
        (this[key] as AvatarObject) = new AvatarObject(avatarConfig[key] as AvatarObject);
      }
    });
  }

  public getDTO(url: string) {
    return {
      url: url,
      skin_color: this.skin_color as string,
      hair: (this.hair as AvatarObject)?.id || null,
      hair_color: this.hair_color as string,
      facial_hair: (this.facial_hair as AvatarObject)?.id || null,
      facial_hair_color: this.facial_hair_color as string,
      eyes: (this.eyes as AvatarObject)?.id,
      eye_brows: (this.eye_brows as AvatarObject)?.id,
      mouth: (this.mouth as AvatarObject)?.id,
      clothes: (this.clothes as AvatarObject)?.id,
      clothes_color: this.clothes_color as string,
      hat: (this.hat as AvatarObject)?.id || null,
      glasses: (this.glasses as AvatarObject)?.id || null,
      clothes_icon: (this.clothes_icon as AvatarObject)?.id || null,
      clothes_icon_color: this.clothes_icon_color as string,
      background: (this.background as AvatarObject)?.id || null,
    };
  }
}
