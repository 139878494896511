import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@Component({
  standalone: true,
  selector: "shared-circular-progress-bar",
  templateUrl: "./circular-progress-bar.component.html",
  styleUrls: ["./circular-progress-bar.component.scss"],
  imports: [CommonModule, NgbTooltip, MatProgressSpinnerModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CircularProgressBarComponent implements OnInit {
  @Input() set currentValue(currentValue: number) {
    this._currentValue = currentValue;
    this.width = this.calculateBarWidth(currentValue, this.maxValue);
  }

  @Input() maxValue!: number;
  @Input() iconClass!: string;
  @Input() showProgressCaption = true;
  @Input() tooltipText?: string;

  protected _currentValue!: number;
  protected width!: number;

  constructor() {}

  ngOnInit(): void {
    this.width = this.calculateBarWidth(this._currentValue, this.maxValue);
  }

  private calculateBarWidth(currentValue: number, maxValue: number) {
    if (currentValue === 0) return 0;
    return (100 / maxValue) * currentValue;
  }
}
