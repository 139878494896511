<shared-navbar></shared-navbar>
<div class="os-page-background scroll-padding pt-3 pt-xl-5 px-2 px-xl-0">
  <div *ngIf="isProfileOwner" class="flex-center-center mb-3">
    <a class="os-link" routerLink="/auth/mijn-account">
      Accountgegevens <i class="fa fa-pencil"></i>
    </a>
  </div>
  <div
    class="col-md-9 col-lg-8 col-xl-7 col-xxl-5 mx-auto os-block-3 p-3 p-xl-4 pb-xxl-5"
  >
    <div class="col-12 mb-2 d-flex justify-content-end">
      <i
        class="fa-solid fa-xmark os-text-red cursor-pointer"
        (click)="onCloseProfile()"
      ></i>
    </div>
    <div class="col-xl-10 mx-auto">
      <div *ngIf="!isLoading; else loadingTemplate">
        <div class="mb-5 flex-center-center">
          <a *ngIf="isProfileOwner; else avatarTemplate" class="os-no-underline" routerLink="/auth/mijn-account/avatar">
            <ng-container *ngTemplateOutlet="avatarTemplate"></ng-container>
          </a>

          <ng-template #avatarTemplate>
            <shared-avatar
              size="extra-large"
              [displayEditButton]="isProfileOwner"
              [avatarUrl]="profile.user.user_extended.avatar"
              [userName]="profile.user.name"
            ></shared-avatar>
          </ng-template>
        </div>

        <div class="flex-between-center gap-3 mb-4 pb-3 border-bottom">
          <div class="col-6">
            <div class="d-flex align-items-center gap-3">
              <div class="font-22 os-semi-bold break-word">
                {{ profile.user.name }}
              </div>
            </div>
            <div class="d-flex align-items-center gap-3">
              <div class="os-text-lightgrey">{{ profile.user.levelYear }}</div>
            </div>
          </div>
          <div class="col-6">
            <div
              class="d-flex align-items-center justify-content-end flex-wrap gap-1 mb-1"
            >
              <ng-container *ngFor="let training of displayedTrainings">
                <img
                  *ngIf="training.icon"
                  [src]="training.icon"
                  width="28x"
                  height="28x"
                  [ngbTooltip]="training.training_name"
                />
              </ng-container>
              <i
                *ngIf="!allTrainingsShown"
                class="font-24 fas fa-ellipsis-h cursor-pointer"
                (click)="onShowAllTrainings()"
              ></i>
            </div>
            <div *ngIf="profile.user.user_extended.school" class="text-end">
              <span class="os-text-lightgrey">
                Zit op {{ profile.user.user_extended.school }}
              </span>
            </div>
          </div>
        </div>

        <div
          *ngIf="profile.user.level as userLevel"
          class="d-flex align-items-center gap-4 mb-4 pb-3 border-bottom"
        >
          <div class="flex-grow-1">
            <div class="flex-between-center os-bold font-18 mb-1">
              <div>Level {{ userLevel.level }}</div>

              <i
                class="fas fa-question-circle-o cursor-pointer fa-primary-color font-18"
                (click)="onOpenXpInfoModal()"
              ></i>
            </div>
            <ng-container *ngIf="isProfileOwner">
              <progress-bar
                [achieved]="userLevel.xp"
                [potential]="userLevel.xp_required"
              ></progress-bar>
              <span class="font-14 os-text-lightgrey">
                {{ userLevel.xp_required - userLevel.xp }} XP tot level
                {{ userLevel.level + 1 }}
              </span>
            </ng-container>

            <ng-container *ngIf="!isProfileOwner">
              <span class="font-14 os-text-lightgrey"
                >{{ userLevel.xp_total }} XP</span
              >
            </ng-container>
          </div>
        </div>

        <div class="font-22 os-semi-bold mb-3">Statistieken</div>
        <div class="user-stat-block">
          <user-stat-block
            *ngFor="let statItem of statItems"
            [statItem]="statItem"
          ></user-stat-block>
        </div>
      </div>
    </div>

    <ng-template #loadingTemplate>
      <loading
        loadingTitle="Even geduld..."
        loadingSubtitle="Gegevens worden geladen..."
      ></loading>
    </ng-template>
  </div>
</div>
