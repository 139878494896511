import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "@app/auth/auth.service";
import { PreSignUpUser } from "@app/models/PreSignUpUser";
import { UserExtended } from "@app/user/models/UserExtended";
import { UtilService } from "@app/shared/services/util.service";
import { CookieService } from "ngx-cookie-service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SchoolPublicData } from "../../models/SchoolPublicData";
import { TeacherInviteCode } from "../../models/TeacherInviteCode";
import { SchoolService } from "../../school.service";

@Component({
  selector: "app-derooipannen",
  templateUrl: "./derooipannen.component.html",
  styleUrls: ["./derooipannen.component.scss"],
})
export class DeRooiPannenComponent implements OnInit {
  studentSignUpForm = new FormGroup({
    firstName: new FormControl("", Validators.required),
    lastName: new FormControl("", Validators.required),
    email: new FormControl("", [Validators.required, Validators.email]),
    password: new FormControl("", [
      Validators.required,
      Validators.minLength(6),
    ]),
    inviteCode: new FormControl("", Validators.required),
  });

  private unsubscribe = new Subject();

  loadingMessage!: string;
  errorMessage!: string;
  isLoading!: boolean;
  showLoginMessage!: boolean;
  registerLoginTitle!: string;

  schoolShortCode!: string;

  userExtended!: UserExtended;
  school!: SchoolPublicData;
  teacherInviteCode!: TeacherInviteCode;

  user!: PreSignUpUser;

  userTypes!: string[];
  actions!: string[];
  selectedUserType!: string;
  selectedAction!: string;
  showPassword!: boolean;

  constructor(
    private schoolService: SchoolService,
    private authService: AuthService,
    private cookieService: CookieService,
    private utilService: UtilService,
    private router: Router
  ) {}

  ngOnInit() {
    if (UtilService.isSchool24()) {
      window.open("https://portal.onlineslagen.nl/derooipannen", "_self");
    }
    this.utilService.setTitle("De Rooi Pannen - Aanmelden");
    this.isLoading = false;
  }

  onStudentSignUpFormSubmit() {
    if (this.studentSignUpForm.valid) {
      this.user = this.utilService.createPreSignUpUser(
        (this.studentSignUpForm.get("email")?.value ?? '').toLowerCase(),
        this.studentSignUpForm.get("password")?.value ?? '',
        this.studentSignUpForm.get("firstName")?.value ?? '',
        this.studentSignUpForm.get("lastName")?.value ?? '',
        this.studentSignUpForm.get("inviteCode")?.value ?? ''
      );
      this.setState("", "Voucher code controleren", true, false);
      this.activateInviteCode();
    }
  }

  onUserTypeSelected(selectedUserType: string) {
    this.selectedUserType = selectedUserType;
  }

  onActionSelected(selectedAction: string) {
    this.selectedAction = selectedAction;
  }

  activateInviteCode() {
    this.schoolService
      .activateInviteCode(this.user, this.user.inviteCode as string)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: () => {
          this.setState("", "Code geldig! Account aanmaken...", true, false);
          this.loginUserPortal();
        },
        error: (error) => {
          this.handleActivateSchoolInviteCodeError(error);
        },
      });
  }

  loginUserPortal() {
    this.authService
      .learningPortalLogin(this.user)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (output_portal) => {
          this.cookieService.set("token", output_portal.key);
          this.setState(
            "",
            "Ingelogd! Je wordt nu doorverwezen naar de trainingen",
            true,
            false
          );
          this.router.navigateByUrl("/home/vakken");
        },
        error: (error) => {
          this.handleLoginError(error);
        },
      });
  }

  handleActivateSchoolInviteCodeError(error: HttpErrorResponse) {
    if (error.status == 404) {
      this.setState("Voucher code ongeldig.", "", false, true);
    } else if (error.status == 401) {
      this.setState("Deze code is helaas al gebruikt.", "", false, true);
    } else if (error.status == 403) {
      this.setState("Emailadres is al gebruikt.", "", false, true);
    } else {
      this.handleError(error);
    }
  }

  handleLoginError(error: HttpErrorResponse) {
    if (error.status == 401) {
      this.setState("", "Accountgegevens kloppen niet.", false, true);
    } else {
      this.handleError(error);
    }
  }

  handleError(error: Error) {
    console.log(error);
    this.setState(
      "",
      "Er ging iets onverwacht fout. Controleer je verbinding of neem contact met ons op.",
      false,
      true
    );
  }

  setState(
    errorMessage: string,
    loadingMessage: string,
    isLoading: boolean,
    showLoginMessage: boolean
  ) {
    this.errorMessage = errorMessage;
    this.loadingMessage = loadingMessage;
    this.isLoading = isLoading;
    this.showLoginMessage = showLoginMessage;
  }

  ngOnDestroy() {
    (window as any).fcWidget.destroy();
    this.unsubscribe.next({});
    this.unsubscribe.complete();
  }
}
