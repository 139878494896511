import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { StatItem } from "../../models/StatItem";

@Component({
  selector: "user-stat-block",
  templateUrl: "./stat-block.component.html",
  styleUrls: ["./stat-block.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class StatBlockComponent {
  @Input() statItem!: StatItem;
}
