import { HttpErrorResponse } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "@app/auth/auth.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonModule } from "@angular/common";
import {
  ButtonComponent,
  ErrorMessageComponent,
  InputComponent, LoadingSpinnerComponent,
  SubSubTitleComponent,
  SubTitleComponent
} from "@app/shared/basic-components";
import { finalize } from "rxjs";

type AccountType = "none" | "unverified";

@Component({
  standalone: true,
  selector: "shared-s24-create-demo-account-modal",
  templateUrl: "./s24-create-demo-account-modal.component.html",
  styleUrls: ["./s24-create-demo-account-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, SubTitleComponent, ErrorMessageComponent, InputComponent, SubSubTitleComponent, ButtonComponent, LoadingSpinnerComponent]
})
export class S24CreateDemoAccountModalComponent implements OnInit {
  @Input() roundBorders = true;

  @Output() modalClosed = new EventEmitter<void>();
  @Output() accountTypeChanged = new EventEmitter<AccountType>();

  protected signUpForm = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.email]),
  });

  protected errorMessage!: string;
  protected isLoading!: boolean;
  protected accountType!: AccountType;

  constructor(
    @Optional() public activeModal: NgbActiveModal,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.setAccountType();
  }

  private setAccountType(): void {
    if (localStorage.getItem("has-created-account") === "true") {
      this.accountType = "unverified";
    } else {
      this.accountType = "none";
    }
  }

  protected closeModal(): void {
    if (this.activeModal) {
      this.activeModal.close();
    } else {
      this.modalClosed.emit();
    }
  }

  protected refresh(): void {
    this.isLoading = true;
    window.location.reload();
  }

  protected onCreateNewAccount(): void {
    localStorage.setItem("has-created-account", "false");
    this.accountType = "none";
    this.accountTypeChanged.emit(this.accountType);
  }

  protected submitForm(): void {
    if (this.signUpForm.valid) {
      this.createDemoAccount(this.signUpForm.get("email")?.value as string);
    }
  }

  private createDemoAccount(email: string): void {
    this.errorMessage = "";
    this.isLoading = true;
    this.authService
      .createDemoStudentUser({ email: email, product: "school24" })
      .pipe(finalize(() => {
        this.isLoading = false;
        this.cdr.markForCheck();
      }))
      .subscribe({
        next: () => {
          localStorage.setItem("has-created-account", "true");
          this.accountType = "unverified";
          this.accountTypeChanged.emit(this.accountType);
        },
        error: (error: HttpErrorResponse) => {
          if (error.status === 400) {
            this.errorMessage = "Er bestaat al een gebruiker met dit e-mail.";
          } else {
            this.errorMessage =
              "Er is iets misgegaan. Neem contact met ons op.";
          }
        },
      });
  }
}
