import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ClickOutsideDirective } from "@app/shared/directives/click-outside.directive";
import { SafeHtmlPipe } from "@app/shared/pipes";

@Component({
  standalone: true,
  selector: "shared-content-modal",
  templateUrl: "./content-modal.component.html",
  styleUrls: ["./content-modal.component.scss"],
  imports: [ClickOutsideDirective, SafeHtmlPipe]
})
export class ContentModal {
  @Input() content!: string;
  @Input() title!: string;
  @Output() onCloseWindow = new EventEmitter<void>();

  closeWindow() {
    this.onCloseWindow.emit();
  }
}
