<div class="col-12 col-xl-9 mx-auto  p-4 p-xl-5 pb-3 pb-xl-3">
  <shared-sub-title
    class="d-block mb-3"
    [title]="'Zo vind je je personeelsnummer'"
  ></shared-sub-title>
  <ol class="" style="padding-left: 1rem">
    <li>Log in op <a href="https://sam.ah.nl" class="os-link" target="_blank">sam.ah.nl</a></li>
    <li>Ga naar MyHR Connect</li>
    <li>Ga naar beloningsoverzicht en klik op beloningsoverzicht weergeven</li>
    <li>Zoek je personeelsnummer op je loonstrookje (zie foto hieronder!)</li>
  </ol>
</div>
<img
  style="max-width: 100%"
  src="https://os-file-storage.ams3.cdn.digitaloceanspaces.com/media%2Fuploads%2Floonstrookje_ah_afbeelding-min.png"
/>
