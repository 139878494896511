export enum ExamStatus {
  Started = 0,
  Running = 1,
  Paused = 2,
  Finished = 3,
  Archived = 4,
  ToBeReviewed = 5,
  ToBeFullyReviewed = 6,
  Deleted = 7
}
