import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { Category } from "@app/models/onboarding/Category";
import { UserAction } from "@app/models/onboarding/UserAction";
import {
  trigger,
  transition,
  query,
  style,
  stagger,
  animate,
} from "@angular/animations";
import { OnboardingService } from "@app/shared/services/onboarding.service";
import { OnboardingVideo } from "@app/models/onboarding/OnboardingVideo";
import { UtilService } from "@app/shared/services/util.service";
import { UserExtended } from "@app/user/models/UserExtended";
import { CommonModule } from "@angular/common";
import { ButtonComponent, LoadingComponent } from "@app/shared/basic-components";

@Component({
  standalone: true,
  selector: "checklists",
  templateUrl: "./checklists.component.html",
  styleUrls: ["./checklists.component.scss"],
  imports: [CommonModule, LoadingComponent, ButtonComponent],
  animations: [
    trigger("fadeIn", [
      transition(":enter", [
        query(":enter", [
          style({ opacity: 0 }),
          stagger("75ms", [animate("250ms", style({ opacity: 1 }))]),
        ]),
      ]),
    ]),
  ],
})
export class ChecklistsComponent implements OnInit {
  @Input() userExtended!: UserExtended;
  @Output() newData: EventEmitter<{
    nrFinishedActions: number;
    nrTotalActions: number;
  }> = new EventEmitter();
  @Output() close: EventEmitter<void> = new EventEmitter();

  currentPage?:
    | "welcome"
    | "overview"
    | "actions-finished"
    | "checklist-completed";

  categories?: Category[];
  userActions?: UserAction[];
  newFinishedUserActions?: UserAction[];
  finishedActionIds?: number[];
  nrTotalActions: number = 0;

  isLoading!: boolean;
  nrToggledCategories: number = 0;

  productName!: string;

  constructor(
    private onboardingService: OnboardingService,
    private utilService: UtilService
  ) {}

  ngOnInit(): void {
    this.productName = UtilService.getProductName();
    this.getUserActions();
  }

  getUserActions() {
    this.isLoading = true;
    this.onboardingService
      .getUserActions({
        user_role: this.userExtended.role,
      })
      .subscribe({
        next: (userActions: UserAction[]) => {
          this.userActions = userActions;
          this.setFinishedActionStats();
          this.getCheckLists();
        },
      });
  }

  setFinishedActionStats() {
    this.finishedActionIds = [];
    this.newFinishedUserActions = [];
    this.userActions?.forEach((userAction: UserAction) => {
      if (userAction.finished_at) {
        this.finishedActionIds!.push(userAction.action.id);
        if (userAction.is_new) {
          this.newFinishedUserActions!.push(userAction);
        }
      }
    });
  }

  getCheckLists() {
    this.onboardingService
      .getCheckLists({ parent: true, user_role: this.userExtended.role })
      .subscribe({
        next: (categories) => {
          this.categories = categories;
          this.categories.forEach((category) => {
            this.calculateCategoryStats(category);
          });
          setTimeout(() => {
            this.initializeCurrentPage();
            this.newData.emit({
              nrFinishedActions: this.finishedActionIds!.length,
              nrTotalActions: this.nrTotalActions,
            });
          }, 100);
          this.isLoading = false;
        },
      });
  }

  calculateCategoryStats(category: Category) {
    if (
      category.product === "any" ||
      category.product === this.productName.toLowerCase()
    ) {
      this.nrTotalActions += category.actions?.length;
      var nrCompleted = 0;
      category.actions.forEach((action) => {
        if (this.finishedActionIds!.indexOf(action.id) > -1) {
          nrCompleted++;
        }
      });
      category.nrCompleted = nrCompleted;
      if (
        category.nrCompleted !== category.actions.length &&
        this.nrToggledCategories === 0
      ) {
        category.toggled = true;
        this.nrToggledCategories++;
      }

      category.children?.forEach((child) => {
        this.calculateCategoryStats(child);
      });
    }
  }

  initializeCurrentPage() {
    const showWelcomePage = !localStorage["welcomePageShown"];
    const showChecklistCompleted = !localStorage["checkistCompletedShown"];
    if (showWelcomePage) {
      this.currentPage = "welcome";
      localStorage["welcomePageShown"] = "TRUE";
    } else if (this.newFinishedUserActions!.length > 0) {
      this.currentPage = "actions-finished";
      this.updateUserActions();
    } else if (
      this.nrTotalActions === this.userActions!.length &&
      showChecklistCompleted
    ) {
      this.currentPage = "checklist-completed";
      localStorage["checkistCompletedShown"] = "TRUE";
    } else {
      this.currentPage = "overview";
    }
  }

  onUnderstandClicked() {
    if (this.newFinishedUserActions!.length > 0) {
      this.currentPage = "actions-finished";
      this.updateUserActions();
    } else {
      this.currentPage = "overview";
    }
  }

  setCurrentPage(
    page: "welcome" | "overview" | "actions-finished" | "checklist-completed"
  ) {
    if (this.currentPage === "actions-finished" && page === "overview") {
      this.newFinishedUserActions = [];
      const showChecklistCompleted = !localStorage["checkistCompletedShown"];
      if (
        this.nrTotalActions === this.userActions!.length &&
        showChecklistCompleted
      ) {
        this.currentPage = "checklist-completed";
        localStorage["checkistCompletedShown"] = "TRUE";
      } else {
        this.currentPage = page;
      }
    } else {
      this.currentPage = page;
    }
  }

  updateUserActions() {
    this.newFinishedUserActions?.forEach((userAction) => {
      userAction.is_new = false;
      this.onboardingService
        .updateUserAction(userAction)
        .subscribe({ next: () => {} });
    });
  }

  onOpenVideoClicked(video: OnboardingVideo) {
    this.onboardingService.openOnboardingVideoModal(this.userExtended, video);
  }

  onCloseChecklistsClicked() {
    this.close.emit();
  }

  onReloadClicked() {
    this.categories = undefined;
    this.currentPage = undefined;
    this.userActions = undefined;
    this.newFinishedUserActions = undefined;
    this.finishedActionIds = undefined;
    this.nrTotalActions = 0;
    this.getUserActions();
  }
}
