import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
  standalone: true,
  selector: "shared-text-placeholder",
  templateUrl: "./text-placeholder.component.html",
  styleUrls: ["./text-placeholder.component.scss"],
  imports: [CommonModule]
})
export class TextPlaceholderComponent { }
