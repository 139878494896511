<div [class.os-block-3]="hasBackground">
  <!-- Block header -->
  <div
    class="d-flex p-3 pl-0 px-md-4 cursor-pointer gap-4 align-items-center w-100"
    (click)="isExpanded = !this.isExpanded"
  >
    <div class="header-content flex-grow-1">
      <ng-content select="[foldableBlockHeader]"></ng-content>
    </div>
    <div *ngIf="_isFoldable" class="flex-center-center">
      <i
        class="fa-primary-color"
        [ngClass]="isExpanded ? 'fa fa-chevron-up' : 'fa fa-chevron-down'"
      ></i>
    </div>
  </div>

  <!-- Block content -->
  <div
    class="col-12 px-2 px-md-4"
    [@openClose]="isExpanded ? 'opened' : 'closed'"
    (@openClose.done)="onAnimationDone()"
  >
    <div class="pt-3 pb-4 border-top">
      <ng-content></ng-content>
    </div>
  </div>
</div>
