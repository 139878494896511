import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { AuthService } from "@app/auth/auth.service";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonModule } from "@angular/common";
import {
  ButtonComponent,
  ErrorMessageComponent,
  InputComponent,
  LoadingSpinnerComponent,
  SubSubTitleComponent,
  SubTitleComponent,
} from "@app/shared/basic-components";
import { S24CreateDemoAccountModalComponent } from "../s24-create-demo-account-modal/s24-create-demo-account-modal.component";
import { ButtonGroupComponent } from "@app/shared/basic-components/buttons/button-group/button-group.component";
import { PremiumSubscriptionType } from "@app/shared/enums/premium-subscription-type";
import { Product } from "@app/shop/models/Product";
import { ShopService } from "@app/shared/services/shop.service";
import { ClickOutsideDirective } from "@app/shared/directives/click-outside.directive";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

enum AnimationState {
  INITIAL = "initial",
  FADED = "faded",
  DISPLAYED = "displayed",
  HIDDEN = "hidden",
  SHRUNK = "shrunk",
}

@Component({
  standalone: true,
  selector: "shared-s24-premium-modal",
  templateUrl: "./s24-premium-modal.component.html",
  styleUrls: ["./s24-premium-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    SubTitleComponent,
    ErrorMessageComponent,
    InputComponent,
    SubSubTitleComponent,
    ButtonGroupComponent,
    ButtonComponent,
    LoadingSpinnerComponent,
    ClickOutsideDirective,
  ],
  animations: [
    trigger("fadeOut", [
      state(AnimationState.INITIAL, style({ opacity: 1 })),
      state(AnimationState.FADED, style({ opacity: 0 })),
      transition("initial => faded", animate("300ms ease-out")),
    ]),
    trigger("displayHide", [
      state(
        AnimationState.DISPLAYED,
        style({ height: "*", overflow: "hidden" })
      ),
      state(AnimationState.HIDDEN, style({ height: "0", overflow: "hidden" })),
      transition("displayed <=> hidden", animate("300ms ease-out")),
    ]),
    trigger("shrinkHeight", [
      state(AnimationState.INITIAL, style({ height: "*" })),
      state(
        AnimationState.SHRUNK,
        style({ height: "150px", marginBottom: "22px" })
      ),
      transition("initial <=> shrunk", animate("300ms ease-out")),
    ]),
  ],
})
export class S24PremiumModalComponent implements OnInit {
  @Input() mode: "public" | "free" = "free";

  protected readonly animationState = AnimationState;
  protected readonly subscriptionType = PremiumSubscriptionType;
  protected readonly allSubscriptionTypes = Object.values(
    PremiumSubscriptionType
  );
  protected readonly comparisonItems: string[] = [
    "5000 + samenvattingen",
    "Leren met jouw lesmethode",
    "Markeren + notities",
    "5000+ video's",
    "Oefenopgaven + uitleg",
    "Onbeperkt toetsen",
    "Zwakke en sterke punten",
    "Woordenlijsten",
    "Begrippen + flashcards",
    "Overzicht markeringen + notities",
    "Samenvattingenboekje (pdf)",
    "Begrippenboekje (pdf)",
  ];

  protected productsInfo: Record<PremiumSubscriptionType, Product> =
    {} as Record<PremiumSubscriptionType, Product>;
  protected selectedProduct!: Product;
  protected selectedSubscriptionType!: PremiumSubscriptionType;

  protected accountType!: "none" | "unverified" | "verified";

  protected isLoading = false;

  protected isMoreInfoView = false;

  constructor(
    private shopService: ShopService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private authService: AuthService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    if (this.authService.isAuthenticated()) {
      this.accountType = "verified";
    } else {
      if (localStorage.getItem("has-created-account") === "true") {
        this.accountType = "unverified";
      } else {
        this.accountType = "none";
      }
    }

    this.getProducts();
  }

  private getProducts() {
    this.shopService
      .getProducts({ product_line: "school24" })
      .subscribe((products) => {
        this.productsInfo[PremiumSubscriptionType.MONTHLY] = products.find(
          (product) => product.interval === "month"
        ) as Product;
        this.productsInfo[PremiumSubscriptionType.YEARLY] = products.find(
          (product) => product.interval === "year"
        ) as Product;
        this.selectedSubscriptionType = PremiumSubscriptionType.YEARLY;
        this.selectedProduct =
          this.productsInfo[PremiumSubscriptionType.YEARLY];
        this.isLoading = false;
        this.cdr.markForCheck();
      });
  }

  protected closeModal() {
    this.activeModal.close();
  }

  protected onSubscriptionTypeSwitch(type: PremiumSubscriptionType): void {
    this.selectedProduct = this.productsInfo[type];
    this.selectedSubscriptionType = type;
  }

  protected goToShop() {
    this.closeModal();
    window.open(`/winkel?product_id=${this.selectedProduct.id}`, "_blank");
  }

  protected onCreateDemoAccount(): void {
    this.activeModal.close();

    if (this.accountType !== "verified") {
      setTimeout(() => {
        this.modalService.open(S24CreateDemoAccountModalComponent, {
          size: "sm",
          backdropClass: "os-backdrop-blurred",
          modalDialogClass: "os-modal-offset-top",
          animation: false,
        });
      }, 150);
    }
  }
}
