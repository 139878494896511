import { TrainingLevelEnum } from "@app/shared/enums/TrainingLevelEnum";
import { Attachment } from "@app/shared/models/Attachment";
import { UtilService } from "@app/shared/services/util.service";

export enum Category {
  SOCIETY = "maatschappij",
  EXACT = "exact",
  LANGUAGES = "talen",
}

export class Training {
  id!: number;
  training_name!: string;
  training_type!: number;
  training_image_url!: string;
  training_webshop_url!: string;
  training_level!: number;
  thumbnail_url!: URL;
  production_ready?: boolean;
  category?: Category;
  phase!: string;
  attachments!: Attachment[];
  examenbundel_ean!: number;
  icon?: string;

  nameShort!: string;
  nameLong!: string;
  levelString!: string;

  constructor(training: Training) {
    Object.assign(this, training);
    this.nameShort = this.getTrainingNameShort();
    this.nameLong = this.getTrainingNameLong();
    this.levelString = UtilService.LevelToString(this.training_level);
  }

  // i.e. Biologie VWO
  getTrainingNameShort() {
    if (this.training_name !== "") {
      return (
        this.training_name +
        " " +
        TrainingLevelEnum[this.training_level].toUpperCase()
      );
    } else {
      return "";
    }
  }

  // i.e. Biologie VWO | Bovenbouw
  getTrainingNameLong() {
    if (this.training_name !== "") {
      return (
        this.training_name +
        " " +
        TrainingLevelEnum[this.training_level].toUpperCase() +
        " | " +
        this.phase.replace(/^./, this.phase[0].toUpperCase())
      );
    } else {
      return "";
    }
  }
}
