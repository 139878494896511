<div
  class="os-text-secondary cursor-pointer"
  (clickOutside)="showList = false"
  (click)="onShowListClicked()"
>
  <div class="select" [ngClass]="showList ? 'select-open' : ''">
    <shared-button [text]="text"></shared-button>
    <div
      class="font-16 os-semi-bold select-unfolded"
      [ngClass]="{
        left: direction === 'left',
        right: direction === 'right'
      }"
      *ngIf="showList"
    >
      <div
        *ngFor="let item of list"
        class="unfold-item py-1 px-2"
        [ngClass]="{
          'selected-item': key
            ? selectedItem && selectedItem[key] === item[key]
            : selectedItem === item
        }"
        (click)="onItemSelected(item)"
      >
        <span>
          {{ key ? item[key] : item }}
        </span>
      </div>
    </div>
  </div>
</div>
