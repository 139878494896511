<div
  *ngFor="let step of steps; let i = index"
  class="os-step p-2 p-xl-3 os-step-activated"
  [ngClass]="{
    'os-step-active': step === currentStep,
    'os-step-activated': activatedSteps.includes(step)
  }"
  (click)="onStepClicked(step)"
>
  <span class="os-step-icon mx-auto mx-xl-2 px-xl-2 mb-1 mb-xl-0">
    <span
      *ngIf="i >= steps.indexOf(currentStep); else stepCompletedIconTemplate"
    >
      {{ i + 1 }}
    </span>
    <ng-template #stepCompletedIconTemplate>
      <i class="fa-solid fa-check"></i>
    </ng-template>
  </span>
  <span class="d-none d-xl-block">{{ step }}</span>
  <span
    class="d-block d-xl-none font-11 os-semi-bold"
    [ngClass]="{ 'os-text-blue': step === currentStep }"
  >
    {{ step }}
  </span>
</div>
