import { Component, Input } from "@angular/core";

@Component({
  standalone: true,
  selector: "shared-sub-title",
  templateUrl: "./sub-title.component.html",
  styleUrls: ["./sub-title.component.scss"],
})
export class SubTitleComponent {
  @Input() title!: string;
}
