<div [formGroup]="group">
  <span
    *ngIf="group.get(controlName)?.touched"
    [ngClass]="{
      'red-line': group.get(controlName)?.errors,
      'green-line': !group.get(controlName)?.errors,
      small: size === 'small',
      medium: size === 'medium'
    }"
  ></span>
  <input
    class="form-control os-text-input font-16"
    [attr.list]="'dataset-' + controlName"
    [formControlName]="controlName"
    [ngClass]="{
      'is-invalid':
        group.get(controlName)?.errors && group.get(controlName)?.touched,
      small: size === 'small',
      medium: size === 'medium'
    }"
    [type]="type === 'password' ? (showPassword ? 'text' : 'password') : type"
    [autocomplete]="autocomplete"
    [maxlength]="maxLength"
    [placeholder]="placeHolder"
    [id]="controlName"
    [attr.data-form-type]="dataFormType"
    (keyup)="onKeyUp($event)"
  />
  <div
    *ngIf="group.get(controlName)?.errors && group.get(controlName)?.touched"
    class="text-danger font-14 os-semi-bold"
  >
    {{ errorMessage }}
  </div>
  <div
    class="d-flex align-items-center justify-content-start mt-2 cursor-pointer "
    *ngIf="type === 'password'"
    (click)="onShowPassword()"
  >
    <input
      type="checkbox"
      class="me-2"
      style="width: 20px; height: 20px"
      [checked]="showPassword"
    />Toon wachtwoord
  </div>
</div>

<datalist *ngIf="dataset" id="dataset-{{ controlName }}">
  <option *ngFor="let option of dataset" [value]="option"></option>
</datalist>
