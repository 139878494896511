<div class="d-flex gap-2">
  <div
    *ngFor="let star of stars; let i = index"
    class="cursor-pointer"
    (click)="onRatingClicked(i + 1)"
    (mouseenter)="onRatingHover(i + 1)"
    (mouseleave)="onRatingHover(null)"
  >
    <i class="fa font-22 {{ getIconClass(i + 1) }}"></i>
  </div>
</div>

<base-modal
  *ngIf="isCommentModalOpen"
  modalSize="sm"
  [showCloseIcon]="false"
  [submitDisabled]="!comment"
  (modalClosed)="isCommentModalOpen = false"
  (modalSubmitted)="onSubmitRatingComment()"
>
  <div class="mb-2 font-18 os-semi-bold">Wat vind je dat er beter kan?</div>
  <shared-text-input-area
    rows="2"
    [(ngModel)]="comment"
  ></shared-text-input-area>
</base-modal>
