<!-- DESKTOP MENU -->
<nav
  class="navbar navbar-expand-md navbar-os navbar-dark justify-content font-18 os-semi-bold d-none d-lg-flex"
>
  <a
    class="navbar-brand ms-4"
    routerLink="/home/vakken"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <img
      *ngIf="!isSchool24"
      src="../../../../assets/brand/os/logo/white.svg"
      class="logo m-0 p-0 my-auto"
    />
    <img
      *ngIf="isSchool24"
      src="../../../../assets/brand/s24/logo/white.svg"
      class="logo m-0 p-0 my-auto"
    />
  </a>
  <div
    class="collapse navbar-collapse justify-content-end m-0 p-0 me-2"
    id="navbarNavAltMarkup"
  >
    <a
      *ngIf="isSchool24"
      class="os-link-white px-3"
      routerLink="/home/vakken"
      routerLinkActive="active"
    >
      <span class="os-link-white-text">Vakken</span>
    </a>
    <a
      *ngIf="!isSchool24"
      class="os-link-white px-3"
      routerLink="/home/vakken"
      routerLinkActive="active"
    >
      <span class="os-link-white-text">Examentrainingen</span>
    </a>
    <a
      *ngIf="!isSchool24"
      class="os-link-white px-3"
      routerLink="/examenbundel"
      routerLinkActive="active"
    >
      <span class="os-link-white-text">Examenbundel</span>
    </a>
    <a class="os-link-white px-3" routerLink="/forum" routerLinkActive="active">
      <span class="os-link-white-text">Forum</span>
    </a>
    <a
      *ngIf="user?.isTeacher || user?.isStaff"
      class="os-link-white px-3"
      routerLink="/docentendashboard"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <span class="os-link-white-text">Docentendashboard</span>
    </a>
    <a
      *ngIf="user?.isStudent"
      class="os-link-white px-3"
      routerLink="/scholen/leerlingen"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <span class="os-link-white-text">Mijn school</span>
    </a>
    <a
      *ngIf="user?.isStaff"
      class="os-link-white px-3"
      routerLink="/beheer/vakken"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <span class="os-link-white-text">Beheer</span>
    </a>
    <div class="mx-3 vl-white"></div>
    <div
      *ngIf="showChecklist"
      class="d-none d-md-inline-block ms-2 me-3 cursor-pointer"
      id="checklist-icon"
      (click)="checkListsToggled = !checkListsToggled"
    >
      <i class="fa-solid fa-list-check"></i>
      <span
        *ngIf="nrOpenActions > 0"
        id="navbarNotificationCounter"
        class="badge-notification badge rounded-pill bg-danger"
        alt="Notifications"
        >{{ nrOpenActions }}</span
      >
    </div>
    <div
      *ngIf="!isSchool24 && user?.isTeacher"
      class="os-link-white d-none d-md-flex align-items-center cursor-pointer ms-2 me-3 font-20"
      id="help-icon"
      (click)="helpToggled = !helpToggled"
    >
      <i class="fa-regular fa-circle-question"></i>
    </div>
    <div
      class="os-link-white d-none d-md-flex align-items-center cursor-pointer ms-2 me-3"
      id="user-icon"
      (click)="userInfoToggled = !userInfoToggled"
    >
      <i class="fa-solid fa-user"></i>
    </div>
  </div>
</nav>

<checklists
  *ngIf="checkListsToggled"
  [userExtended]="user?.user_extended"
  (newData)="onNewCheckListData($event)"
  (clickOutside)="onOutsideChecklistClicked($event)"
  (close)="onCloseClicked()"
></checklists>

<navbar-user
  *ngIf="userInfoToggled"
  (clickOutside)="onOutsideUserInfoClicked($event)"
>
</navbar-user>

<navbar-help
  *ngIf="helpToggled"
  [userExtended]="user?.user_extended"
  (clickOutside)="onOutsideHelpClicked($event)"
>
</navbar-help>

<!-- MOBILE MENU -->
<nav class="d-flex d-lg-none navbar navbar-dark fixed-top">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">
      <img
        [src]="
          isSchool24
            ? '../../../../assets/brand/s24/logo/white.svg'
            : '../../../../assets/brand/os/logo/white.svg'
        "
        class="logo m-0 p-0 my-auto"
    /></a>

    <div>
      <div
        *ngIf="showChecklist"
        class="d-inline-block me-3 cursor-pointer"
        id="checklist-icon-mobile"
        (click)="checkListsToggled = !checkListsToggled"
      >
        <i class="fa-solid fa-list-check"></i>
        <span
          *ngIf="nrOpenActions > 0"
          id="navbarNotificationCounter"
          class="badge-notification badge rounded-pill bg-danger"
          alt="Notifications"
          >{{ nrOpenActions }}</span
        >
      </div>
      <button
        class="d-inline-block navbar-toggler"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasNavbar"
        aria-controls="offcanvasNavbar"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
    <div
      class="offcanvas offcanvas-end px-2"
      tabindex="-1"
      id="offcanvasNavbar"
      aria-labelledby="offcanvasNavbarLabel"
    >
      <div class="offcanvas-header">
        <div class="d-flex align-items-center">
          <img
            [src]="
              isSchool24
                ? '../../../../assets/brand/s24/logo/favicon.svg'
                : '../../../../assets/brand/os/logo/favicon.svg'
            "
            class="me-3"
          />
          <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
            {{ isSchool24 ? "School24" : "OnlineSlagen" }}
          </h5>
        </div>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">
        <ul class="navbar-nav justify-content-end flex-grow-1">
          <li
            *ngFor="let item of mainMenuItems"
            class="course-menu-item p-2 mb-2 d-flex gap-1 align-item-center"
            [ngClass]="currentUrl === item.url ? 'active' : ''"
            (click)="onItemClicked(item)"
          >
            <i
              class="me-2 align-fa-icon {{ item.faIcon }} {{
                currentUrl === item.url ? 'os-text-white' : 'fa-primary-color'
              }}"
            ></i>
            <span class="d-md-inline">{{ item.title }}</span>
          </li>
        </ul>
        <ul
          *ngIf="user?.isStaff"
          class="navbar-nav justify-content-end flex-grow-1"
        >
          <li
            *ngFor="let item of adminMenuItems"
            class="course-menu-item p-2 mb-2 d-flex gap-1 align-item-center"
            [ngClass]="currentUrl === item.url ? 'active' : ''"
            (click)="onItemClicked(item)"
          >
            <i
              class="me-2 align-fa-icon {{ item.faIcon }} {{
                currentUrl === item.url ? 'os-text-white' : 'fa-primary-color'
              }}"
            ></i>
            <span class="d-md-inline">{{ item.title }}</span>
          </li>
        </ul>
        <ul class="navbar-nav justify-content-end flex-grow-1">
          <li
            class="course-menu-item p-2 mb-2 d-flex gap-1 align-item-center"
            (click)="onMyAccountClicked()"
          >
            <i
              class="me-2 align-fa-icon fa-primary-color fa fa-user font-20"
            ></i>
            <span class="d-md-inline">Mijn account</span>
          </li>
          <li
            class="course-menu-item p-2 d-flex gap-1 align-item-center"
            (click)="logout()"
          >
            <i
              class="fa-primary-color fa-solid fa-right-from-bracket font-20 me-2"
            ></i>
            <span class="d-md-inline">Uitloggen</span>
          </li>
        </ul>
        <div
          class="px-3 os-text-dark"
          style="word-break: break-word; font-size: 14px"
        >
          <p class="mb-1">
            <strong>Ingelogd als</strong>
          </p>
          <a
            class="d-block text-decoration-none"
            routerLink="/gebruiker/{{ user?.id }}/profiel"
          >
            <shared-avatar
              class="mb-1"
              size="medium-large"
              [avatarUrl]="user?.avatar?.url"
              [userName]="user?.first_name"
            ></shared-avatar>
          </a>
          <p class="mb-1">
            {{ user?.first_name }}
            {{ user?.last_name }}
          </p>
          <p class="mb-1">
            {{ user?.email }}
          </p>
        </div>
      </div>
    </div>
  </div>
</nav>
