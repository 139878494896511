import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output, ViewChild
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { AbstractControl, FormGroup, FormsModule, ValidationErrors } from "@angular/forms";
import { getValueAccessorProvider } from "../value-accessors/value-accessor-provider";
import { BaseControlValueAccessorComponent } from "../value-accessors/base-control-value-accessor";
import { ValidationService, ValidationErrorKeys } from "@app/shared/services/validation";

type LabelSize = 'medium' | 'large' | 'extra-large';

@Component({
  standalone: true,
  selector: "shared-text-input-area",
  templateUrl: "./text-input-area.component.html",
  styleUrls: ["./text-input-area.component.scss"],
  providers: [getValueAccessorProvider(TextInputAreaComponent)],
  imports: [CommonModule, FormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextInputAreaComponent extends BaseControlValueAccessorComponent<string> implements OnInit {
  @Input('text') value!: string;
  @Input() placeholder!: string;
  @Input() rows: number = 1;
  @Input() fontSize: "regular" | "small" = "regular";
  @Input() label?: string;
  @Input() labelSize: LabelSize = 'medium';
  @Input() required = false;
  @Input() formControlName?: string;
  @Input() formGroup?: FormGroup;

  @Output() textEnter: EventEmitter<string> = new EventEmitter();

  @ViewChild('input') input!: ElementRef<HTMLElement>;

  constructor(private validationService: ValidationService, cdr: ChangeDetectorRef) {
    super(cdr);
  }

  get isControlInvalid(): boolean {
    return !!this.control && !!this.control.errors && this.control.touched;
  }

  get control(): AbstractControl | null {
    return (this.formControlName && this.formGroup) ? this.formGroup.get(this.formControlName) : null;
  }

  ngOnInit(): void {
    if (this.value === undefined) {
      this.value = "";
    }

    if (
      (this.value === "" && this.placeholder === undefined) ||
      this.placeholder === ""
    ) {
      this.placeholder = "Typ hier..";
    }
  }

  public focus() {    
    this.input.nativeElement.focus();
  }

  protected onTextChange(text: string): void {
    this.textEnter.emit(text);
    this.onValueChange(text);
  }

  protected getErrorMessage(errors: ValidationErrors): string {
    const error = Object.keys(errors)[0];
    const maxLengthErrorAdditionalInfo = errors[ValidationErrorKeys.MAX_LENGTH]?.requiredLength;

    return this.validationService.getErrorMessage(error, maxLengthErrorAdditionalInfo);
  }
}
