import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UtilService } from "@app/shared/services/util.service";
import { environment } from "@env/environment";
import { Observable } from "rxjs";
import { Thread } from "./models/Thread";
import { UserThread } from "./models/UserThread";

@Injectable({
  providedIn: "root",
})
export class InboxService {
  userThreadsUrl: string = environment.apiEndpoint + "/comms/user-threads/";
  threadsUrl: string = environment.apiEndpoint + "/comms/threads/";

  constructor(private http: HttpClient, private utilService: UtilService) {}

  getUserThreads(params?: {
    participants?: number;
    discussed_course_assignment_id?: number;
  }): Observable<UserThread[]> {
    return this.http.get<UserThread[]>(
      this.userThreadsUrl +
        "?" +
        this.utilService.dictionaryToQueryParams(params)
    );
  }

  updateUserThread(
    userThreadId: number,
    body: {
      has_unread_messages?: boolean;
      has_deleted_thread?: boolean;
    }
  ): Observable<UserThread> {
    return this.http.patch<UserThread>(
      this.userThreadsUrl + userThreadId,
      body
    );
  }

  createThread(body: Thread): Observable<Thread> {
    return this.http.post<Thread>(this.threadsUrl, body);
  }

  addUserToUserThread(body: {
    thread: number;
    user: number;
  }): Observable<UserThread> {
    return this.http.post<UserThread>(this.userThreadsUrl, body);
  }

  deleteThread(thread: Thread) {
    return this.http.delete<any>(this.threadsUrl + thread.id);
  }
}
