import {
  AfterViewInit,
  Directive, ElementRef,
  EventEmitter,
  Output,
} from "@angular/core";

@Directive({
  standalone: true,
  selector: "[elementInView]",
})
export class ElementInViewDirective implements AfterViewInit {
  @Output() elementInView = new EventEmitter<void>();

  private observer!: IntersectionObserver;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    const options = {
      threshold: 0.5
    };

    this.observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.elementInView.emit();
        }
      })
    }, options);
    this.observer.observe(this.elementRef.nativeElement);
  }
}
