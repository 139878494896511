<video
  #videoPlayer
  playsinline
  controls
  crossorigin
  [poster]="video.thumbnail_url | safe"
  (play)="onVideoStarted()"
  (pause)="onVideoPaused()"
  (seeking)="onVideoSeeking()"
  (ended)="onVideoEnded()"
  (loadedmetadata)="onMetadata()"
  (timeupdate)="onTimeUpdate()"
>
  <source
    [src]="video.url | safe"
    type="video/mp4"
    (error)="this.isError = true"
  />

  <track
    *ngIf="video.subtitles_url"
    kind="captions"
    [src]="video.subtitles_url"
    srclang="nl"
    label="Nederlands"
    default
  />
</video>
