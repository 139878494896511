import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  standalone: true,
  name: "randomize",
})
export class RandomizeArrayOrderPipe implements PipeTransform {
  transform(array: any[]): any[] {
    if (!array) return array;

    // Copy the original array to avoid modifying it directly
    const arrayCopy = [...array];

    // Randomize the order using the Fisher-Yates algorithm
    for (let i = arrayCopy.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arrayCopy[i], arrayCopy[j]] = [arrayCopy[j], arrayCopy[i]];
    }

    return arrayCopy;
  }
}
