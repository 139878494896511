<lib-ngx-image-zoom
  class="d-none d-xl-block"
  [thumbImage]="myThumbnail"
  [fullImage]="myFullresImage"
  [magnification]="1.7"
  [enableScrollZoom]="true"
  [maxZoomRatio]="4"
  [scrollStepSize]="0.4"
  [enableLens]="true"
  [zoomMode]="'hover'"
  [lensWidth]="180"
  [lensHeight]="180"
></lib-ngx-image-zoom>

<lib-ngx-image-zoom
  class="d-block d-xl-none"
  [thumbImage]="myThumbnail"
  [fullImage]="myFullresImage"
></lib-ngx-image-zoom>
