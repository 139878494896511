import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
} from "@angular/core";
import { BaseControlValueAccessorComponent } from "../value-accessors/base-control-value-accessor";
import { getValueAccessorProvider } from "../value-accessors/value-accessor-provider";
import { SecondsToMMMSSPipe } from "@app/shared/pipes";
import { AbstractControl, FormGroup, FormsModule } from "@angular/forms";
import { NgxMaskDirective, provideNgxMask } from "ngx-mask";
import { NgIf } from "@angular/common";
import { InputErrorComponent } from "../input-error/input-error.component";

@Component({
  standalone: true,
  selector: "minutes-input",
  templateUrl: "./minutes-input.component.html",
  styleUrls: ["./minutes-input.component.scss"],
  providers: [
    getValueAccessorProvider(MinutesInputComponent),
    SecondsToMMMSSPipe,
    provideNgxMask(),
  ],
  imports: [
    SecondsToMMMSSPipe,
    FormsModule,
    NgxMaskDirective,
    NgIf,
    InputErrorComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MinutesInputComponent extends BaseControlValueAccessorComponent<number> {
  @Output() enterPress: EventEmitter<void> = new EventEmitter();

  @Input() formControlName?: string;
  @Input() formGroup?: FormGroup;

  protected displayedValue!: string;

  protected readonly formatPipe = inject(SecondsToMMMSSPipe);

  get isControlInvalid(): boolean {
    return !!this.control && !!this.control.errors && this.control.touched;
  }

  get control(): AbstractControl | null {
    return (this.formControlName && this.formGroup) ? this.formGroup.get(this.formControlName) : null;
  }

  override writeValue(value: number): void {
    this.displayedValue = this.formatPipe.transform(value, false);
    super.writeValue(value);
  }

  protected onDisplayedValueChange(value: string): void {
    const numberValue = this.formatMMSSToSeconds(value);
    super.onValueChange(numberValue);
  }

  private formatMMSSToSeconds(time: string): number {
    if (!time) return 0;

    time = time.padStart(4, "0");
    time = `${time.slice(0, 2)}:${time.slice(2, 4)}`;

    const [minutes, seconds] = time.split(":").map(Number);

    return minutes * 60 + seconds;
  }

  protected onKeyUp(event: KeyboardEvent) {
    if (event.key === "Enter") {
      this.enterPress.emit();
    }
  }
}
