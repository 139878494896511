import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
  standalone: true,
  selector: "shared-info-block",
  templateUrl: "./info-block.component.html",
  styleUrls: ["./info-block.component.scss"],
  imports: [CommonModule]
})
export class InfoBlockComponent implements OnInit {
  @Input() title!: string;
  @Input() message!: string;
  @Input() enableHide: boolean = true;

  @Output() dismiss: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onCrossClicked() {
    this.dismiss.emit();
  }
}
