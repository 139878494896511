<label
  *ngIf="label"
  class="mb-1 os-semi-bold font-16"
  [class.required]="required"
  [ngClass]="{
    required: required,
    'font-16': labelSize === 'medium',
    'font-18': labelSize === 'large',
    'font-20': labelSize === 'extra-large'
  }"
>
  {{ label }}
</label>
<textarea
  #input
  class="os-jost"
  [ngClass]="{
    'form-control': formControlName,
    'is-invalid': isControlInvalid
  }"
  type="text"
  [ngModel]="value"
  (ngModelChange)="onTextChange($event)"
  [placeholder]="placeholder"
  [rows]="rows"
></textarea>
<div *ngIf="isControlInvalid" class="text-danger font-14 os-semi-bold">
  {{ this.getErrorMessage(control.errors) }}
</div>
