<div class="info-label mb-2">
  <div class="d-flex gap-2 align-items-center">
    <span class="font-14 text-secondary os-semi-bold" [class.required]="required">{{ label }}</span>
    <i
      *ngIf="infoTooltip"
      class="fa fa-question-circle fa-primary-color cursor-pointer"
      placement="top"
      [ngbTooltip]="infoTooltip"
    ></i>
  </div>
  <ng-content></ng-content>
</div>
