import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { OnboardingVideo } from "@app/models/onboarding/OnboardingVideo";
import { OnboardingService } from "@app/shared/services/onboarding.service";
import { UserService } from "@app/shared/services/user.service";
import { Video } from "@app/training/shared/models/Video";
import { VideoStatus } from "@app/training/shared/models/VideoStatus";
import { UserExtended } from "@app/user/models/UserExtended";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonModule } from "@angular/common";
import { ButtonComponent, VideoPlayerComponent } from "@app/shared/basic-components";
import { UserOnboardingVideo } from "@app/models/onboarding/UserOnboardingVideo";

@Component({
  standalone: true,
  selector: "onboarding-video-modal",
  templateUrl: "./onboarding-video-modal.component.html",
  styleUrls: ["./onboarding-video-modal.component.scss"],
  imports: [CommonModule, VideoPlayerComponent, ButtonComponent]
})
export class OnboardingVideoComponent implements OnInit {
  @Input() userExtended!: UserExtended;
  @Input() onboardingVideo!: OnboardingVideo;

  protected onboardingVideos!: OnboardingVideo[];
  protected currentOnboardingVideo!: OnboardingVideo;
  protected video?: Video;

  constructor(
    private activeModal: NgbActiveModal,
    private userService: UserService,
    private onboardingService: OnboardingService,
    private route: ActivatedRoute,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.getOnboardingVideos();
  }

  private getOnboardingVideos() {
    this.onboardingService
      .getOnboardingVideos({ user_role: this.userExtended.role })
      .subscribe((videos) => {
        this.onboardingVideos = videos;
        if (this.onboardingVideo) {
          this.onOnboardingVideoClicked(this.onboardingVideo);
        } else {
          let tag = this.route.snapshot.queryParams["video_tag"];
          if (tag) {
            this.onboardingVideo = this.onboardingVideos.find(
              (video) => video.tag === tag
            ) as OnboardingVideo;
          }
          if (this.onboardingVideo) {
            this.onOnboardingVideoClicked(this.onboardingVideo);
          } else {
            this.onOnboardingVideoClicked(this.onboardingVideos[0]);
          }
        }
      });
  }

  protected onOnboardingVideoClicked(onboardingVideo: OnboardingVideo) {
    this.currentOnboardingVideo = onboardingVideo;
    this.video = undefined;
    setTimeout(() => {
      this.createVideo();
      this.cdr.markForCheck();
    }, 50);
  }

  private createVideo() {
    this.video = {
      id: this.currentOnboardingVideo.id,
      url: this.currentOnboardingVideo.url,
      subtitles_url: this.currentOnboardingVideo.subtitles_url,
      thumbnail_url: this.currentOnboardingVideo.thumbnail_url,
    };
  }

  protected onVideoStatusChanged(videoStatus: VideoStatus) {
    var body: Partial<UserOnboardingVideo> = {};
    if (videoStatus.status === "finished") {
      body["finished_at"] = new Date();
    }
    body["seconds_watched"] = +videoStatus.secondsWatched.toFixed(0);
    body["onboarding_video"] = this.currentOnboardingVideo.id;
    this.onboardingService.createOnboardingUserVideo(body).subscribe({});
  }

  protected onTimeChanged() {}

  protected onDisableVideosClicked() {
    this.userExtended.show_onboarding_videos = false;
    this.userService.updateUserExtended(this.userExtended).subscribe({});
    this.closeModal();
  }

  protected closeModal() {
    this.activeModal.close();
  }
}
