import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { Router } from "@angular/router";
import { TableCellType } from "@app/shared/enums/table-cell-types-enum";
import { TableCell } from "@app/shared/models/TableCell";
import { TableColumn } from "@app/shared/models/TableColumn";
import { TableHeader } from "@app/shared/models/TableHeader";
import { TableRow } from "@app/shared/models/TableRow";
import { TableHeaderComponent } from "./components/header/header.component";
import { TableCellComponent } from "./components/cell/cell.component";
import { CommonModule } from "@angular/common";

@Component({
  standalone: true,
  selector: "shared-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
  imports: [
    CommonModule,
    TableHeaderComponent,
    TableCellComponent
  ]
})
export class TableComponent implements OnInit {
  @Input() headers!: TableHeader[];
  @Input() set rows(rows: TableRow[]) {
    this._rows = rows;
    this.sortCells();
    if (!this.tableHasOnlyText()) {
      this.setColumns();
    }
  }
  @Input() noDataMessage: string = "Geen data gevonden.";
  @Input() footerTitle: string = "Gemiddeld";
  @Input() sortOrder: "ascending" | "descending" = "ascending";

  @Output() cellClicked = new EventEmitter<{
    cell: TableCell;
    row: number;
    column: number;
  }>();
  @Output() deleteClicked = new EventEmitter<TableRow>();

  TableCellType = TableCellType;

  _rows!: TableRow[];
  columns!: TableColumn[];

  sortedColumn: number = 0;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.sortCells();
    if (!this.tableHasOnlyText()) {
      this.setColumns();
    }
  }

  setColumns() {
    this.columns = this.headers.slice(1).map((header, index) => {
      return new TableColumn(
        header,
        this._rows.map((row) => row.cells[index])
      );
    });
  }

  tableHasOnlyText() {
    return this.headers.every((header) => header.type === TableCellType.Text);
  }

  onColumnClicked(index: number) {
    if (this.sortedColumn === index) {
      this.sortOrder =
        this.sortOrder === "ascending" ? "descending" : "ascending";
    } else {
      this.sortedColumn = index;
      this.sortOrder = "ascending";
    }
    this.sortCells();
  }

  sortCells() {
    this._rows.sort((a, b) => {
      let aValue, bValue;
      if (this.sortedColumn === 0) {
        aValue = a.title;
        bValue = b.title;
      } else {
        aValue = a.cells[this.sortedColumn - 1].value;
        bValue = b.cells[this.sortedColumn - 1].value;
      }
      if (this.compare(aValue, bValue)) {
        return this.sortOrder === "ascending" ? 1 : -1;
      } else if (!this.compare(aValue, bValue)) {
        return this.sortOrder === "ascending" ? -1 : 1;
      } else {
        return 0;
      }
    });
  }

  compare(a: any, b: any) {
    if (a === undefined) return false;
    if (b === undefined) return true;
    return a > b;
  }

  onMiddleClick(event: MouseEvent, url: string): void {
    if (!url) return;

    if (event.button === 1) {
      window.open(url, "_blank");
    }
  }

  onLinkClicked(event: MouseEvent, url: string) {
    if (url) {
      if (event.ctrlKey) {
        window.open(url, "_blank");
      } else {
        this.router.navigateByUrl(url);
      }
    }
  }

  onCellClicked(cell: TableCell, row: number, column: number) {
    if (cell.is_clickable) {
      this.cellClicked.emit({ cell, row, column });
    }
  }

  onDeleteClicked(row: TableRow) {
    this.deleteClicked.emit(row);
  }
}
