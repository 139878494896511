import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ClickOutsideDirective } from "@app/shared/directives/click-outside.directive";

@Component({
  standalone: true,
  selector: "shared-date-selector",
  templateUrl: "./date-selector.component.html",
  styleUrls: ["./date-selector.component.scss"],
  imports: [CommonModule, ClickOutsideDirective]
})
export class DateSelectorComponent implements OnInit {
  @Input() label!: string;
  @Input() defaultDate!: Date;

  @Output() dateSelected: EventEmitter<Date> = new EventEmitter();

  selectedDate!: Date;

  selectYearToggled: boolean = false;
  selectMonthToggled: boolean = false;
  selectDayToggled: boolean = false;

  yearOptions!: number[];

  monthOptions: any[] = [
    { index: 0, name: "Januari" },
    { index: 1, name: "Februari" },
    { index: 2, name: "Maart" },
    { index: 3, name: "April" },
    { index: 4, name: "Mei" },
    { index: 5, name: "Juni" },
    { index: 6, name: "Juli" },
    { index: 7, name: "Augustus" },
    { index: 8, name: "September" },
    { index: 9, name: "Oktober" },
    { index: 10, name: "November" },
    { index: 11, name: "December" },
  ];

  constructor() {}

  ngOnInit(): void {
    this.selectedDate = this.defaultDate;
    this.yearOptions = [
      this.selectedDate.getFullYear() - 4,
      this.selectedDate.getFullYear() - 3,
      this.selectedDate.getFullYear() - 2,
      this.selectedDate.getFullYear() - 1,
      this.selectedDate.getFullYear(),
      this.selectedDate.getFullYear() + 1,
      this.selectedDate.getFullYear() + 2,
    ];
  }

  onDaySelected(day: any) {
    this.selectedDate.setDate(day);
    this.dateSelected.emit(this.selectedDate);
  }

  onMonthSelected(month: any) {
    this.selectedDate.setMonth(month.index);
    this.dateSelected.emit(this.selectedDate);
  }

  onYearSelected(year: any) {
    this.selectedDate.setFullYear(year);
    this.dateSelected.emit(this.selectedDate);
  }

  getDayOptions() {
    let amountOfDays = this.setAmountOfDays();
    let dayOptions = Array.from({ length: amountOfDays ?? 0 }, (v, k) => k + 1);
    return dayOptions;
  }

  setAmountOfDays() {
    const monthNr = this.selectedDate.getMonth();

    if (
      monthNr === 0 ||
      monthNr === 2 ||
      monthNr === 4 ||
      monthNr === 6 ||
      monthNr === 7 ||
      monthNr === 9 ||
      monthNr === 11
    ) {
      return 31;
    } else if (
      monthNr === 3 ||
      monthNr === 5 ||
      monthNr === 8 ||
      monthNr === 10
    ) {
      return 30;
    } else if (monthNr === 1) {
      return this.ifLeapYear() ? 29 : 28;
    }
  }

  ifLeapYear() {
    let ifLeapYear;
    if (this.selectedDate.getFullYear() % 4 === 0) {
      ifLeapYear = true;
      if (this.selectedDate.getFullYear() % 100 === 0) {
        ifLeapYear = false;
        if (this.selectedDate.getFullYear() % 400 === 0) {
          ifLeapYear = true;
        }
      }
    } else {
      ifLeapYear = false;
    }
    return ifLeapYear;
  }
}
