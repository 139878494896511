import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";

@Component({
  standalone: true,
  selector: "info-label",
  templateUrl: "./info-label.component.html",
  styleUrls: ["./info-label.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, FormsModule, NgbTooltip]
})
export class InfoLabelComponent {
  @Input() label!: string;
  @Input() required = false;
  @Input() infoTooltip?: string;
}
