import { Component, Input, OnInit } from "@angular/core";

import { Clipboard } from "@angular/cdk/clipboard";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  standalone: true,
  selector: "shared-share-link",
  templateUrl: "./share-link.component.html",
  styleUrls: ["./share-link.component.scss"],
})
export class ShareLinkComponent implements OnInit {
  @Input() url!: string;

  constructor(private clipboard: Clipboard, private snackBar: MatSnackBar) {}

  ngOnInit(): void {}

  onCopyClicked() {
    this.clipboard.copy(this.url);
    this.snackBar.open("Link is gekopieerd", "", { duration: 3000 });
  }
}
