import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
  standalone: true,
  selector: "shared-chip-cloud",
  templateUrl: "./chip-cloud.component.html",
  styleUrls: ["./chip-cloud.component.scss"],
  imports: [CommonModule]
})
export class ChipCloudComponent implements OnInit {
  @Input() chips!: any[];
  @Input() set selectedChips(chips: any) {
    this.setSelectedChips(chips);
  }
  @Input() key!: string;
  @Input() disabled: boolean = false;
  @Input() mode: "single" | "multiple" = "single";

  @Output() chipSelected = new EventEmitter<any>();

  @ContentChild('customChipTemplate') customChipTemplate?: TemplateRef<any>;

  _selectedChips: any[] = [];

  constructor() {}

  ngOnInit(): void {}

  setSelectedChips(chips: any[]) {
    if (chips && chips.length > 0) {
      if (this.key) {
        this._selectedChips = chips.map((c) => c[this.key]);
      } else {
        this._selectedChips = chips;
      }
    } else {
      this._selectedChips = [];
    }
  }

  onChipClicked(chip: string) {
    if (!this.disabled) {
      if (this.mode === "single") {
        this._selectedChips = [chip];
      } else {
        if (this._selectedChips.includes(chip)) {
          this._selectedChips = this._selectedChips.filter((c) => c !== chip);
        } else {
          this._selectedChips = [...this._selectedChips, chip];
        }
      }
      this.chipSelected.emit(chip);
    }
  }
}
