import {
  trigger,
  transition,
  style,
  animate,
  state,
} from "@angular/animations";
import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
  standalone: true,
  selector: "shared-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.scss"],
  imports: [CommonModule],
  animations: [
    trigger("fadeInOut", [
      state("in", style({ opacity: 1 })),
      state("out", style({ opacity: 0 })),
      transition("out => in", animate("300ms ease-in")),
      transition("in => out", animate("300ms ease-out")),
    ]),
  ],
})
export class FAQComponent {
  @Input() question!: string;
  @Input() answer!: string;
  @Input() isToggled: boolean = false;
}
