import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output, Renderer2, TemplateRef,
  ViewChild
} from "@angular/core";
import {
  BaseControlValueAccessorComponent
} from "../value-accessors/base-control-value-accessor";
import { getValueAccessorProvider } from "../value-accessors/value-accessor-provider";
import { CommonModule } from "@angular/common";
import { ClickOutsideDirective } from "@app/shared/directives/click-outside.directive";
import { LoadingSpinnerComponent } from "../../loading/loading-spinner/loading-spinner.component";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import {
  EmployeeNrModalComponent
} from "@app/school/partners/ah-complete-account/components/employee-nr-modal.component";

export type SelectorUnfoldedSize = 'default' | 'small';

export interface SelectorItem<T> {
  title: string;
  value: T;
}

@Component({
  standalone: true,
  selector: "shared-selector",
  templateUrl: "./selector.component.html",
  styleUrls: ["./selector.component.scss"],
  providers: [getValueAccessorProvider(SelectorComponent)],
  imports: [CommonModule, ClickOutsideDirective, LoadingSpinnerComponent]
})
export class SelectorComponent extends BaseControlValueAccessorComponent<string | null> {
  @Input() set list(list: any[]) {
    this._list = list;

    if (this.selectedItem && !this._list.includes(this.selectedItem)) {
      this.onItemSelected(this._list[0]);
    }
  }
  @Input() key!: string;
  @Input() selectedItem?: any;
  @Input() placeHolder = "";
  @Input() label?: string;
  @Input() required = false;
  @Input() size: SelectorUnfoldedSize = "default";
  @Input() isLoading = false;
  @Input() includeAllOption = false;

  @Output() itemSelect: EventEmitter<any> = new EventEmitter();

  showList!: boolean;

  protected _list!: any[];

  private mobilePopupRef?: NgbModalRef;

  @ViewChild('selectItemsContainer') itemsContainer!: ElementRef;
  @ViewChild('mobileDropdownPopupTemplate') mobilePopupTemplate!: TemplateRef<void>;

  constructor(
    cdr: ChangeDetectorRef,
    private readonly renderer: Renderer2,
    private readonly modalService: NgbModal
  ) {
    super(cdr);
  }

  override writeValue(value: string | null) {
    super.writeValue(value);
    this.selectedItem = value;
  }

  override onCancelEdit(): void {
    this.selectedItem = this.valueBeforeEdit;
    super.onCancelEdit();
  }

  protected onItemSelected(item: any) {
    this.selectedItem = item;
    this.itemSelect.emit(this.selectedItem);
    this.onValueChange(item);

    if (this.mobilePopupRef) {
      this.mobilePopupRef.close();
    }
  }

  protected onShowListClicked(isMobile = false) {
    if (this.readonly || !this._list.length) return;

    if (isMobile && this._list.length > 0) {
      this.showMobilePopup();
      return;
    }

    if (this._list.length > 0) {
      this.showList = !this.showList;
    }

    setTimeout(() => {
      this.checkHasScroll();
    })
  }

  private showMobilePopup(): void {
    this.mobilePopupRef = this.modalService.open(this.mobilePopupTemplate);
  }

  private checkHasScroll(): void {
    if (this.itemsContainer) {
      const elem = this.itemsContainer.nativeElement;

      if (elem.scrollHeight > elem.clientHeight) {
        this.renderer.addClass(elem, 'has-scroll');
      }
    }
  }
}
