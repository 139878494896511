<div
  class="p-3 px-xl-4 os-block-3"
  id="{{ blockId }}"
  (click)="toggleIsCollapse()"
  [ngClass]="{
    'cursor-pointer': collapsable
  }"
>
  <div
    *ngIf="title"
    class="flex-between-center"
    [ngClass]="{
      'mb-3': (!isCollapsed && collapsable) || !collapsable
    }"
  >
    <div class="d-none d-xl-block os-bold font-18">
      {{ title }}
    </div>
    <div class="d-block d-xl-none os-bold font-16">
      {{ title }}
    </div>

    <i
      *ngIf="collapsable"
      [ngClass]="isCollapsed ? 'fa fa-chevron-up' : 'fa fa-chevron-down'"
    ></i>
  </div>
  <div
    class="text-from-text-editor"
    [ngClass]="isCollapsed ? 'd-none' : 'd-block'"
    [innerHTML]="text | safeHtml"
  ></div>
</div>
