import { Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
  standalone: true,
  selector: "shared-grade-box-v2",
  templateUrl: "./grade-box-v2.component.html",
  styleUrls: ["./grade-box-v2.component.scss"],
  imports: [CommonModule]
})
export class GradeBoxV2Component {
  @Input() grade!: number;
  @Input() size: string = "regular";
}
