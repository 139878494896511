<div class="dark-background"></div>

<div class="no-background">
  <div
    (clickOutside)="closeWindow(); $event.stopPropagation()"
    class="modal-body mx-2 mx-md-auto col-md-8 col-xl-5 col-xxl-3 p-4 p-xl-5 scrollable"
  >
    <div *ngIf="!showThankYouPage">
      <div class="col-12 px-0 flex-between-center mb-4">
        <shared-sub-title [title]="'Feedback melden'"></shared-sub-title>
        <i
          class="fa-solid fa-xmark os-text-red cursor-pointer"
          (click)="closeWindow()"
        ></i>
      </div>
      <div class="">
        <div class="mb-4">
          <shared-selector
            [list]="bugReportTypes"
            [selectedItem]="selectedType"
            [placeHolder]="'Kies type'"
            (itemSelect)="onTypeSelected($event)"
          ></shared-selector>
          <p class="font-12 os-text-red pt-1">
            {{ noTypeErrorMessage }}
          </p>
        </div>

        <div class="mb-4">
          <shared-text-input-area
            [text]="describedLocation"
            [placeholder]="'Waar?'"
            [rows]="2"
            (textEnter)="onDescribedLocationEntered($event)"
          ></shared-text-input-area>
        </div>

        <div class="mb-4">
          <div class="mb-2">
            <div class="os-semi-bold font-16">Wat wil je melden?</div>
            <small *ngIf="selectedType === ContentBugType.TeachingMethodBug">
              Vergeet niet je lesmethode en editie te noemen!</small
            >
          </div>
          <shared-text-input-area
            [text]="bug"
            [placeholder]="placeHolder"
            [rows]="5"
            [fontSize]="'small'"
            (textEnter)="onBugEntered($event)"
          ></shared-text-input-area>
        </div>
        <shared-button
          class="float-end"
          [text]="'Verstuur'"
          [isDisabled]="
            bug.length === 0 ||
            describedLocation.length === 0 ||
            selectedType === undefined
          "
          [isLoading]="isLoading"
          (click)="onSubmitClicked()"
        ></shared-button>
      </div>
    </div>
    <div *ngIf="showThankYouPage" class="text-center">
      <div
        class="col-12 px-0 d-flex justify-content-end align-items-center mb-4"
      >
        <i
          class="fa-solid fa-xmark os-text-red cursor-pointer"
          (click)="closeWindow()"
        ></i>
      </div>
      <shared-title class="d-block mb-4" [title]="'Bedankt!'"></shared-title>
      <div class="mb-4">
        Je melding is geregistreerd onder #{{ contentBugReport.id }}.
      </div>
      <div class="mb-4">
        We waarderen je feedback enorm! We gaan er zo snel mogelijk mee aan de
        slag en lossen problemen meestal binnen 2 werkdagen op.
      </div>
      <shared-button [text]="'Sluiten'" (click)="closeWindow()"></shared-button>
    </div>
  </div>
</div>
