import { Component, OnInit } from "@angular/core";
import { UserExtended } from "@app/user/models/UserExtended";
import { LogService } from "@app/shared/services/log.service";

import { UserService } from "@app/shared/services/user.service";
import { UtilService } from "@app/shared/services/util.service";
import { Place } from "@app/shared/enums/place-enum";
import { NavbarComponent } from "@app/shared/feature-components/navbar";
import { ChatComponentComponent } from "@app/shared/feature-components/chat";
import { ButtonComponent } from "@app/shared/basic-components";

@Component({
  standalone: true,
  selector: "app-not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.scss"],
  imports: [NavbarComponent, ChatComponentComponent, ButtonComponent]
})
export class NotFoundComponent implements OnInit {
  userExtended!: UserExtended;

  constructor(
    private userService: UserService,
    private logService: LogService,
    private utilService: UtilService
  ) {}

  ngOnInit() {
    this.utilService.setTitle("Pagina niet gevonden!");
    this.logService.startLog(Place.NotFound, "Pagina niet gevonden!");
    this.getUser();
  }

  getUser() {
    this.userService.getUserExtended().subscribe({
      next: (userExtended) => {
        this.userExtended = userExtended;
      },
      error: () => {},
    });
  }

  goToContact() {
    if (UtilService.isSchool24()) {
      window.open("https://www.school24.nl/contact", "_blank");
    } else {
      window.open("https://www.onlineslagen.nl/contact", "_blank");
    }
  }
}
