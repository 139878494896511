import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Attachment } from "@app/shared/models/Attachment";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { ManagementService } from "@app/management/management.service";
import { File as OSFile, FileTypes } from "@app/shared/models/File";
import { AttachmentService } from "@app/shared/services/attachment.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { HttpErrorResponse } from "@angular/common/http";
import { CommonModule } from "@angular/common";
import {
  UploadedFileComponent
} from "./components/uploaded-file/uploaded-file.component";
import { ButtonComponent, InputComponent, SubSubTitleComponent } from "@app/shared/basic-components";
import { DndDirective } from "@app/shared/directives";

@Component({
  standalone: true,
  selector: "shared-upload-attachments-modal",
  templateUrl: "./upload-attachments-modal.component.html",
  styleUrls: ["./upload-attachments-modal.component.scss"],
  imports: [CommonModule, UploadedFileComponent, InputComponent, SubSubTitleComponent, ButtonComponent, DndDirective]
})
export class UploadAttachmentsModal implements OnInit {
  @Input() objectId!: number;
  @Input() contentType!: number;
  @Input() trainingId!: number;
  @Input() attachment!: Attachment;

  @Output() attachmentCreated: EventEmitter<Attachment> = new EventEmitter();

  editAttachmentTitleToggle!: boolean;
  attachmentTitleForm = new FormGroup({
    title: new FormControl("", [
      Validators.required,
      Validators.maxLength(200),
    ]),
  });

  destroyAttachmentOnModalClose!: boolean;

  uploadErrorMessage!: string;

  constructor(
    private activeModal: NgbActiveModal,
    private attachmentService: AttachmentService,
    private managementService: ManagementService
  ) {}

  ngOnInit(): void {
    if (this.attachment == null) {
      this.editAttachmentTitleToggle = true;
      this.destroyAttachmentOnModalClose = true;
    } else {
      this.destroyAttachmentOnModalClose = false;
      this.attachmentTitleForm.get("title")?.setValue(this.attachment.title);
    }
  }

  onSubmitAttachmentTitle() {
    if (this.attachmentTitleForm.valid) {
      this.editAttachmentTitleToggle = false;
      if (this.attachment) {
        this.attachment.title = this.attachmentTitleForm.get("title")?.value as string;
        this.attachmentService
          .updateAttachment(this.attachment)
          .subscribe({ next: (attachment) => {} });
      } else {
        this.createNewAttachment(this.attachmentTitleForm.get("title")?.value as string);
      }
    }
  }

  createNewAttachment(title: string) {
    const newAttachment = {
      title: title,
      object_id: this.objectId,
      content_type: this.contentType,
    };
    this.attachmentService.createAttachment(newAttachment).subscribe({
      next: (attachment) => {
        this.attachment = attachment;
      },
      error: (error: HttpErrorResponse) => {
        console.log(error);
      },
    });
  }

  onFilesDropped(fileList: FileList) {
    this.uploadErrorMessage = "";
    this.processFileList(fileList);
  }

  onFilesAdded(event: any) {
    this.uploadErrorMessage = "";
    this.processFileList(event.target.files);
    (document.getElementById("fileDropRef") as HTMLInputElement).value = "";
  }

  processFileList(fileList: FileList) {
    for (let i = 0; i < fileList.length; i++) {
      const droppedFile = fileList.item(i) as File;

      if (!this.isValidFileType(droppedFile)) {
        this.uploadErrorMessage =
          "Je probeert een ." +
          droppedFile.name.split(".")[1] +
          " te uploaden. Alleen audio (.mp3, .wav en .opus), video (.mp4), afbeeldingen (.png, .jpg) en pdf bestanden zijn toegestaan.";
      } else if (!this.isValidFileNameLength(droppedFile)) {
        this.uploadErrorMessage =
          "Bestandsnaam is te lang. Maximale lengte is 80 tekens.";
      } else {
        const formData: FormData = new FormData();
        formData.append("file", droppedFile, droppedFile.name);
        var newFile = this.createFileObject(
          droppedFile,
          "",
          (this.attachment.files?.length ?? 0) + (i + 1)
        );
        this.managementService.uploadFile(formData, this.trainingId).subscribe({
          next: (file: any) => {
            newFile.url = file.file_url;
            this.createFile(newFile);
          },
          error: () => {},
        });
      }
    }
  }

  createFileObject(file: File, url: string, index: number): OSFile {
    return {
      index: index,
      url: url,
      title: file.name,
      attachment: this.attachment.id as number,
      type: this.getFileType(file),
      is_archived: false,
    };
  }

  createFile(file: OSFile) {
    this.attachmentService.createFile(file).subscribe({
      next: (file) => {
        this.attachment.files?.push(file);
      },
      error: (error: HttpErrorResponse) => {
        console.log(error);
      },
    });
  }

  onCreateAttachmentClicked() {
    this.destroyAttachmentOnModalClose = false;
    this.attachmentCreated.emit(this.attachment);
    this.closeModal();
  }

  isValidFileType(file: File) {
    return this.getFileType(file) != null;
  }

  isValidFileNameLength(file: File) {
    return file.name.length <= 80;
  }

  getFileType(file: File): FileTypes | undefined {
    const fileType = file.type.split("/")[0];
    const extension = file.type.split("/")[1];

    if (fileType === "audio") {
      return "audio";
    } else if (fileType === "video") {
      return "video";
    } else if (fileType === "image") {
      return "image";
    } else if (fileType === "application") {
      if (extension === "pdf") {
        return "pdf";
      } else if (
        extension ===
        "vnd.openxmlformats-officedocument.presentationml.presentation"
      ) {
        return "powerpoint";
      }
    }
    return undefined;
  }

  onFileDeleted(toDeleteFile: OSFile) {
    toDeleteFile.is_archived = true;
    this.attachmentService.updateFile(toDeleteFile).subscribe({
      next: () => {
        this.attachment.files = this.attachment.files?.filter((file) => {
          return toDeleteFile.id !== file.id;
        });
      },
    });
  }

  drop(event: CdkDragDrop<any>) {
    moveItemInArray(
      this.attachment.files ?? [],
      event.previousIndex,
      event.currentIndex
    );
    for (let i = 0; i < (this.attachment.files?.length ?? 0); i++) {
      if (this.attachment.files) {
        this.attachment.files[i].index = i + 1;

        this.attachmentService
          .updateFile(this.attachment.files[i])
          .subscribe({ next: (file) => {} });
      }
    }
  }

  onFileUpdated(updatedFile: OSFile) {
    this.attachment.files?.sort((a, b) => {
      if (a.index > b.index) {
        return 1;
      }

      if (a.index < b.index) {
        return -1;
      }

      return 0;
    });
  }

  closeModal() {
    this.activeModal.close();
  }

  ngOnDestroy() {
    if (this.destroyAttachmentOnModalClose) {
      this.attachment.is_archived = true;
      this.attachmentService
        .updateAttachment(this.attachment)
        .subscribe({ next: () => {} });
    }
  }
}
