<span
  class="os-semi-bold "
  [ngClass]="{
    'grade-box': size === 'regular',
    'grade-box-small': size === 'small',
    'red-box': grade < 5.5,
    'orange-box': grade < 6.5 && grade >= 5.5,
    'green-box': grade >= 6.5,
    'transparent-box': grade === undefined
  }"
>
  <span *ngIf="grade !== undefined">
    {{ grade | number: "1.0-1" }}
  </span>
  <span *ngIf="grade === undefined"> - </span>
</span>
