import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
  standalone: true,
  selector: "error-message",
  templateUrl: "./error-message.component.html",
  styleUrls: ["./error-message.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule]
})
export class ErrorMessageComponent {
  @Input() errorMessage!: string;
}
