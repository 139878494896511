<div class="p-4 p-lg-5 os-block-3 mb-3" *ngIf="items">
  <div class="mb-4">
    <div class="os-h6-arvo">{{ title }}</div>
    <span class="font-12 os-link" (click)="toggleSelectAll()">{{
      selectedItems.length > 0 ? "Alles uitvinken" : "Selecteer alles"
    }}</span>
  </div>
  <div
    *ngFor="let item of items; let i = index"
    (click)="onItemSelected(item)"
    class="mb-2 cursor-pointer d-flex one-line"
  >
    <input
      type="checkbox"
      class="me-3 cursor-pointer align-middle"
      id="item-checkbox-{{ i }}"
      name="checkbox"
      [checked]="selectedItems.indexOf(item) > -1"
    />
    <label class="font-14 align-middle cursor-pointer text-limit"
      >{{ idKey ? item[idKey] : i + 1 }}. {{ item[itemKey] }}</label
    >
  </div>
  <div *ngIf="items.length === 0">
    <span class="font-14 align-middle">Nog geen onderdelen voor lesmethode</span>
  </div>
</div>
