import { ElementRef, Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { PreSignUpUser } from "@app/models/PreSignUpUser";
import { TrainingLevelEnum } from "@app/shared/enums/TrainingLevelEnum";
import { TrainingPhaseEnum } from "@app/shared/enums/training-phase-enum";
import { UserCourseType } from "@app/shared/enums/user-course-type";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ImageModalComponent } from "@app/shared/feature-components/modals";
import { Exam } from "@app/exambundle/models/Exam";
import { ExamType } from "@app/shared/enums/exam-type-enum";
import { ExamStatus } from "@app/shared/enums/exam-status-enum";
import { UserExtended } from "@app/user/models/UserExtended";
import { OSRoles } from "@app/shared/enums/user-type-enum";
import { Training } from "@app/training/shared/models/Training";
import { User } from "@app/models/User";
import { S24Roles } from "@app/shared/enums/s24-roles.enum";
import { Answer } from "@app/training/shared/models/Answer";
import { TrainingTypeEnum } from "@app/shared/enums/training-type-enum";

@Injectable({
  providedIn: "root",
})
export class UtilService {
  // Time in seconds
  MAX_BLUR_TIME = 1800;

  productName!: string;

  constructor(
    private zone: NgZone,
    private router: Router,
    private modalService: NgbModal,
    private titleService: Title
  ) {}

  // initializeBackButton(url) {
  //   document.addEventListener("deviceready", onDeviceReady, false);
  //   var self = this;
  //
  //   function onDeviceReady() {
  //     document.addEventListener("backbutton", backButtonPressed, false);
  //   }
  //
  //   function backButtonPressed(e) {
  //     if ((window as any).fcWidget.isOpen()) {
  //       (window as any).fcWidget.close();
  //     } else {
  //       self.zone.run(() => self.router.navigate([url]));
  //     }
  //   }
  // }

  getMobileOperatingSystem() {
    var userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
      return 1;
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 2;
    }

    return 0;
  }

  checkIfCordova() {
    if (window.hasOwnProperty("cordova")) {
      return 1;
    } else {
      return 0;
    }
  }

  isDesktop() {
    return window.innerWidth >= 1200;
  }

  // // This is an estimation of what weeks are in a month. Not an accurate calculation!
  // getWeeksOfMonths(month) {
  //   switch (month) {
  //     case 1:
  //       return [1, 2, 3, 4, 5];
  //     case 2:
  //       return [6, 7, 8, 9];
  //     case 3:
  //       return [10, 11, 12, 13];
  //     case 4:
  //       return [14, 15, 16, 17, 18];
  //     case 5:
  //       return [19, 20, 21, 22];
  //     case 6:
  //       return [23, 24, 25, 26];
  //     case 7:
  //       return [27, 28, 29, 30, 31];
  //     case 8:
  //       return [32, 33, 34, 35];
  //     case 9:
  //       return [36, 37, 38, 39];
  //     case 10:
  //       return [40, 41, 42, 43, 44];
  //     case 11:
  //       return [45, 46, 47, 48];
  //     case 12:
  //       return [49, 50, 51, 52];
  //     default:
  //       return [];
  //   }
  // }

  sleep = (milliseconds: number) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  isEdgeOrIE() {
    return /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
  }

  // ValidateEmail(mail) {
  //   if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
  //     return true;
  //   }
  //   return false;
  // }

  createPreSignUpUser(
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    inviteCode?: string,
    controlAnswer?: string,
    controlAnswer2?: string
  ): PreSignUpUser {
    return {
      email: email,
      password: password,
      firstName: firstName,
      lastName: lastName,
      inviteCode: inviteCode,
      controlAnswer: controlAnswer,
      controlAnswer2: controlAnswer2,
    };
  }

  dictionaryToQueryParams<T>(parameters?: Record<string, T>) {
    if (parameters) {
      let paramsToString = "";
      for (const [key, value] of Object.entries(parameters)) {
        if (value !== undefined && value !== null) {
          paramsToString === ""
            ? (paramsToString = `${key}=${value}`)
            : (paramsToString = paramsToString.concat(`&${key}=${value}`));
        }
      }
      return paramsToString;
    } else {
      return "";
    }
  }

  shuffleArray<T>(array: T[]) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  getRandomItemsFromArray<T>(array: T[], itemsNumber = 3): T[] {
    if (itemsNumber > array.length) {
      return array;
    }

    const shuffledArray = this.shuffleArray([...array]);

    return shuffledArray.slice(0, itemsNumber);
  }

  setTitle(title: string) {
    this.titleService.setTitle(title + " - " + UtilService.getProductName());
  }

  translateLevelAndYearIntoPhase(level: number, year: number) {
    if (
      ([TrainingLevelEnum["vwo"], TrainingLevelEnum["havo"]].includes(level) &&
        year >= 4) ||
      ([
        TrainingLevelEnum["vmbo gl/tl"],
        TrainingLevelEnum["vmbo kb"],
        TrainingLevelEnum["vmbo bb"],
      ].includes(level) &&
        year >= 3)
    ) {
      return TrainingPhaseEnum.Bovenbouw;
    } else {
      return TrainingPhaseEnum.Onderbouw;
    }
  }

  levelToNumber(level: string): number | undefined {
    switch (level) {
      case "vwo":
        return 0;
      case "havo":
        return 1;
      case "vmbo gl/tl":
        return 2;
      case "vmbo kb":
        return 3;
      case "vmbo bb":
        return 4;
      default:
        return undefined;
    }
  }

  public static LevelToString(level: number): string {
    switch (level) {
      case 0:
        return "vwo";
      case 1:
        return "havo";
      case 2:
        return "vmbo gl/tl";
      case 3:
        return "vmbo kb";
      case 4:
        return "vmbo bb";
      default:
        return "";
    }
  }

  differenceInSeconds(startDate?: Date, endDate?: Date) {
    if (startDate && endDate) {
      return Math.floor(
        Math.abs((endDate.getTime() - startDate.getTime()) / 1000)
      );
    } else {
      return 0;
    }
  }

  public static calculateGrade(achievedPoints: number, totalPoints: number) {
    if (totalPoints > 0) {
      return Number((9 * (achievedPoints / totalPoints) + 1).toFixed(1));
    } else {
      return undefined;
    }
  }

  userCourseToToString(userCourseType: UserCourseType) {
    switch (userCourseType) {
      case UserCourseType.CE:
        return "0";
      case UserCourseType.SE:
        return "1";
      case UserCourseType.CE_AND_SE:
        return "0,1";
      default:
        return undefined;
    }
  }

  public static calculatePercentage(amount: number, total: number) {
    if (amount > 0) {
      return Math.round(Number(((amount / total) * 100).toFixed(1)));
    } else {
      return 0;
    }
  }

  // transpose(mat) {
  //   for (let i = 0; i < mat.length; i++) {
  //     for (let j = i + 1; j < mat[i].length; j++) {
  //       let temp = mat[i][j];
  //       mat[i][j] = mat[j][i];
  //       mat[j][i] = temp;
  //     }
  //   }
  //   return mat;
  // }

  groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
    arr.reduce((groups, item) => {
      (groups[key(item)] ||= []).push(item);
      return groups;
    }, {} as Record<K, T[]>);

  public static groupByArray<T, K extends keyof T | Function>(
    xs: T[],
    key: K
  ): { key: K extends keyof T ? T[K] : unknown; values: T[] }[] {
    return xs.reduce(function (rv, x) {
      let v = key instanceof Function ? key(x) : x[key as keyof T];
      let el = rv.find((r) => r && r.key === v);
      if (el) {
        el.values.push(x);
      } else {
        rv.push({ key: v, values: [x] });
      }
      return rv;
    }, [] as { key: K extends keyof T ? T[K] : unknown; values: T[] }[]);
  }

  isVisible(el: HTMLElement) {
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;

    // Only completely visible elements return true:
    var isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
    // Partially visible elements return true:
    //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
  }

  capitalizeFirstLetter(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  beautifyExampleBlocks() {
    Array.from(
      document.getElementsByClassName("ql-example-assignment-block")
    ).forEach((el) => {
      if (this.isFirstQLElement(el)) {
        if (el.nodeName === "LI") {
          el = el.parentNode as HTMLElement;
        }
        el.outerHTML =
          "<p class='pb-0 mt-2 pe-3 text-end ql-example-assignment-block' style='border-radius: 6px 6px 0px 0px'><i>Voorbeeld</i></p>" +
          el.outerHTML;
      }
      if (this.isLastQLElement(el)) {
        // @ts-ignore
        (el as HTMLElement).style["border-radius"] = "0px 0px 6px 6px";
        (el as HTMLElement).classList.add("pb-4");
      }
    });
  }

  isFirstQLElement(el: ChildNode) {
    if (this.isQLElement(el.previousSibling)) {
      return false;
    } else {
      if (el.nodeName === "LI") {
        return !this.isQLElement(el.parentNode?.previousSibling ?? null);
      } else {
        return true;
      }
    }
  }

  isLastQLElement(el: ChildNode) {
    if (this.isQLElement(el.nextSibling) || this.isLastListElement(el)) {
      return false;
    } else {
      return true;
    }
  }

  isLastListElement(el: ChildNode) {
    return el.nodeName === "LI" && el.nextSibling === null;
  }

  isQLElement(el: ChildNode | null): boolean {
    if (!el) return false;
    if (el.nodeName === "UL" || el.nodeName === "OL") {
      return this.isQLElement(el.childNodes[0]);
    } else {
      return (el as HTMLElement).classList.contains(
        "ql-example-assignment-block"
      );
    }
  }

  getSelectionHtml() {
    var html = "";
    if (typeof window.getSelection != "undefined") {
      var sel = window.getSelection();
      if (sel?.rangeCount) {
        var container = document.createElement("div");
        for (var i = 0, len = sel.rangeCount; i < len; ++i) {
          container.appendChild(sel.getRangeAt(i).cloneContents());
        }
        html = container.innerHTML;
      }
    }
    return html;
  }

  makeImagesClickable(el: HTMLElement) {
    var imageNodes = el.getElementsByTagName("img");
    for (var i = 0; i < imageNodes.length; i++) {
      imageNodes[i].addEventListener("click", this.enlargeImage.bind(this));
    }
  }

  enlargeImage(imgElement: MouseEvent) {
    const imageModalRef = this.modalService.open(ImageModalComponent, {
      windowClass: "image-modal",
    });
    imageModalRef.componentInstance.imageSrc = (
      imgElement.target as HTMLImageElement
    ).src;
  }

  hideElement(element: ElementRef) {
    element.nativeElement.style.top = "0px";
    element.nativeElement.style.left = "0px";
    element.nativeElement.style.display = "none";
  }

  calculateRegularGrade(score: number, length: number, norm: number) {
    return 9 * (score / length) + norm;
  }

  calculateLo(score: number, length: number) {
    return 1 + score * (9 / length) * 2;
  }

  calculateLb(score: number, length: number) {
    return 10 - (length - score) * (9 / length) * 0.5;
  }

  calculateRo(score: number, length: number) {
    return 1 + score * (9 / length) * 0.5;
  }

  calculateRb(score: number, length: number) {
    return 10 - (length - score) * (9 / length) * 2;
  }

  calculateExamGrade(score: number, length: number, norm: number) {
    if (length > 0) {
      if (norm < 1) {
        const arr = [
          this.calculateRegularGrade(score, length, norm),
          this.calculateRo(score, length),
          this.calculateRb(score, length),
        ];
        return Math.max(...arr);
      } else if (norm > 1) {
        const arr = [
          this.calculateRegularGrade(score, length, norm),
          this.calculateLo(score, length),
          this.calculateLb(score, length),
        ];
        return Math.min(...arr);
      } else {
        return this.calculateRegularGrade(score, length, norm);
      }
    }
    return 1;
  }

  getTrainingTitleShort(training: Training) {
    return (
      training.training_name +
      " " +
      TrainingLevelEnum[training.training_level].toUpperCase()
    );
  }

  getTrainingTitle(training: Training) {
    return (
      training.training_name +
      " " +
      TrainingLevelEnum[training.training_level].toUpperCase() +
      " | " +
      training.phase.replace(/^./, training.phase[0].toUpperCase())
    );
  }

  public static getExamTitle(oldExam: Exam): string {
    if (!oldExam || !oldExam?.year) {
      if (UtilService.isSchool24()) {
        return "Toets";
      } else {
        return "Examen per onderdeel";
      }
    } else {
      return (
        "Examen " +
        oldExam.year +
        (oldExam.type === ExamType.Paper
          ? " - Tijdvak "
          : oldExam.type === ExamType.Digital
          ? " - Variant "
          : " - Opgavenset ") +
        oldExam.time_slot
      );
    }
  }

  public static getExamTitleShort(oldExam: Exam): string {
    if (!oldExam || !oldExam?.year) {
      if (UtilService.isSchool24()) {
        return "Toets";
      } else {
        return "Examen per onderdeel";
      }
    } else {
      return oldExam.year + " - " + oldExam.time_slot;
    }
  }

  examStatusToString(status: ExamStatus): string {
    switch (status) {
      case ExamStatus.ToBeReviewed || ExamStatus.ToBeFullyReviewed:
        return "Nakijken";
      case ExamStatus.Finished || ExamStatus.Archived:
        return "Afgerond";
      default:
        return "Bezig";
    }
  }

  isStudent(userExtended: UserExtended): boolean {
    if (UtilService.getProductName() === "OnlineSlagen") {
      return (
        userExtended.role === OSRoles.Student ||
        userExtended.role === OSRoles.DemoStudent ||
        userExtended.role === OSRoles.CustomerStudent ||
        userExtended.role === OSRoles.NoRole
      );
    } else {
      return (
        userExtended.s24_role === S24Roles.StudentS24 ||
        userExtended.s24_role === S24Roles.DemoStudentS24 ||
        userExtended.s24_role === S24Roles.CustomerStudentS24 ||
        userExtended.s24_role === S24Roles.NoRole
      );
    }
  }

  isTeacher(userExtended: UserExtended) {
    return (
      userExtended.role === OSRoles.Teacher ||
      userExtended.role === OSRoles.DemoTeacher ||
      userExtended.role === OSRoles.SchoolAdmin ||
      userExtended.s24_role === S24Roles.Teacher ||
      userExtended.s24_role === S24Roles.DemoTeacher ||
      userExtended.s24_role === S24Roles.SchoolAdmin
    );
  }

  isStaff(user: User) {
    return user.is_staff || user.is_superuser;
  }

  getTrainingIcon(trainingName: string) {
    const trainingIconFolder = "assets/training_icons/";

    switch (trainingName) {
      case "Scheikunde":
      case "NaSk 2":
        return `${trainingIconFolder}chemistry.png`;
      case "Biologie":
        return `${trainingIconFolder}biology.png`;
      case "Frans":
        return `${trainingIconFolder}french.png`;
      case "Engels":
        return `${trainingIconFolder}english.png`;
      case "Nederlands":
        return `${trainingIconFolder}dutch.png`;
      case "Duits":
        return `${trainingIconFolder}german.png`;
      case "Wiskunde A":
      case "Wiskunde B":
      case "Wiskunde C":
      case "Wiskunde":
        return `${trainingIconFolder}mathematics.png`;
      case "Natuurkunde":
      case "NaSk 1":
      case "NaSk":
        return `${trainingIconFolder}physics.png`;
      case "Aardrijkskunde":
        return `${trainingIconFolder}geography.png`;
      case "Economie":
        return `${trainingIconFolder}economy.png`;
      case "Bedrijfseconomie":
        return `${trainingIconFolder}business_economics.png`;
      case "Geschiedenis":
        return `${trainingIconFolder}history.png`;
      case "Maatschappijkunde":
        return `${trainingIconFolder}social_studies.png`;
      default:
        return ""; // Return an empty string or a default icon if needed
    }
  }

  getWeekNumber(date: Date) {
    const d = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
  }

  dayOfYear(date: Date): number {
    const startOfYear = new Date(date.getFullYear(), 0, 0);
    const diff = date.getTime() - startOfYear.getTime();
    const day = Math.floor(diff / (1000 * 60 * 60 * 24));
    return day;
  }

  showDevelopmentFeatures() {
    const showDevelopmentFeatures = true;
    return (
      (window.location.href.includes("localhost") ||
        window.location.href.includes("staging")) &&
      showDevelopmentFeatures
    );
  }

  public static isSchool24() {
    return window.location.href.includes("school24.nl"); // ||
    return true;
    // window.location.href.includes("localhost")
  }

  public static getTrainingType() {
    return UtilService.isSchool24()
      ? TrainingTypeEnum.School24
      : TrainingTypeEnum.OnlineSlagen;
  }

  public static getProductName() {
    if (UtilService.isSchool24()) {
      return "School24";
    } else {
      return "OnlineSlagen";
    }
  }

  // Convert an array of answers into multiple choice or multiple correct format. I.e.:
  // A. Answer 1
  // B. Answer 2
  public static answersToMCString(answers: Answer[]): string {
    let answerString = "<div class='d-flex flex-wrap'>";
    answers.forEach((answer) => {
      answerString +=
        "<div class='os-semi-bold text-from-text-editor os-answer-box me-2 mb-2'>" +
        answer.answer +
        "</div>";
    });
    answerString += "</div>";

    return answerString;
  }
  // Convert an array of answers into fill in format. I.e.:
  // 1. Answer 1
  // 2. Answer 2
  public static answersToFillInString(answers: string[]): string {
    let answerString = "";
    answers.forEach((answer, index) => {
      answerString += `<div class='d-flex'>
        <div class='col-1 os-bold'>${index + 1}.</div>
        <div class='col-11 text-from-text-editor'>${answer}</div>
      </div>`;
    });
    return answerString;
  }

  // Convert an array of answers into correct order format. I.e.:
  // "Answer 1, Answer 2, Answer 3"
  public static getCorrectOrderAnswerString(answers: string[]) {
    let answerString = "<div class='d-flex flex-wrap'>";
    for (let answer of answers) {
      answerString +=
        "<div class='os-semi-bold os-answer-box me-2 mb-2'>" +
        answer.replace(/<\/?[^>]+(>|$)/g, "") +
        "</div>";
    }
    answerString += "</div>";
    return answerString;
  }

  public static escapeString(text: string): string {
    return text.replace(/[.*+?^${}()%|[\]\\]/g, "\\$&"); // $& means the whole matched string
  }

  public static findAllOccurrences(keyword: string, text: string): number[] {
    const escapedKeyword = UtilService.escapeString(keyword);
    const regex = new RegExp(escapedKeyword, "g");
    const positions: number[] = [];

    let match;
    while ((match = regex.exec(text)) !== null) {
      positions.push(match.index);
    }

    return positions;
  }
}
