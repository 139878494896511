import { ChangeDetectorRef, Component, Input, OnDestroy, EventEmitter, Output, OnInit } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Subject } from 'rxjs';
import { CustomErrorMessages } from "@app/shared/services/validation";

@Component({
  template: ''
})
export abstract class BaseControlValueAccessorComponent<Value> implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() value!: Value;
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() readonlyByDefault = false;
  @Input() customErrorMessages?: CustomErrorMessages;

  @Output() valueChange = new EventEmitter<Value>();
  @Output() editConfirmed = new EventEmitter<Value>();

  onChange?: (_: unknown) => void;
  onTouch?: () => void;

  protected valueBeforeEdit!: Value;

  protected destroyed = new Subject();

  constructor(protected readonly cd: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.readonlyByDefault) {
      this.readonly = true;
    }
  }

  ngOnDestroy(): void {
    this.destroyed.next(null);
    this.destroyed.complete();
  }

  onValueChange(value: Value): void {
    this.value = value;
    this.valueChange.emit(value);
    this.cd.markForCheck();

    this.onChange?.(value);
    this.onTouch?.();
  }

  writeValue(value: Value): void {
    this.value = value;
    this.cd.markForCheck();
  }

  registerOnChange(fn: (value: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cd.markForCheck();
  }

  protected onEdit(): void {
    this.valueBeforeEdit = this.value;
    this.readonly = false;
  }

  protected onCancelEdit(): void {
    this.readonly = true;
    this.onValueChange(this.valueBeforeEdit);
  }

  protected onConfirmEdit(): void {
    this.readonly = true;

    if (this.valueBeforeEdit !== this.value) {
      this.editConfirmed.emit(this.value);
    }
  }
}
