import { Observable, of, tap } from "rxjs";

export enum AvatarObjectCategory {
  BODY = "body",
  CLOTHES = "clothes",
  CLOTHES_ICON = "clothes_icon",
  EYE_BROWS = "eye_brows",
  EYES = "eyes",
  FACIAL_HAIR = "facial_hair",
  GLASSES = "glasses",
  HAIR = "hair",
  HAT = "hat",
  MOUTH = "mouth",
  BACKGROUND = "background"
}

export class AvatarObject {
  id!: number;
  index!: number;
  title!: string;
  category!: AvatarObjectCategory;
  svg!: string;
  menu_svg!: string | null;
  is_locked!: boolean;
  unlock_info!: string;
  is_adjustable!: boolean;
  color_options!: string[];
  defaultColor!: string;
  private image?: HTMLImageElement | Map<string, HTMLImageElement>;

  constructor(avatarObject: AvatarObject) {
    Object.assign(this, avatarObject);
  }

  public getImage(color?: string): Observable<HTMLImageElement> {
    const elem = document.createElement('div');
    elem.innerHTML = this.svg as string;

    if (!this.image) {
      return this.is_adjustable
        ? this.createAndCacheColorImage$(elem, color)
        : this.createAndCacheImage$(elem);
    } else if (this.image instanceof Map) {
      return this.getCachedOrCreateColorImage$(elem, color);
    } else {
      return of(this.image);
    }
  }

  private createAndCacheImage$(elem: HTMLElement): Observable<HTMLImageElement> {
    return this.getImageFromHtmlElem$(elem).pipe(tap(image => this.image = image));
  }

  private createAndCacheColorImage$(elem: HTMLElement, color?: string): Observable<HTMLImageElement> {
    this.image = new Map<string, HTMLImageElement>();
    return this.getNewColorImage$(elem, color as string).pipe(
      tap(newImg => (this.image as Map<string, HTMLImageElement>).set(color as string, newImg))
    );
  }

  private getCachedOrCreateColorImage$(elem: HTMLElement, color?: string): Observable<HTMLImageElement> {
    const imageFromMap = (this.image as Map<string, HTMLImageElement>).get(color as string);
    if (imageFromMap) return of(imageFromMap);

    return this.getNewColorImage$(elem, color as string).pipe(
      tap(newImg => (this.image as Map<string, HTMLImageElement>).set(color as string, newImg))
    );
  }

  private getNewColorImage$(parentElem: HTMLElement, color: string): Observable<HTMLImageElement> {
    const collection = parentElem.getElementsByClassName('is_adjustable_element');
    for (let i = 0; i < collection.length; i++) {
      const element = collection[i];
      element.setAttribute("style", `fill: #${color}`)
    }
    return this.getImageFromHtmlElem$(parentElem);
  }

  private getImageFromHtmlElem$(elem: HTMLElement): Observable<HTMLImageElement>  {
    const blob = new Blob([elem.innerHTML], { type: 'image/svg+xml' });

    return this.getLoadImage$(blob);
  }

  private getLoadImage$(blob: Blob): Observable<HTMLImageElement> {
    return new Observable(observer => {
      const url = URL.createObjectURL(blob);
      const image = new Image();
      image.src = url;

      image.onload = () => {
        URL.revokeObjectURL(url);
        observer.next(image);
        observer.complete();
      };

      image.onerror = (err) => {
        observer.error(err);
      };
    });
  }
}
