<div
  class="os-btn-2 ripple"
  [ngClass]="{
    'os-btn-2-disabled': isDisabled,
    'os-btn-2-primary': style === 'primary',
    'os-btn-2-green': style === 'green',
    'os-btn-2-red': style === 'red',
    'os-btn-2-orange': style === 'orange',
    'os-btn-2-secondary': style === 'secondary',
    'os-btn-2-link': style === 'link',
    'os-btn-2-link-blue': style === 'link-blue',
    'os-btn-2-link-red': style === 'link-red',
    'no-padding': padding === 'no-padding',
    'w-100 justify-content-center': fullWidth,
    'size-extra-small': size === 'extra-small',
    'size-small': size === 'small',
    'size-medium': size === 'medium',
    'size-large': size === 'large',
    'border-dashed': borderStyle === 'dashed',
    'border-dotted': borderStyle === 'dotted'
  }"
  (click)="onClick()"
>
  <span
    *ngIf="!isLoading"
    class="d-flex align-items-center justify-content-center"
  >
    {{ text }}
    <img
      *ngIf="!faIcon && icon"
      [src]="iconSrc"
      class="icon"
      [ngClass]="{ 'ms-2': text !== '' && text !== undefined }"
    />
    <i
      *ngIf="faIcon"
      class="{{ faIcon }} font-18"
      [ngClass]="{ 'ms-2': text !== '' && text !== undefined }"
    ></i>
  </span>
  <loading-spinner-small-white
    *ngIf="isLoading && style !== 'secondary'"
  ></loading-spinner-small-white>
  <loading-dots-blue
    *ngIf="isLoading && style === 'secondary'"
  ></loading-dots-blue>
</div>
