import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";

import { FeedbackService } from "@app/feedback/feedback.service";

import { UserFeedbackForm } from "@app/feedback/models/UserFeedbackForm";
import { FeedbackFormQuestion } from "@app/feedback/models/FeedbackFormQuestion";

import { environment } from "@env/environment";
import { GlobalConstants } from "@app/shared/global-constants";
import { CommonModule } from "@angular/common";
import { ButtonComponent } from "@app/shared/basic-components";

@Component({
  standalone: true,
  selector: "feedback-modal",
  templateUrl: "./feedback-modal.component.html",
  styleUrls: ["./feedback-modal.component.scss"],
  imports: [CommonModule, ButtonComponent]
})
export class FeedbackModalComponent implements OnInit {
  trainingId!: number;

  questions!: FeedbackFormQuestion[];
  userFeedbackForm!: UserFeedbackForm;

  staticFilesEndpoint: string = environment.staticFilesEndpoint;

  nrTotalStars: number = 5;
  nrEmptyStars!: number;
  nrFilledStars!: number;

  emptyStarArray!: Array<number>;
  filledStarArray!: Array<number>;

  errorMessage!: string;
  buttonText!: string;
  showFollowUp!: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private feedbackService: FeedbackService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getQuestions();
    this.nrEmptyStars = this.nrTotalStars;
    this.nrFilledStars = 0;

    this.setStarArrays();
    this.updateFeedbackFormShown();
    this.setState("Versturen", "", false);
  }

  getQuestions() {
    this.feedbackService
      .getFeedbackFormQuestions(GlobalConstants.FirstFeedbackFormId)
      .subscribe((questions) => {
        this.questions = questions;
      });
  }

  setStarArrays() {
    this.emptyStarArray = new Array(this.nrEmptyStars);
    this.filledStarArray = new Array(this.nrFilledStars);
  }

  emptyStarClicked(positionOfEmptyFilledStar: number) {
    this.nrFilledStars += positionOfEmptyFilledStar + 1;
    this.nrEmptyStars = this.nrTotalStars - this.nrFilledStars;

    this.setStarArrays();
  }

  filledStarClicked(positionOfSelectedFilledStar: number) {
    this.nrFilledStars = positionOfSelectedFilledStar + 1;
    this.nrEmptyStars = this.nrTotalStars - this.nrFilledStars;

    this.setStarArrays();
  }

  updateFeedbackFormShown() {
    this.feedbackService
      .updateUserFeedbackFormShown(GlobalConstants.FirstFeedbackFormId, true)
      .subscribe((userFeedbackForm) => {
        this.userFeedbackForm = userFeedbackForm;
      });
  }

  sendFeedback() {
    if (this.nrEmptyStars == this.nrTotalStars) {
      this.setState("Versturen", "Selecteer minstens 1 ster", false);
    } else {
      this.setState("Verstuurd", "", true);
    }
  }

  forwardToFeedbackForm() {
    this.feedbackService
      .createUserFeedbackFormAnswer(this.questions[0]["id"], this.nrFilledStars)
      .subscribe(() => {
        this.activeModal.close();
        this.router.navigate(["/feedback/" + this.trainingId + "/"]);
      });
  }

  setState(buttonText: string, errorMessage: string, showFollowUp: boolean) {
    this.buttonText = buttonText;
    this.errorMessage = errorMessage;
    this.showFollowUp = showFollowUp;
  }

  closeModal() {
    this.activeModal.close();
  }
}
