<div class="py-3 br-6  border-bottom">
  <div
    class="flex-between-center cursor-pointer"
    [ngClass]="isToggled ? 'mb-3' : ''"
    (click)="isToggled = !isToggled"
  >
    <div class="os-bold">{{ question }}</div>
    <i
      width="20px"
      [ngClass]="isToggled ? 'fa fa-chevron-down' : 'fa fa-chevron-right'"
    ></i>
  </div>
  <div
    class="answer"
    [@fadeInOut]="isToggled ? 'in' : 'out'" [hidden]="!isToggled"
  >
    <div [innerHTML]="answer"></div>
  </div>
</div>
