import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModule, Location } from "@angular/common";

import { UtilService } from "@app/shared/services/util.service";
import { MenuItem } from "@app/shared/models/MenuItem";
import { ButtonComponent } from "../../buttons/button/button.component";

@Component({
  standalone: true,
  selector: "shared-offcanvas-sidebar",
  templateUrl: "./offcanvas-sidebar.component.html",
  styleUrls: ["./offcanvas-sidebar.component.scss"],
  imports: [CommonModule, ButtonComponent]
})
export class OffcanvasSidebarComponent implements OnInit {
  @Input() title: string = '';
  @Input() id: string = "sharedOffcanvasSidebar";
  @Input() menuItems: MenuItem[][] = [];
  @Input() links: MenuItem[] = [];
  @Input() selectedMenuItem!: MenuItem;

  @Output() menuItemSelect: EventEmitter<MenuItem> = new EventEmitter();
  @Output() linkClick: EventEmitter<MenuItem> = new EventEmitter();

  isSchool24!: boolean;

  constructor(private _location: Location) {}

  ngOnInit() {
    this.setisSchool24();
    this.initializeBackButton();
  }

  initializeBackButton() {
    document.addEventListener("deviceready", onDeviceReady, false);
    var self = this;

    function onDeviceReady() {
      // @ts-ignore
      document.addEventListener("backbutton", backButtonPressed, false);
    }

    function backButtonPressed(e: MouseEvent) {
      e.preventDefault();
      const offcanvasNavbar = document.getElementById("offcanvasNavbar");
      const offcanvasBackdrop =
        document.getElementsByClassName("offcanvas-backdrop");
      if (offcanvasNavbar?.classList.contains("show")) {
        window.history.forward();
        if (offcanvasBackdrop) {
          offcanvasBackdrop[0].classList.toggle("show");
        }
        offcanvasNavbar.classList.toggle("show");
      } else {
        self._location.back();
      }
    }
  }

  setisSchool24() {
    this.isSchool24 = UtilService.isSchool24();
  }

  onMenuItemSelected(menuItem: MenuItem) {
    this.menuItemSelect.emit(menuItem);
  }

  onLinkClicked(menuItem: MenuItem) {
    this.linkClick.emit(menuItem);
  }
}
