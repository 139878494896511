import { Injectable } from "@angular/core";
import { DatePipe } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Observable } from "rxjs";

import { environment } from "@env/environment";
import { UtilService } from "@app/shared/services/util.service";

import { User } from "@app/models/User";
import { UserActivityLog } from "./models/UserActivityLog";
import { SchoolKPIData } from "./models/SchoolKPIData";
import { ContentBugReport } from "@app/feedback/models/ContentBugReport";
import { AdminChangeLog } from "./models/AdminChangeLog";
import { Group } from "@app/school/models/Group";
import { SchoolPrivateData } from "@app/school/models/SchoolPrivateData";

import { Comment } from "./models/Comment";
import { Response } from "./models/Response";
import { ContentCheck } from "./models/ContentCheck";
import { CheckExecution } from "./models/CheckExecution";
import { Assignment } from "@app/training/shared/models/Assignment";
import { SpellingIssue } from "./models/SpellingIssue";
import { Completion } from "@app/management/models/Completion";
import { TeachingMethod } from "@app/training/shared/models/TeachingMethod";

@Injectable({
  providedIn: "root",
})
export class ManagementService {
  managementUrl: string = environment.apiEndpoint + "/management";

  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    private datePipe: DatePipe
  ) {}

  uploadFile(newFile: FormData, trainingId: number): Observable<any> {
    return this.http.post<any>(
      this.managementUrl + "/trainings/" + trainingId + "/files/",
      newFile
    );
  }

  uploadFileAsAStudent(newFile: FormData, trainingId: number): Observable<any> {
    return this.http.post<any>(
      environment.apiEndpoint + "/user/trainings/" + trainingId + "/files",
      newFile
    );
  }

  getTrainingsWithParams(params?: Record<string, string>): Observable<any> {
    if (params) {
      return this.http.get<any>(
        this.managementUrl +
          "/stats?" +
          this.utilService.dictionaryToQueryParams(params)
      );
    } else {
      return this.http.get<any>(
        this.managementUrl + "/stats?production_ready=true"
      );
    }
  }

  getUser(userId: number): Observable<User> {
    return this.http.get<User>(this.managementUrl + "/users/" + userId);
  }

  removeUserProgress(userId: number, removeExams: boolean): Observable<string> {
    return this.http.delete<string>(
      this.managementUrl +
        "/users/" +
        userId +
        "/progress?remove_exams=" +
        removeExams
    );
  }

  getSchool(schoolId: number) {
    return this.http.get<SchoolPrivateData>(
      this.managementUrl + "/schools/" + schoolId
    );
  }

  createStudent(
    schoolId: number,
    student: any,
    sendWelcomeEmail: boolean,
    isDemoSchool: boolean,
    productName: string
  ) {
    return this.http.post<any>(
      this.managementUrl +
        "/schools/" +
        schoolId +
        "/students?send_mail=" +
        sendWelcomeEmail +
        "&mail_type=" +
        productName +
        "&is_demo_school=" +
        isDemoSchool,
      student,
      {
        observe: "response",
      }
    );
  }

  createTeacher(
    schoolId: number,
    teacher: any,
    productName: string,
    isDemoSchool: boolean
  ) {
    return this.http.post<any>(
      this.managementUrl +
        "/schools/" +
        schoolId +
        "/teachers?mail_type=" +
        productName +
        "&is_demo_school=" +
        isDemoSchool,
      teacher,
      {
        observe: "response",
      }
    );
  }

  createStudentGroups(schoolId: number, studentId: number, groups: Group[]) {
    return this.http.post<any>(
      this.managementUrl +
        "/schools/" +
        schoolId +
        "/students/" +
        studentId +
        "/groups",
      groups,
      {
        observe: "response",
      }
    );
  }

  createTeacherGroups(
    schoolId: number,
    userId: number,
    groups: Group[],
    productName: string
  ) {
    return this.http.post<any>(
      this.managementUrl +
        "/schools/" +
        schoolId +
        "/teachers/" +
        userId +
        "/groups?mail_type=" +
        productName,
      groups,
      {
        observe: "response",
      }
    );
  }

  downloadUsers(mode: string = "", fromDate: Date, toDate: Date) {
    var url =
      this.managementUrl +
      "/users?mode=" +
      mode +
      "&from_date=" +
      this.datePipe.transform(fromDate, "yyyy_MM_dd") +
      "&to_date=" +
      this.datePipe.transform(toDate, "yyyy_MM_dd");

    return this.http.get(url, {
      observe: "response",
      responseType: "blob",
    });
  }

  downloadTrainingOverview(trainingId: number) {
    var url = this.managementUrl + "/trainings/" + trainingId + "/overview";

    return this.http.get(url, {
      observe: "response",
      responseType: "blob",
    });
  }

  downloadFeedback(fromDate: Date, toDate: Date) {
    var url =
      this.managementUrl +
      "/feedback?start_date=" +
      this.datePipe.transform(fromDate, "yyyy_MM_dd") +
      "&end_date=" +
      this.datePipe.transform(toDate, "yyyy_MM_dd");

    return this.http.get(url, {
      observe: "response",
      responseType: "blob",
    });
  }

  downloadAhStudents(fromDate: Date, toDate: Date) {
    var url =
      this.managementUrl +
      "/ah-students?from_date=" +
      this.datePipe.transform(fromDate, "yyyy_MM_dd") +
      "&to_date=" +
      this.datePipe.transform(toDate, "yyyy_MM_dd");

    return this.http.get(url, {
      observe: "response",
      responseType: "blob",
    });
  }

  downloadAhNoInlogStudents(fromDate: Date, toDate: Date) {
    var url =
      this.managementUrl +
      "/ah-no-inlog-students?from_date=" +
      this.datePipe.transform(fromDate, "yyyy_MM_dd") +
      "&to_date=" +
      this.datePipe.transform(toDate, "yyyy_MM_dd");

    return this.http.get(url, {
      observe: "response",
      responseType: "blob",
    });
  }

  downloadTrainingTopology(trainingName: string) {
    var url =
      this.managementUrl + "/download-topology?training_name=" + trainingName;

    return this.http.get(url, {
      observe: "response",
      responseType: "blob",
    });
  }

  downloadSchoolProgress(
    schoolId: number,
    params: {
      from_date: string;
      to_date: string;
      download_per_student_data: boolean;
      download_per_training_data: boolean;
    }
  ) {
    var url =
      this.managementUrl +
      "/schools/" +
      schoolId +
      "/stats?" +
      this.utilService.dictionaryToQueryParams(params);

    return this.http.get(url, {
      observe: "response",
      responseType: "blob",
    });
  }

  getSchoolKPIData(params?: { school_year?: string; student_type?: string }) {
    return this.http.get<SchoolKPIData[]>(
      this.managementUrl +
        "/kpi/schools?" +
        this.utilService.dictionaryToQueryParams(params)
    );
  }

  getUserLogs(userId: number, limit: number, offset: number) {
    return this.http.get<UserActivityLog[]>(
      this.managementUrl +
        "/users/" +
        userId +
        "/activity-logs?limit=" +
        limit +
        "&offset=" +
        offset
    );
  }

  getContentReportBugs() {
    return this.http.get<ContentBugReport[]>(
      this.managementUrl + "/content-bug-reports"
    );
  }

  patchContentReportBugs(contentBugReport: ContentBugReport) {
    return this.http.patch<ContentBugReport[]>(
      this.managementUrl + "/content-bug-reports/" + contentBugReport.id,
      contentBugReport
    );
  }

  getCourseChangelog(courseId: number) {
    return this.http.get<AdminChangeLog[]>(
      this.managementUrl + "/courses/" + courseId + "/changelog"
    );
  }

  getComments(courseId: number, params?: { status: number }) {
    return this.http.get<Comment[]>(
      this.managementUrl +
        "/courses/" +
        courseId +
        "/comments?" +
        this.utilService.dictionaryToQueryParams(params)
    );
  }

  getOldExamComments(oldExamId: number, params?: { status: number }) {
    return this.http.get<Comment[]>(
      this.managementUrl +
        "/old-exams/" +
        oldExamId +
        "/comments?" +
        this.utilService.dictionaryToQueryParams(params)
    );
  }

  createComment(data: { text: string; course?: number; old_exam?: number }) {
    return this.http.post<Comment>(this.managementUrl + "/comments", data);
  }

  createResponse(data: { text: string; comment: number }) {
    return this.http.post<Response>(this.managementUrl + "/responses", data);
  }

  updateComment(comment: Comment) {
    return this.http.put<Comment>(
      this.managementUrl + "/comments/" + comment.id,
      comment
    );
  }

  getContentChecks(params?: {
    is_active: boolean;
    content_type_id: number;
    object_id?: number;
  }) {
    return this.http.get<ContentCheck[]>(
      this.managementUrl +
        "/content-checks?" +
        this.utilService.dictionaryToQueryParams(params)
    );
  }

  getTeachingMethodContentChecks(
    teachingMethodId: number,
    params?: { is_active: boolean; content_type: number }
  ) {
    return this.http.get<ContentCheck[]>(
      this.managementUrl +
        "/teaching-method/" +
        teachingMethodId +
        "/content-checks?" +
        this.utilService.dictionaryToQueryParams(params)
    );
  }

  createCheckExecution(data: {
    content_check: number;
    object_id: number;
    status: "approved" | "rejected" | "skipped" | "open";
    comment: string;
  }) {
    return this.http.post<CheckExecution>(
      this.managementUrl + "/check-executions",
      data
    );
  }

  updateCheckExecution(checkExecution: CheckExecution) {
    return this.http.put<CheckExecution>(
      this.managementUrl + "/check-executions/" + checkExecution.id,
      checkExecution
    );
  }

  // The function will take in the prompt and the number of tokens to generate
  // The function will return a promise that will resolve to the response
  promptGPT3(
    prompt: string,
    maxTokens: number,
    apiKey: string
  ): Observable<Completion> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiKey,
      "OpenAI-Organization": "org-T0XFpNhKf8E2ivZVc0LjwECK",
    });

    const body = {
      model: "gpt-3.5-turbo",
      messages: [{ role: "user", content: prompt }],
      // Only add max_tokens to body if it is not null
      ...(maxTokens != null ? { maxTokens: maxTokens } : {}),
    };

    return this.http.post<Completion>(
      "https://api.openai.com/v1/chat/completions",
      body,
      {
        headers: headers,
      }
    );
  }

  getChatGPTApiKey() {
    return this.http.get<string>(this.managementUrl + "/chat-gpt-api-key");
  }

  copyAssignment(
    assignment: Assignment,
    data: { category: number; assignment_id?: number }
  ) {
    data["assignment_id"] = assignment.id;
    return this.http.post<Assignment>(
      this.managementUrl + "/copy-assignment/",
      data
    );
  }

  copyTeachingMethod(
    teachingMethodId: number,
    data: { target_teaching_method_id: number }
  ) {
    return this.http.post<any>(
      this.managementUrl + "/teaching-methods/" + teachingMethodId + "/copy",
      data
    );
  }

  getTrainingContentBugReports(trainingId: number) {
    return this.http.get<ContentBugReport[]>(
      this.managementUrl + "/trainings/" + trainingId + "/content-bug-reports/"
    );
  }

  updateTrainingContentBugReport(
    trainingId: number,
    contentBugReport: ContentBugReport,
    params?: { send_email?: boolean }
  ) {
    return this.http.put<ContentBugReport>(
      this.managementUrl +
        "/trainings/" +
        trainingId +
        "/content-bug-reports/" +
        contentBugReport.id +
        "?" +
        this.utilService.dictionaryToQueryParams(params),
      contentBugReport
    );
  }

  postProcessExamBook(examBookFile: File): Observable<Blob> {
    const formData = new FormData();
    formData.append("exam_book_file", examBookFile);

    return this.http.post<any>(
      this.managementUrl + "/exam-book-post-processing",
      formData,
      {
        responseType: "blob" as "json", // This specifies that the response is a blob (PDF)
      }
    );
  }

  createSampleBook(examBookFile: File): Observable<Blob> {
    const formData = new FormData();
    formData.append("exam_book_file", examBookFile);

    return this.http.post<any>(
      this.managementUrl + "/create-sample-book",
      formData,
      {
        responseType: "blob" as "json", // This specifies that the response is a blob (PDF)
      }
    );
  }

  createSampleBookLanguages(examBookFile: File): Observable<Blob> {
    const formData = new FormData();
    formData.append("exam_book_file", examBookFile);

    return this.http.post<any>(
      this.managementUrl + "/create-sample-book-languages",
      formData,
      {
        responseType: "blob" as "json", // This specifies that the response is a blob (PDF)
      }
    );
  }

  createDemoTeacher(data: {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    school: string;
  }) {
    return this.http.post<any>(this.managementUrl + "/demo-teachers", data);
  }

  generateSummary(courseId: number) {
    return this.http.get<any>(
      this.managementUrl + "/courses/" + courseId + "/generate-summary"
    );
  }

  startSpellCheck(params: {
    course_id?: number;
    old_exam_id?: number;
  }): Observable<SpellingIssue[]> {
    const url =
      this.managementUrl +
      "/spell-check?" +
      this.utilService.dictionaryToQueryParams(params);
    return this.http.post<SpellingIssue[]>(url, {});
  }

  getTrainingSpellingIssues(
    trainingId: number,
    params?: {
      has_course?: boolean;
      has_old_exam?: boolean;
      course_id?: number;
      old_exam_id?: number;
      content_type?: "Theory" | "Assignment";
    }
  ): Observable<SpellingIssue[]> {
    const url =
      this.managementUrl +
      "/trainings/" +
      trainingId +
      "/spelling-issues?" +
      this.utilService.dictionaryToQueryParams(params);
    return this.http.get<SpellingIssue[]>(url);
  }

  updateSpellingIssue(spellingIssue: SpellingIssue): Observable<SpellingIssue> {
    const url =
      this.managementUrl + "/spelling-issues/" + spellingIssue.id.toString();
    return this.http.patch<SpellingIssue>(url, spellingIssue);
  }

  getCourseUsage(courseId: number) {
    return this.http.get<{ title: string; url: string }[]>(
      this.managementUrl + "/courses/" + courseId + "/usage"
    );
  }
}
