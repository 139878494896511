<div class="progress-circle-wrapper mb-1">
  <mat-progress-spinner
    class="os-mat-progress-spinner-small"
    [ngClass]="{
      'os-mat-progress-spinner-success': _currentValue === maxValue
    }"
    [diameter]="40"
    [value]="width"
  ></mat-progress-spinner>
  <div class="progress-info font-16">
    <i class="{{ iconClass }}" [ngbTooltip]="tooltipText"></i>
  </div>
</div>
<div
  *ngIf="showProgressCaption"
  class="flex-center-center font-12 os-semi-bold one-line"
>
  <span
    [ngClass]="{
      'text-secondary': _currentValue < maxValue
    }"
    >{{ _currentValue | number : "1.0-1" }}</span
  >
  <span class="text-secondary" style="padding-left: 4px; padding-right: 4px"
    >|</span
  >
  <span>{{ maxValue | number : "1.0-1" }}</span>
</div>
