<div *ngIf="!isCollapsable" class="d-none d-md-block align-top mb-3 w-100">
  <div class="sidebar-menu-box">
    <ul class="py-4">
      <li
        *ngFor="let menuItem of menuItems; let i = index"
        class="d-flex align-items-center sidebar-menu-item py-2"
        [ngClass]="{
          active: menuItem.id === _selectedMenuItem?.id,
          'mb-3': i < menuItems.length - 1
        }"
        (click)="onMenuItemClicked(menuItem)"
      >
        <img
          *ngIf="!menuItem.faIcon && menuItem.icon"
          [src]="
            menuItem.id === _selectedMenuItem?.id
              ? menuItem.activeIcon
              : menuItem.icon
          "
          style="width: 40px; max-height: 24px"
        />
        <i
          *ngIf="menuItem.faIcon"
          [ngClass]="{
            'os-text-white': menuItem.id === _selectedMenuItem?.id
          }"
          class="font-22 {{ menuItem.faIcon }}"
          style="width: 40px"
        ></i>
        <span>{{ menuItem.title }}</span>
      </li>
    </ul>
    <ng-content></ng-content>
  </div>
</div>

<div *ngIf="isCollapsable" class="d-none d-md-block align-top mb-3">
  <div class="sidebar-menu-box mb-2">
    <ul class="py-2">
      <li
        class="d-flex align-items-center sidebar-menu-item py-2 my-2 flex-between-center"
        [ngClass]="{
          active: _selectedMenuItem,
          'border-bottom': !_selectedMenuItem && toggleMenu
        }"
        (click)="onToggleMenuClicked()"
      >
        <div *ngIf="_selectedMenuItem" class="w-100">
          <img
            *ngIf="!_selectedMenuItem.faIcon && _selectedMenuItem.icon"
            [src]="_selectedMenuItem.icon"
            style="width: 20%; max-height: 24px"
          />
          <i
            *ngIf="_selectedMenuItem.faIcon"
            class="font-22 {{ _selectedMenuItem.faIcon }}"
            style="width: 20%"
          ></i>
          <span class="d-md-inline">{{ _selectedMenuItem?.title }}</span>
        </div>
        <div *ngIf="!_selectedMenuItem" class="w-100">
          <i class="fa-solid fa-plus" style="width: 20%"></i>
          <span class="d-md-inline">{{ placeHolder }}</span>
        </div>
        <i
          style="font-size: 14px"
          [ngClass]="toggleMenu ? 'fa fa-chevron-down' : 'fa fa-chevron-right'"
        ></i>
      </li>
      <div *ngIf="toggleMenu">
        <div *ngFor="let menuItem of menuItems">
          <li
            class="d-flex align-items-center sidebar-menu-item my-3 py-2"
            (click)="onMenuItemClicked(menuItem)"
            *ngIf="menuItem.id !== _selectedMenuItem?.id"
          >
            <img
              *ngIf="!menuItem.faIcon && menuItem.icon"
              [src]="menuItem.icon"
              style="width: 20%; max-height: 24px"
            />
            <i
              *ngIf="menuItem.faIcon"
              class="font-22 {{ menuItem.faIcon }}"
              style="width: 20%"
            ></i>

            <span class="d-md-inline">{{ menuItem.title }}</span>
          </li>
        </div>
      </div>
    </ul>
  </div>
</div>
