import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of, switchMap, tap } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "@env/environment";
import { SnackBarService } from "@app/shared/services/utility-services/snack-bar.service";

export enum XPAction {
  ASSIGNMENT_MADE,
  VIDEO_WATCHED,
  THEORY_READ,
  SUMMARY_WRITTEN,
  CONCEPTS_LEARNED,
  FLASHCARD_PRACTICED,
  THEORY_NOTE_MADE,
  VIDEO_NOTE_MADE,
  FORUM_QUESTION_ASKED,
  FORUM_QUESTION_ANSWERED,
}

export interface XPGain {
  xp: number;
  is_level_up: boolean;
  user: number;
  id: number;
  date: Date;
  action: XPAction;
  current_level: number;
}

export interface XPGainAction {
  id: number;
  key: string;
  name: string;
  xp: number;
}

@Injectable({
  providedIn: "root",
})
export class XPService {
  private readonly apiPath = "gamification/xp-gain";

  private xpAudio: HTMLAudioElement;

  constructor(
    private http: HttpClient,
    private snackBarService: SnackBarService
  ) {
    this.xpAudio = new Audio();
    this.xpAudio.src =
      "https://os-file-storage.ams3.cdn.digitaloceanspaces.com/media/uploads/marketing/xp_gained_sound.mp3";
    this.xpAudio.load();
  }

  public getXpGainActions(): Observable<XPGainAction[]> {
    return this.http.get<XPGainAction[]>(
      `${environment.apiEndpoint}/${this.apiPath}/actions`
    );
  }

  public addXPGainCall<T>(
    action: XPAction,
    obs$: Observable<T>
  ): Observable<T> {
    return obs$.pipe(
      switchMap((resp) => {
        return this.addXPGain(action).pipe(
          tap((xpGain) => {
            xpGain.is_level_up
              ? this.snackBarService.showLevelUpMessage(xpGain.current_level)
              : this.snackBarService.showXPGainedMessage(xpGain.xp);
            this.xpAudio.play();
          }),
          map(() => resp),
          catchError(() => of(resp))
        );
      })
    );
  }

  private addXPGain(action: XPAction): Observable<XPGain> {
    return this.http.post<XPGain>(
      `${environment.apiEndpoint}/${this.apiPath}`,
      { action }
    );
  }
}
