<input
  #input
  class="form-control font-18"
  type="text"
  mask="00:00"
  [class.os-invalid-border]="isControlInvalid"
  [showMaskTyped]="true"
  [ngModel]="displayedValue"
  (ngModelChange)="onDisplayedValueChange($event)"
  (keyup)="onKeyUp($event)"
/>
<shared-input-error [controlName]="formControlName" [controlGroup]="formGroup"></shared-input-error>
