import { UtilService } from "@app/shared/services/util.service";
import { TableCellType } from "../enums/table-cell-types-enum";
import { TableCell } from "./TableCell";
import { TableHeader } from "./TableHeader";

export class TableColumn {
  title!: string;
  type!: TableCellType;
  cells!: TableCell[];

  summary?: TableCell;

  constructor(header: TableHeader, cells: TableCell[]) {
    this.title = header.value;
    this.type = header.type;
    this.cells = cells;
    if (header.summary) {
      this.summary = header.summary;
    } else {
      this.calculateSummary();
    }
  }

  private calculateSummary() {
    const values = this.cells
      .filter((cell) => cell?.value !== undefined)
      .map((cell) => cell.value);
    const maxValues = this.cells
      .filter((cell) => cell?.maxValue !== undefined)
      .map((cell) => cell.maxValue) as number[];

    if (this.type === TableCellType.Grade) {
      const sum = values.reduce((a, b) => Number(a) + Number(b), 0);

      this.summary = {
        type: TableCellType.Grade,
        value: sum ? sum / values.length : undefined,
        maxValue: maxValues[0],
      };
    }

    if (this.type === TableCellType.Points) {
      const sum = values.reduce((a, b) => a + b, 0);

      this.summary = {
        type: TableCellType.Points,
        value: sum ? Math.round((sum / values.length) * 10) / 10 : undefined,
        maxValue: maxValues[0],
      };
    }

    if (this.type === TableCellType.Percentage) {
      const sum = values.reduce((a, b) => a + b, 0);
      const maxSum = maxValues.reduce((a, b) => a + b, 0);
      this.summary = {
        type: TableCellType.Percentage,
        value: sum ? UtilService.calculatePercentage(sum, maxSum) : undefined,
        maxValue: maxSum,
      };
    }

    if (this.type === TableCellType.Text || this.type === TableCellType.Date) {
      this.summary = {
        type: TableCellType.Text,
        value: undefined,
        maxValue: undefined,
      };
    }

    if (this.type === TableCellType.Status) {
      const sum = values.filter((value) => value === 2).length;
      const maxSum = maxValues.length;
      const percentage = UtilService.calculatePercentage(sum, maxSum);
      this.summary = {
        type: TableCellType.Text,
        value: undefined,
        maxValue: undefined,
        text: percentage + "%",
      };
    }

    if (this.type === TableCellType.Number) {
      const sum = values.reduce((a, b) => a + b, 0);
      this.summary = {
        type: TableCellType.Number,
        value: sum ? sum / values.length : undefined,
        maxValue: undefined,
      };
    }
  }
}
