import { Component, Input } from "@angular/core";

@Component({
  standalone: true,
  selector: "shared-small-section-title",
  templateUrl: "./small-section-title.component.html",
  styleUrls: ["./small-section-title.component.scss"],
})
export class SmallSectionTitleComponent {
  @Input() title!: string;
}
