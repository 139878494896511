import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { Router, RouterModule } from "@angular/router";

import { CookieService } from "ngx-cookie-service";

import { UserService } from "@app/shared/services/user.service";
import { OnboardingService } from "@app/shared/services/onboarding.service";
import { UtilService } from "@app/shared/services/util.service";
import { InboxService } from "@app/inbox/inbox.service";

import { UserThread } from "@app/inbox/models/UserThread";
import { UserAction } from "@app/models/onboarding/UserAction";
import { Category } from "@app/models/onboarding/Category";
import { OSRoles } from "@app/shared/enums/user-type-enum";
import { CommonModule } from "@angular/common";
import { ChecklistsComponent } from "@app/shared/feature-components/navbar/components/checklists/checklists.component";
import { UserInfoComponent } from "@app/shared/feature-components/navbar/components/user/user.component";
import { HelpComponent } from "@app/shared/feature-components/navbar/components/help/help.component";
import { ClickOutsideDirective } from "@app/shared/directives/click-outside.directive";
import { MenuItem } from "@app/shared/models/MenuItem";
import { AvatarComponent } from "@app/shared/basic-components";
import { finalize } from "rxjs";
import { User } from "@app/models/User";

interface NavbarMenuItem extends Omit<MenuItem, "key"> {}

@Component({
  standalone: true,
  selector: "shared-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ChecklistsComponent,
    UserInfoComponent,
    HelpComponent,
    RouterModule,
    ClickOutsideDirective,
    AvatarComponent,
  ],
})
export class NavbarComponent implements OnInit {
  UserRole: typeof OSRoles = OSRoles;
  user!: User;
  simplifiedRole!: "demo_leerling" | "leerling" | "demo_docent" | "docent";

  userInfoToggled!: boolean;
  helpToggled!: boolean;
  checkListsToggled!: boolean;

  newMessages: number = 0;
  userThreads!: UserThread[];
  userActions!: UserAction[];
  categories!: Category[];

  nrTotalActions: number = 0;
  nrFinishedActions: number = 0;
  nrOpenActions: number = 0;

  isSchool24!: boolean;
  showChecklist!: boolean;
  currentUrl!: string;

  mainMenuItems!: NavbarMenuItem[];
  adminMenuItems!: NavbarMenuItem[];

  constructor(
    private cookieService: CookieService,
    private router: Router,
    private inboxService: InboxService,
    private userService: UserService,
    private onboardingService: OnboardingService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.currentUrl = this.router.url;
    this.setisSchool24();
    this.getUser();
  }

  setisSchool24() {
    this.isSchool24 = UtilService.isSchool24();
  }

  getUser() {
    this.userService
      .getUser()
      .pipe(finalize(() => this.cdr.markForCheck()))
      .subscribe((user) => {
        this.user = User.Factory(user);
        this.getUserThreads();

        if (
          (this.user.user_extended.role === OSRoles.DemoTeacher ||
            this.user.user_extended.role === OSRoles.Teacher ||
            this.user.user_extended.role === OSRoles.Student ||
            this.user.user_extended.role === OSRoles.CustomerStudent) &&
          !UtilService.isSchool24()
        ) {
          this.showChecklist = true;
          this.getUserActions();
        }
        this.setMenuItems();
      });
  }

  getUserActions() {
    this.onboardingService
      .getUserActions({
        user_role: this.user.user_extended.role,
      })
      .pipe(finalize(() => this.cdr.markForCheck()))
      .subscribe({
        next: (userActions: UserAction[]) => {
          this.userActions = userActions;
          this.userActions.forEach((userAction) => {
            if (userAction.is_new) {
              this.checkListsToggled = true;
            }
          });
          this.nrFinishedActions = this.userActions.length;
          this.getCheckLists();
        },
      });
  }

  getCheckLists() {
    this.onboardingService
      .getCheckLists({
        parent: true,
        user_role: this.user.user_extended.role,
        product: UtilService.getProductName().toLowerCase(),
      })
      .pipe(finalize(() => this.cdr.markForCheck()))
      .subscribe({
        next: (categories) => {
          this.categories = categories;
          if (this.categories.length === 0) {
            this.showChecklist = false;
          } else {
            this.calculateTotalActions(categories);
            setTimeout(() => {
              this.nrOpenActions = this.nrTotalActions - this.nrFinishedActions;
            }, 100);
          }
        },
      });
  }

  calculateTotalActions(categories: Category[]) {
    if (categories?.length > 0) {
      categories.forEach((category) => {
        if (
          category.product === "any" ||
          category.product === UtilService.getProductName().toLowerCase()
        ) {
          this.nrTotalActions += category.actions.length;
          this.calculateTotalActions(category.children);
        }
      });
    }
  }

  onNewCheckListData(data: {
    nrFinishedActions: number;
    nrTotalActions: number;
  }) {
    this.nrFinishedActions = data.nrFinishedActions;
    this.nrTotalActions = data.nrTotalActions;
    this.nrOpenActions = this.nrTotalActions - this.nrFinishedActions;
  }

  setMenuItems() {
    this.mainMenuItems = [
      {
        title: UtilService.isSchool24() ? "Vakken" : "Examentrainingen",
        url: "/home/vakken",
        faIcon: "fa fa-home",
      },
    ];
    if (!UtilService.isSchool24()) {
      this.mainMenuItems.push({
        title: "Examenbundel",
        url: "/examenbundel",
        faIcon: "fas fa-list",
      });
    }
    this.mainMenuItems.push(
      { title: "Forum", url: "/forum", faIcon: "fa fa-comments" },
      {
        title: this.user.isTeacher ? "Docentendashboard" : "Mijn school",
        url: this.user.isTeacher ? "/scholen/leraren" : "/scholen/leerlingen",
        faIcon: this.user.isTeacher ? "fa fa-bar-chart" : "fas fa-school",
      }
    );

    this.mainMenuItems.push({
      title: "Inbox",
      url: "/inbox",
      faIcon: "fa fa-envelope-o",
    });

    this.mainMenuItems.push({
      title: "Vakken instellen",
      url: "/home/account-instellen",
      faIcon: "fa fa-cog",
    });

    this.adminMenuItems = [
      {
        title: "Beheer",
        url: "/beheer/vakken",
        faIcon: "fas fa-cubes",
      },
    ];
  }

  getUserThreads() {
    const params = {
      participants: this.user.id,
      mode: "simple",
    };

    this.inboxService.getUserThreads(params).subscribe((userThreads) => {
      this.userThreads = userThreads;
      this.checkNewMessages();
    });
  }

  checkNewMessages() {
    this.newMessages = 0;

    this.userThreads.forEach((userThread) => {
      if (userThread.has_unread_messages) {
        this.newMessages += 1;
      }
    });
  }

  onOutsideChecklistClicked(event: MouseEvent) {
    const htmlElement = event.target as HTMLElement;
    const checkListIcon = document.getElementById(
      "checklist-icon"
    ) as HTMLElement;

    const checkListIconMobile = document.getElementById(
      "checklist-icon-mobile"
    ) as HTMLElement;

    if (
      !checkListIcon.contains(htmlElement) &&
      !checkListIconMobile?.contains(htmlElement) &&
      !htmlElement.classList.contains("fade")
    ) {
      this.checkListsToggled = false;
    }
  }

  onCloseClicked() {
    this.checkListsToggled = false;
  }

  onOutsideHelpClicked(event: MouseEvent) {
    const helpIcon = document.getElementById("help-icon") as HTMLElement;
    if (!helpIcon.contains(event.target as Node)) {
      this.helpToggled = false;
    }
  }

  onOutsideUserInfoClicked(event: MouseEvent) {
    const checkListIcon = document.getElementById("user-icon") as HTMLElement;
    if (!checkListIcon.contains(event.target as Node)) {
      this.userInfoToggled = false;
    }
  }

  onItemClicked(item: MenuItem) {
    document.getElementsByTagName("body")[0].style.overflow = "auto";
    item.url && this.router.navigateByUrl(item.url);
  }

  onMyAccountClicked() {
    if (UtilService.isSchool24()) {
      this.router.navigate(["auth", "mijn-account"]);
    } else {
      window.open(
        "https://www.onlineslagen.nl/mijn-account/edit-account/",
        "_blank"
      );
    }
  }

  logout() {
    (window as any).fcWidget.destroy();
    this.cookieService.delete("token", "/");
    this.redirectToLogin();
  }

  redirectToLogin() {
    this.router.navigateByUrl("/auth");
  }
}
