<div class="background-light-blue pt-5 scroll-padding">
  <div class="col-11 col-xxl-8 px-0 mx-auto">
    <div class="col-12 col-xl-6 col-xxl-6 mx-auto">
      <div class="d-flex flex-wrap justify-content-between pe-5">
        <img
          src="../../../../assets/brand/os/logo/dark.svg"
          alt="OnlineSlagen"
          style="max-height: 40px"
          class="me-md-5 me-0"
        /><img
          src="../../../../assets/partners/ah_logo.png"
          alt="Albert Heijn"
          style="max-height: 60px"
          class="ms-md-5 ms-0"
        />
      </div>

      <h1 class="font-arvo os-bold my-3">
        Welkom Albert Heijn franchise ondernemers
      </h1>

      <p class=" pb-2">
        Wat leuk dat je meedoet aan de online examentrainingen voor medewerkers.
        Via deze pagina kun je medewerkers gemakkelijk aanmelden.
      </p>
      <p class=" pb-2">
        Vul hieronder het e-mail adres in waarmee jij je hebt aangemeld. Hierna
        kom je op een pagina waar je medewerkers kunt opgeven. Medewerkers
        ontvangen een e-mail met hun inloggegevens en kunnen gelijk
        starten. Voor vragen of opmerkingen kun je contact opnemen via
        info@onlineslagen.nl en helpen we je graag verder!
      </p>
      <div *ngIf="!isAuthorized" class="form col-md-10 col-12 mx-auto px-4">
        <form [formGroup]="authorizedRegistratorForm">
          <div class="col-12 px-0">
            <label class="os-h8-arvo-bold">Jouw e-mail (ter verificatie)</label>
            <shared-input
              [group]="authorizedRegistratorForm"
              [controlName]="'email'"
              [type]="'email'"
              [placeHolder]="'Vul hier je e-mail in...'"
              [errorMessage]="'Vul een geldig e-mailadres in'"
            ></shared-input>
          </div>
          <div
            *ngIf="authorizedRegistersErrorMessage"
            class="text-danger font-14 os-semi-bold"
          >
            {{ authorizedRegistersErrorMessage }}
          </div>
          <div class="text-center mt-3">
            <shared-button
              [text]="'Verder'"
              [isLoading]="isLoading"
              [isDisabled]="!authorizedRegistratorForm.valid"
              (click)="onAuthorizedRegistratorFormSubmit()"
            >
            </shared-button>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="isAuthorized" class="form col-md-10 col-12 mx-auto px-4">
      <shared-sub-title
        class="text-center d-block mb-3"
        [title]="'Vul gegevens van medewerkers in'"
      ></shared-sub-title>
      <form *ngFor="let form of studentForms; let i = index" [formGroup]="form">
        <div
          class="d-block d-xl-flex justify-content-between align-items-center mb-2"
        >
          <div class="os-h8-arvo-bold">#{{ i + 1 }}</div>
          <div class="col-12 col-xl-2 mb-1">
            <shared-input
              [group]="form"
              [controlName]="'firstName'"
              [placeHolder]="'Voornaam...'"
              [errorMessage]="'Voornaam is verplicht'"
            ></shared-input>
          </div>
          <div class="col-12 col-xl-3 mb-1">
            <shared-input
              [group]="form"
              [controlName]="'lastName'"
              [placeHolder]="'Achternaam...'"
              [errorMessage]="'Achternaam is verplicht'"
            ></shared-input>
          </div>
          <div class="col-12 col-xl-4 mb-1">
            <shared-input
              [group]="form"
              [controlName]="'email'"
              [type]="'email'"
              [placeHolder]="'E-mail...'"
              [errorMessage]="'Vul een geldig e-mailadres in'"
            ></shared-input>
          </div>
          <div class="col-12 col-xl-2 mb-1">
            <shared-input
              [group]="form"
              [controlName]="'storeNr'"
              [placeHolder]="'Winkelnummer...'"
              [errorMessage]="'Winkelnummer is verplicht'"
            ></shared-input>
          </div>
        </div>
        <div
          *ngIf="i === 0"
          class="d-flex align-items-center justify-content-end mb-2 cursor-pointer "
          (click)="onUseStoreNrEverywhere()"
        >
          <input
            type="checkbox"
            class="me-2"
            style="width: 20px; height: 20px"
            [checked]="useStoreNrEverywhere"
          />Winkelnummer overal gebruiken
        </div>
      </form>
      <div class="d-block text-center mt-3">
        <shared-button
          [text]="'Extra medewerker'"
          [style]="'secondary'"
          [faIcon]="'fa-solid fa-plus'"
          (click)="addStudentForm()"
        >
        </shared-button>
      </div>
      <div class="d-block text-center mt-3">
        <shared-button
          [text]="'Medewerkers uitnodigen'"
          [isLoading]="isLoading"
          (click)="onStudentSignUpFormSubmit()"
        >
        </shared-button>
        <div class="font-14 os-semi-bold mt-1">
          {{ loadingMessage }}
        </div>
      </div>
    </div>
  </div>
</div>

<anonymous-chat-component></anonymous-chat-component>
