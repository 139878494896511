import { ChangeDetectionStrategy, Component } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
  standalone: true,
  selector: "shared-row-layout",
  templateUrl: "./row-layout.component.html",
  styleUrls: ["./row-layout.component.scss"],
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RowLayoutComponent {
}
