import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
  standalone: true,
  selector: "app-updates-modal",
  templateUrl: "./updates-modal.component.html",
  styleUrls: ["./updates-modal.component.scss"],
  imports: [CommonModule]
})
export class UpdatesModalComponent implements OnInit {
  @Input() updates!: string;
  @Output() modalCloseClicked = new EventEmitter<void>();
  @Output() modalRefreshClicked = new EventEmitter<void>();
  constructor() {}

  ngOnInit(): void {}

  onModalCloseClicked() {
    this.modalCloseClicked.emit();
  }

  onRefreshClicked() {
    this.modalRefreshClicked.emit();
  }
}
