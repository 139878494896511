import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxImageZoomModule } from "ngx-image-zoom";

@Component({
  standalone: true,
  selector: "image-modal",
  templateUrl: "./image-modal.component.html",
  styleUrls: ["./image-modal.component.scss"],
  imports: [NgxImageZoomModule]
})
export class ImageModalComponent implements OnInit {
  @Input() imageSrc!: string;

  myThumbnail!: string;
  myFullresImage!: string;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.myThumbnail = this.imageSrc;
    this.myFullresImage = this.imageSrc;
  }

  closeModal() {
    this.activeModal.close();
  }
}
