import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ButtonComponent, SubTitleComponent } from "@app/shared/basic-components";
import { ClickOutsideDirective } from "@app/shared/directives/click-outside.directive";

type CheckboxOption<CheckedKey extends string> = {
  [k in CheckedKey]: boolean;
};

@Component({
  standalone: true,
  selector: "shared-checkbox-modal",
  templateUrl: "./checkbox-modal.component.html",
  styleUrls: ["./checkbox-modal.component.scss"],
  imports: [CommonModule, SubTitleComponent, ButtonComponent, ClickOutsideDirective]
})
export class CheckboxModal<CheckedKey extends string> implements OnInit {
  @Input() title: string = "Kies opties";
  @Input() options!: CheckboxOption<CheckedKey>[];
  @Input() key!: string;
  @Input() isCheckedKey!: CheckedKey;
  @Output() save = new EventEmitter<any[]>();
  @Output() close = new EventEmitter<void>();

  nrSelectedItems: number = 0;

  constructor() {}

  ngOnInit(): void {
    this.nrSelectedItems = this.getNrSelectedItems();
  }

  closeWindow() {
    this.close.emit();
  }

  onOptionClicked(option: CheckboxOption<CheckedKey>) {
    option[this.isCheckedKey] = !option[this.isCheckedKey];
    this.nrSelectedItems = this.getNrSelectedItems();
  }

  onSubmitClicked() {
    this.save.emit(this.options);
  }

  toggleSelectAll() {
    if (this.nrSelectedItems > 0) {
      this.options.map((option) => (option[this.isCheckedKey] = false));
      this.nrSelectedItems = 0;
    } else {
      this.options.map((option) => (option[this.isCheckedKey] = true));
      this.nrSelectedItems = this.options.length;
    }
  }

  getNrSelectedItems() {
    var list = this.options.filter((option) => {
      return option[this.isCheckedKey];
    });
    return list.length;
  }
}
