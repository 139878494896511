<label *ngIf="title" class="mb-2 os-semi-bold font-18">{{ title }}</label>
<div
  [ngClass]="{
    'd-flex align-items-center flex-wrap': direction === 'horizontal',
    'justify-content-start': justify === 'start',
    'justify-content-end': justify === 'end',
    'justify-content-center': justify === 'center',
    'justify-content-between': justify === 'between'
  }"
>
  <div
    *ngFor="let chip of chips; let i = index"
    class="flex-between-center chip os-semi-bold mb-2"
    [ngClass]="{
      'chip-active': selectedKeys?.includes(chip.key),
      'chip-disabled': disabled && !selectedKeys?.includes(chip.key),
      'br-20': mode === 'single',
      'br-6': mode === 'multiple',
      'me-2': i < chips.length - 1
    }"
    (click)="onChipClicked(chip)"
  >
    <i
      *ngIf="selectedKeys?.includes(chip.key)"
      class="fa-regular pe-2 font-14"
      [ngClass]="{
        'fa-square-check': mode === 'multiple',
        'fa-circle-check': mode === 'single'
      }"
    ></i>
    <i
      *ngIf="!selectedKeys?.includes(chip.key)"
      class="pe-2 font-14 fa-regular"
      [ngClass]="{
        'fa-square': !disabled && mode === 'multiple',
        'fa-circle': !disabled && mode === 'single',
        'fa-solid fa-ban': disabled
      }"
    ></i>
    {{ chip.value }}
  </div>
</div>
