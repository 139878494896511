import { Component, Input, OnInit } from "@angular/core";
import { UtilService } from "@app/shared/services/util.service";
import { Breadcrumb } from "@app/shared/models/breadcrumb";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

@Component({
  standalone: true,
  selector: "shared-breadcrumbs",
  templateUrl: "./breadcrumbs.component.html",
  styleUrls: ["./breadcrumbs.component.scss"],
  imports: [CommonModule, RouterModule],
})
export class BreadCrumbsComponent implements OnInit {
  @Input() breadcrumbs!: Breadcrumb[];

  previousBreadcrumb!: Breadcrumb;
  currentBreadcrumb!: Breadcrumb;

  constructor(private utilService: UtilService) {}

  ngOnInit(): void {
    this.breadcrumbs.forEach((breadcrumb, index) => {
      if (breadcrumb.queryParams) {
        this.translateQueryParams(breadcrumb);
      }
      if (breadcrumb.url === "") {
        this.currentBreadcrumb = breadcrumb;
        if (index > 0) {
          this.previousBreadcrumb = this.breadcrumbs[index - 1];
        }
      }
    });
  }

  private translateQueryParams(breadcrumb: Breadcrumb) {
    breadcrumb.url +=
      "?" + this.utilService.dictionaryToQueryParams(breadcrumb.queryParams);
  }

  protected getUrlWithoutQueryParams(url: string): string {
    return url.split("?")[0];
  }

  protected getQueryParams(url: string): Record<string, string> {
    const queryParamsString = url.split("?")[1];
    if (!queryParamsString) {
      return {};
    }
    return this.parseQueryParams(queryParamsString);
  }

  protected parseQueryParams(queryParamsString: string): Record<string, string> {
    return queryParamsString
      .split("&")
      .reduce((acc: Record<string, string>, param: string) => {
        const [key, value] = param.split("=");
        acc[key] = decodeURIComponent(value);
        return acc;
      }, {});
  }
}
