<div class="d-flex align-items-center cursor-pointer">
  <div class="col-1" *ngIf="!fileEditToggle" (click)="fileEditToggle = true">
    {{ file.index }}.
  </div>
  <shared-input
    *ngIf="fileEditToggle"
    class="col-1"
    [group]="fileForm"
    [controlName]="'index'"
    [type]="'number'"
    (enterPress)="submitFileIndex()"
  ></shared-input>

  <shared-selector
    class="d-block col-2 px-2"
    [list]="fileTypes"
    [placeHolder]="'Kies bestandstype'"
    [selectedItem]="file.type"
    (itemSelect)="onFileTypeSelected($event)"
  ></shared-selector>
  <div *ngIf="!fileEditToggle" class="col-8 flex-between-center">
    <div>{{ file.width }}</div>
    <div class="os-link">
      <a href="{{ file.url }}" target="_blank">{{ file.title }}</a>
    </div>
    <div>
      <i
        class="fa-solid fa-pen cursor-pointer me-3 font-18"
        (click)="fileEditToggle = true"
      ></i>
      <i
        class="fa-regular fa-trash-can os-text-red cursor-pointer font-18"
        (click)="onDeleteFileClicked()"
      ></i>
    </div>
  </div>
  <div *ngIf="fileEditToggle" class="col-8 flex-between-center">
    <shared-input
      class="col-2 pe-2"
      [group]="fileForm"
      [controlName]="'width'"
      [type]="'text'"
      [placeHolder]="'Breedte...'"
      (enterPress)="submitFile()"
    ></shared-input>
    <shared-input
      class="col-8 pe-2"
      [group]="fileForm"
      [controlName]="'title'"
      [type]="'text'"
      [placeHolder]="'Vul titel van bestand in...'"
      [errorMessage]="'Vul een titel in'"
      (enterPress)="submitFile()"
    ></shared-input>
    <shared-button
      class="col-2"
      [text]="'Opslaan'"
      (click)="submitFile()"
    ></shared-button>
  </div>
</div>
