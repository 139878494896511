<label *ngIf="label" class="mb-1 os-semi-bold font-16" [class.required]="required">{{ label }}</label>
<div class="d-flex gap-3">
  <!-- Desktop dropdown layout -->
  <div
    class="d-none d-lg-block os-text-secondary flex-grow-1"
    [class.cursor-pointer]="!readonly"
    (clickOutside)="showList = false"
    (click)="onShowListClicked()"
  >
    <ng-container *ngTemplateOutlet="dropdownTemplate"></ng-container>
  </div>

  <!-- Mobile dropdown layout -->
  <div
    class="d-lg-none os-text-secondary flex-grow-1"
    [class.cursor-pointer]="!readonly"
    (click)="onShowListClicked(true)"
  >
    <ng-container *ngTemplateOutlet="dropdownTemplate"></ng-container>
  </div>

  <!-- Icons to trigger edit mode (both mobile && desktop) -->
  <div *ngIf="readonlyByDefault" class="d-flex gap-3">
    <i
      *ngIf="readonly"
      class="font-16 fa fa-pencil fa-primary-color cursor-pointer align-fa-icon"
      (click)="onEdit()"
    ></i>

    <ng-container *ngIf="!readonly">
      <i
        class="font-16 fa fa-check os-text-success cursor-pointer align-fa-icon"
        (click)="onConfirmEdit()"
      ></i>
      <i
        class="font-16 fa fa-times os-text-red cursor-pointer align-fa-icon"
        (click)="onCancelEdit()"
      ></i>
    </ng-container>
  </div>
</div>

<ng-template #dropdownTemplate>
  <div class="select" [ngClass]="showList ? 'select-open' : ''">
    <div class="d-flex justify-content-between align-items-center">
      <p class="m-0 font-16" [class.os-semi-bold]="selectedItem" [class.text-hint]="!selectedItem">
        {{
          (selectedItem) ? (key ? selectedItem[key] : selectedItem) : placeHolder
        }}
      </p>

      <loading-spinner *ngIf="isLoading; else openSelectIconTemplate" [small]="true"></loading-spinner>

      <ng-template #openSelectIconTemplate>
        <i
          class="open-select-icon {{ showList ? 'fa fa-chevron-down' : 'fa fa-chevron-right' }}"
          width="20px"
          [ngClass]="{ readonly: readonly }"
        ></i>
      </ng-template>
    </div>
    <div class="font-16 os-semi-bold select-unfolded {{ size }}" *ngIf="showList">
      <div #selectItemsContainer class="select-items-container">
        <ng-container *ngTemplateOutlet="dropdownOptionsTemplate"></ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #mobileDropdownPopupTemplate>
  <div class="mobile-popup-options-wrapper p-2">
    <ng-container *ngTemplateOutlet="dropdownOptionsTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #dropdownOptionsTemplate>
  <div *ngIf="includeAllOption" class="unfold-item p-2 p-lg-1 px-lg-2" (click)="onItemSelected(null)"><span>Alle</span></div>

  <div
    *ngFor="let item of _list"
    class="unfold-item p-2 p-lg-1 px-lg-2"
    [ngClass]="{
          'selected-item': key
            ? selectedItem && selectedItem[key] === item[key]
            : selectedItem === item
        }"
    (click)="onItemSelected(item)"
  >
        <span>
          {{ key ? item[key] : item }}
        </span>
  </div>
</ng-template>
