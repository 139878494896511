import { TableCellType } from "../enums/table-cell-types-enum";

export class TableCell {
  type!: TableCellType;
  value!: any;
  maxValue?: number;
  text?: string;
  url?: string;
  is_clickable?: boolean;
  object?: any;
  borderStyle?: "os-border-solid" | "os-border-dashed" | "os-border-dotted" =
    "os-border-solid";
}
