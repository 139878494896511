import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input, OnDestroy, OnInit,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { AbstractControl, FormGroup, ValidationErrors } from "@angular/forms";
import { CustomErrorMessages, ValidationErrorKeys, ValidationService } from "@app/shared/services/validation";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  standalone: true,
  selector: "shared-input-error",
  templateUrl: "./input-error.component.html",
  styleUrls: ["./input-error.component.scss"],
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputErrorComponent implements OnInit, OnDestroy {
  @Input() controlName?: string;
  @Input() controlGroup?: FormGroup;
  @Input() customErrorMessages?: CustomErrorMessages;

  private readonly destroyed = new Subject<void>();

  get isControlInvalid(): boolean {
    return !!this.control && !!this.control.errors && this.control.touched;
  }

  get control(): AbstractControl | null {
    return (this.controlName && this.controlGroup) ? this.controlGroup.get(this.controlName) : null;
  }

  constructor(
    private validationService: ValidationService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.control) {
      this.control.statusChanges.pipe(takeUntil(this.destroyed)).subscribe(() => this.cdr.markForCheck())
    }
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  protected getErrorMessage(errors: ValidationErrors): string {
    const error = Object.keys(errors)[0] as ValidationErrorKeys;
    const maxLengthErrorAdditionalInfo = errors[ValidationErrorKeys.MAX_LENGTH]?.requiredLength;

    return this.customErrorMessages?.[error] || this.validationService.getErrorMessage(error, maxLengthErrorAdditionalInfo);
  }
}
