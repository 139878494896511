import { AvatarConfiguration } from "@app/auth/models/AvatarConfiguration";
import { S24Roles } from "@app/shared/enums/s24-roles.enum";
import { OSRoles } from "@app/shared/enums/user-type-enum";
import { UtilService } from "@app/shared/services/util.service";
import { Level } from "@app/user/models/Level";
import { UserExtended } from "@app/user/models/UserExtended";

export class User {
  isStudent: boolean = false;
  isTeacher: boolean = false;
  isStaff: boolean = false;

  constructor(
    public id: number,
    public first_name: string,
    public last_name: string,
    public username: string,
    public email: string,
    public is_staff: boolean,
    public is_superuser: boolean,
    public last_login: Date,
    public level: Level,
    public user_extended: UserExtended,
    public is_online?: boolean,
    public avatar?: AvatarConfiguration,
    public last_visited_date?: Date,
    public last_visited_url?: string,
    public last_visited_place?: number
  ) {
    this.setIsStudent();
    this.setIsTeacher();
    this.setIsStaff();
  }

  public static Factory(data: User): User {
    return new User(
      data.id,
      data.first_name,
      data.last_name,
      data.username,
      data.email,
      data.is_staff,
      data.is_superuser,
      new Date(data.last_login),
      data.level,
      data.user_extended,
      data.is_online,
      data.avatar ? new AvatarConfiguration(data.avatar) : undefined,
      data.last_visited_date ? new Date(data.last_visited_date) : undefined,
      data.last_visited_url,
      data.last_visited_place
    );
  }

  private setIsStudent() {
    if (UtilService.getProductName() === "OnlineSlagen") {
      this.isStudent =
        this.user_extended.role === OSRoles.Student ||
        this.user_extended.role === OSRoles.DemoStudent ||
        this.user_extended.role === OSRoles.CustomerStudent ||
        this.user_extended.role === OSRoles.NoRole;
    } else {
      this.isStudent =
        this.user_extended.s24_role === S24Roles.StudentS24 ||
        this.user_extended.s24_role === S24Roles.DemoStudentS24 ||
        this.user_extended.s24_role === S24Roles.CustomerStudentS24 ||
        this.user_extended.s24_role === S24Roles.NoRole;
    }
  }

  private setIsTeacher() {
    this.isTeacher =
      this.user_extended.role === OSRoles.Teacher ||
      this.user_extended.role === OSRoles.DemoTeacher ||
      this.user_extended.role === OSRoles.SchoolAdmin ||
      this.user_extended.s24_role === S24Roles.Teacher ||
      this.user_extended.s24_role === S24Roles.DemoTeacher ||
      this.user_extended.s24_role === S24Roles.SchoolAdmin;
  }

  private setIsStaff() {
    this.isStaff = this.is_staff || this.is_superuser;
  }
}
