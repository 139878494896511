<div class="info-block os-block-light-blue-outline">
  <div class="ps-3" style="border-left: 6px solid #3a5aff">
    <div class="os-h6-arvo mb-2">{{ title }}</div>
    <div class="" style="font-size: 16px" [innerHTML]="message"></div>
    <!-- <a class="os-link os-bold font-16">Lees meer</a> -->
  </div>
  <i
    *ngIf="enableHide"
    class="fa-solid fa-circle-xmark os-text-blue remove-message-cross font-22"
    (click)="onCrossClicked()"
  ></i>
</div>
