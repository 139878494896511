import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'splitGetLast'
})
export class SplitGetLastPipe implements PipeTransform {
  transform(val:string, params:string[]):string {
    const splitArray = val.split(params[0]);
    return splitArray[splitArray.length - 1];
  }
}
