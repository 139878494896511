import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable, of, tap } from "rxjs";

import { UserExtended } from "@app/user/models/UserExtended";
import { User } from "@app/models/User";
import { UtilService } from "@app/shared/services/util.service";

import { environment } from "@env/environment";
import { SchoolPublicData } from "@app/school/models/SchoolPublicData";
import { UserTraining } from "@app/training/shared/models/UserTraining";
import { UserCourseStats } from "@app/training/shared/models/UserCourseStats";
import {
  UserCourseFacadeTopology,
  UserTopology,
} from "@app/training/shared/models/UserTopology";
import { Profile } from "@app/user/models/Profile";

@Injectable({
  providedIn: "root",
})
export class UserService {
  userUrl: string = environment.apiEndpoint + "/user/";
  userRestoreIdUrl: string = environment.apiEndpoint + "/restore-id/";
  userTrainingsUrlV2: string = environment.apiEndpointV2 + "/user/trainings/";

  userExtended!: UserExtended;

  private userState: User | null = null;
  private userExtendedState: UserExtended | null = null;

  constructor(private http: HttpClient, private utilService: UtilService) {}

  clearState(): void {
    this.userExtendedState = null;
    this.userState = null;
  }

  isStaff(): boolean {
    if (this.userExtended && this.userExtended.user) {
      return (
        !!this.userExtended.user.is_staff ||
        !!this.userExtended.user.is_superuser
      );
    } else {
      return false;
    }
  }

  getUser(): Observable<User> {
    const url = environment.apiEndpoint + "/v2/user/";
    return this.userState
      ? of(this.userState)
      : this.http.get<User>(url).pipe(tap((user) => (this.userState = user)));
  }

  updateUser(User: User): Observable<User> {
    return this.http.patch<any>(environment.apiEndpoint + "/v2/user/", User);
  }

  getUserExtended(): Observable<UserExtended> {
    return this.userExtendedState
      ? of(this.userExtendedState)
      : this.http
          .get<UserExtended>(this.userUrl)
          .pipe(tap((userExtended) => (this.userExtendedState = userExtended)));
  }

  updateUserExtended(
    userExtended: Partial<UserExtended>,
    acSync: boolean = false
  ): Observable<UserExtended> {
    return this.http
      .patch<UserExtended>(this.userUrl + "0/?ac_sync=" + acSync, userExtended)
      .pipe(tap((userExtended) => (this.userExtendedState = userExtended)));
  }

  updateUserEmailReceiver(email_receiver: boolean): Observable<any> {
    return this.http.patch<any>(this.userUrl, {
      email_receiver,
    });
  }

  getUserTrainingsV2(params: {
    level?: number;
    type?: number;
    production_ready?: boolean;
    is_active?: boolean;
  }): Observable<UserTraining[]> {
    return this.http.get<UserTraining[]>(
      this.userTrainingsUrlV2 +
        "?" +
        this.utilService.dictionaryToQueryParams(params)
    );
  }

  getUserTopology(
    teachingMethodId: number,
    params?: { q?: string; mode?: string }
  ) {
    return this.http.get<UserTopology>(
      this.userUrl +
        "teaching-methods/" +
        teachingMethodId +
        "/topology?" +
        this.utilService.dictionaryToQueryParams(params)
    );
  }

  getUserTopologyV2(
    teachingMethodId: number,
    params?: { get_only_selected_domains?: boolean; q?: string }
  ): Observable<UserCourseFacadeTopology> {
    return this.http.get<UserCourseFacadeTopology>(
      `${
        environment.apiEndpointV2
      }/user/teaching-methods/${teachingMethodId}/topology?${this.utilService.dictionaryToQueryParams(
        params
      )}`
    );
  }

  createUserSearch(body: {
    keyword: string;
    amount_of_results: number;
    training: number;
  }): Observable<any> {
    return this.http.post<any>(this.userUrl + "search", body);
  }

  getUserCourseStats(courseId: number): Observable<UserCourseStats> {
    return this.http.get<any>(this.userUrl + "courses/" + courseId + "/stats");
  }

  getUserTeachingMethodCourseStats(
    teachingMethodId: number
  ): Observable<UserCourseStats[]> {
    return this.http.get<any>(
      this.userUrl + "teaching-methods/" + teachingMethodId + "/course-stats"
    );
  }

  getUserTraining(trainingId: number): Observable<UserTraining> {
    return this.http.get<UserTraining>(this.userTrainingsUrlV2 + trainingId);
  }

  loadUserWpProductTokens(): Observable<any> {
    return this.http.get<any>(
      environment.apiEndpoint + "/user/load-wp-product-tokens?"
    );
  }

  loadAccess(): Observable<any> {
    return this.http.get<any>(environment.apiEndpoint + "/v2/auth/load-access");
  }

  postRestoreId(restoreId: number) {
    return this.http.post<number>(this.userRestoreIdUrl, {
      restore_id: restoreId,
    });
  }

  getUserSchools(): Observable<SchoolPublicData[]> {
    return this.http.get<SchoolPublicData[]>(this.userUrl + "schools");
  }

  getUserGroupBridgesSchool(schoolId: number): Observable<any> {
    return this.http.get(this.userUrl + "schools/" + schoolId + "/groups");
  }

  updateActiveUserTraining(
    userTraining: UserTraining
  ): Observable<UserTraining> {
    if (userTraining.teachingmethod) {
      userTraining.teachingmethod = userTraining.teachingmethod.id;
    }
    return this.http.patch<UserTraining>(
      this.userTrainingsUrlV2 + userTraining.training.id,
      userTraining
    );
  }

  updateActiveUserTrainings(
    userTrainings: UserTraining[]
  ): Observable<UserTraining[]> {
    return this.http.put<UserTraining[]>(
      this.userTrainingsUrlV2,
      userTrainings
    );
  }

  getProfile(userId: number): Observable<Profile> {
    return this.http.get<Profile>(
      `${environment.apiEndpoint}/user/${userId}/profile`
    );
  }
}
