<div class="modal-body">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLongTitle">
      Nieuw in deze versie
    </h5>
    <button
      type="button"
      class="close text-danger"
      (click)="onModalCloseClicked()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" [innerHTML]="updates"></div>
  <div class="modal-footer">
    <div
      class="os-mobile-btn os-mobile-focus-btn os-btn-text mx-auto"
      (click)="onRefreshClicked()"
    >
      Verversen
    </div>
  </div>
</div>
