import { Injectable } from "@angular/core";

export enum ValidationErrorKeys {
  REQUIRED = 'required',
  MAX_LENGTH = 'maxlength',
  MAX = 'max',
  EMAIL = 'email',
  SAME_EMAIL_NOT_ALLOWED = 'sameEmailNotAllowed',
  NOT_ALLOWED_VALUE = 'notAllowedValue',
  INVALID_URL = 'invalidUrl'
}

export type CustomErrorMessages = Partial<Record<ValidationErrorKeys, string>>

@Injectable({
  providedIn: "root",
})
export class ValidationService {
  protected errorMessages: Record<ValidationErrorKeys, (additionalArg?: string) => string> = {
    [ValidationErrorKeys.REQUIRED]: () => 'Dit veld kan niet leeg zijn',
    [ValidationErrorKeys.MAX_LENGTH]: (additionalArg) => `Maximale lengte is ${additionalArg} tekens`,
    [ValidationErrorKeys.MAX]: () => 'Maximale waarde overschreden',
    [ValidationErrorKeys.EMAIL]: () => 'Vul een geldig e-mailadres in',
    [ValidationErrorKeys.SAME_EMAIL_NOT_ALLOWED]: () => 'Geef een ander e-mailadres op',
    [ValidationErrorKeys.NOT_ALLOWED_VALUE]: () => 'Waarde niet toegestaan',
    [ValidationErrorKeys.INVALID_URL]: () => 'Voer een geldige URL in',
  }

  getErrorMessage(errorKey: string, additionalInfo?: string): string {
    return this.errorMessages[errorKey as ValidationErrorKeys]?.(additionalInfo) || 'Ongeldig';
  }
}
