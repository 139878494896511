<div *ngIf="userFeedbackForm && questions" class="modal-body text-center p-4">
  <div class="modal-header pt-0 text-end">
    <i class="fa fa-times icon text-danger" (click)="closeModal()"></i>
  </div>
  <img
    src="{{ staticFilesEndpoint }}/media/uploads/2020/01/10/like1.png"
    width="96px"
    class="pt-3"
  />
  <h4 class="modal-title mt-4 mb-2">
    <b>{{ userFeedbackForm.feedbackForm.title }}</b>
  </h4>
  <div *ngFor="let question of questions">
    <p>
      {{ question.title }}
    </p>

    <div class="star-selector my-4">
      <i
        *ngFor="let filledStar of filledStarArray; let j = index"
        class="fa-solid fa-star os-gold cursor-pointer font-40 me-1"
        (click)="filledStarClicked(j)"
        width="40px"
      ></i>
      <i
        *ngFor="let filledStar of emptyStarArray; let j = index"
        class="fa-regular fa-star os-gold cursor-pointer font-40 me-1"
        (click)="emptyStarClicked(j)"
        width="40px"
      ></i>
    </div>
  </div>
  <div class="my-2">
    <shared-button [text]="buttonText" (click)="sendFeedback()"></shared-button>
    <p *ngIf="errorMessage" class="text-danger mt-2">{{ errorMessage }}</p>
  </div>
  <div *ngIf="showFollowUp" class="mx-auto">
    <p>Ontzettend bedankt!</p>
    <p>Wil je ons nog even helpen door 2 korte vragen te beantwoorden?</p>
    <div class="flex-between-center">
      <shared-button
        [text]="'Nee'"
        [style]="'secondary'"
        (click)="closeModal()"
      >
      </shared-button>
      <shared-button [text]="'Ja'" (click)="forwardToFeedbackForm()">
      </shared-button>
    </div>
  </div>
</div>
