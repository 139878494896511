import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AttachmentService } from "@app/shared/services/attachment.service";
import { UtilService } from "@app/shared/services/util.service";

import { File as OSFile } from "@app/shared/models/File";
import { CommonModule } from "@angular/common";
import { ErrorMessageComponent } from "@app/shared/basic-components";
import { SafePipe } from "@app/shared/pipes";

@Component({
  standalone: true,
  selector: "shared-file-page",
  templateUrl: "./file-page.component.html",
  styleUrls: ["./file-page.component.scss"],
  imports: [CommonModule, ErrorMessageComponent, SafePipe]
})
export class FilePageComponent implements OnInit {
  fileId!: number;
  file!: OSFile;
  errorMessage!: string;

  constructor(
    private utilService: UtilService,
    private attachmentService: AttachmentService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getParams();
    this.getFile();
  }

  getParams() {
    this.fileId = +this.route.snapshot.params["file-id"];
  }

  getFile() {
    this.attachmentService.getFile(this.fileId).subscribe({
      next: (file) => {
        this.file = file;
        this.utilService.setTitle("Bijlage - " + this.file.title);
      },
      error: (error: HttpErrorResponse) => {
        if (error.status === 404) {
          this.errorMessage = "Bestand niet gevonden.";
        } else {
          this.errorMessage = "Er ging iets fout.";
        }
      },
    });
  }
}
