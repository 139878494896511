import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { UtilService } from "@app/shared/services/util.service";
import { TitleComponent } from "@app/shared/basic-components";
import { CommonModule } from "@angular/common";

@Component({
  standalone: true,
  selector: "shared-no-access",
  templateUrl: "./no-access.component.html",
  styleUrls: ["./no-access.component.scss"],
  imports: [CommonModule, TitleComponent]
})
export class NoAccessComponent implements OnInit {
  schoolForm = new FormGroup({
    name: new FormControl("", [Validators.required]),
  });

  isSchool24!: boolean;

  constructor(private utilService: UtilService) {}

  ngOnInit(): void {
    this.isSchool24 = UtilService.isSchool24();
  }
}
