import {
  Directive,
  ElementRef,
  Output,
  EventEmitter,
  HostListener,
} from "@angular/core";

@Directive({
  standalone: true,
  selector: "[bottomScroll]",
})
export class BottomScrollDirective {
  @Output() public scrolledToBottom = new EventEmitter<void>();

  @HostListener("window:scroll", ["$event"]) onScroll() {
    this.detectEndOfVerticalScroll();
  }

  private detectEndOfVerticalScroll(): void {
    const element = document.documentElement;
    const scrollTop = element.scrollTop;
    const scrollHeight = element.scrollHeight;
    const clientHeight = element.clientHeight;

    const isEndReached = scrollTop + clientHeight >= scrollHeight;

    if (isEndReached) {
      this.scrolledToBottom.emit();
    }
  }
}
