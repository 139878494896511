<div
  class="modal-wrapper p-4 px-md-5"
  (clickOutside)="closeModal(); $event.stopPropagation()"
>
  <div class="close-icon-wrapper p-3 flex-center-center">
    <i
      class="fa-solid fa-xmark fa-primary-color font-20 cursor-pointer"
      (click)="closeModal()"
    ></i>
  </div>

  <header class="mb-3 d-flex justify-content-between">
    <span class="font-28 font-xl-32 os-bold">Kies een pakket</span>
  </header>
  <div class="blocks-wrapper pb-2 mb-3">
    <div class="premium-modal-block p-3 px-4 br-6">
      <div class="img-wrapper" [@shrinkHeight]="isMoreInfoView ? animationState.SHRUNK : animationState.INITIAL">
        <img src="../../../assets/brand/s24/account/basis.svg" alt="basis" />
      </div>
      <div class="block-header flex-grow-1 d-flex flex-column mb-3">
        <span class="os-semi-bold font-size-transition {{ isMoreInfoView ? 'font-18' : 'font-24'}}">Basis</span>
        <span class="font-weight-600 font-size-transition {{ isMoreInfoView ? 'font-24' : 'font-28'}}">Altijd gratis</span>
      </div>
      <div class="mb-3" [@displayHide]="isMoreInfoView ? animationState.DISPLAYED : animationState.HIDDEN">
        <div *ngFor="let item of comparisonItems; let i = index">
          <ng-container *ngTemplateOutlet="comparisonItemTemplate; context: { $implicit: item, selected: i === 0 || i === 1 || i === 2 }"></ng-container>
        </div>
      </div>
      <shared-button
        class="d-block {{ isMoreInfoView ? 'mb-2' : 'mb-4'}}"
        text="{{accountType === 'verified' ? 'Houd basis account' : 'Basis account aanmaken'}}"
        size="medium"
        [style]="'secondary'"
        [fullWidth]="true"
        (click)="onCreateDemoAccount()"
      ></shared-button>
      <div
        [@fadeOut]="isMoreInfoView ? animationState.FADED : animationState.INITIAL"
        [@displayHide]="isMoreInfoView ? animationState.HIDDEN : animationState.DISPLAYED"
      >
        <span><strong>Doorgaan met je voortgang</strong></span>
        <ul class="mb-0">
          <li>5000+ samenvattingen</li>
          <li>Markeren + notities</li>
          <li>Leren met jouw lesmethode</li>
        </ul>
      </div>
    </div>
    <div class="premium-modal-block p-3 px-4 br-6">
        <shared-button-group
          class="button-group"
          [buttonNames]="allSubscriptionTypes"
          [selectedButton]="selectedSubscriptionType"
          (selectedButtonChange)="onSubscriptionTypeSwitch($event)"
        ></shared-button-group>
        <div class="img-wrapper premium-img" [@shrinkHeight]="isMoreInfoView ? animationState.SHRUNK : animationState.INITIAL">
          <img src="../../../assets/brand/s24/account/premium.svg" alt="premium"/>
        </div>
        <div class="block-header flex-grow-1 d-flex flex-column mb-3">
          <ng-container  *ngIf="!isLoading; else loadingTemplate">
            <span class="font-size-transition os-semi-bold d-flex align-items-center gap-2 {{ isMoreInfoView ? 'font-18' : 'font-24'}}">
            Premium
              <div
                *ngIf="selectedSubscriptionType === subscriptionType.YEARLY"
                class="p-1 os-background-muted-blue font-weight-600 font-12 br-6 os-text-primary-color"
              >
                BESTE WAARDE
              </div>
            </span>
            <span class="font-weight-600 font-size-transition {{ isMoreInfoView ? 'font-24' : 'font-28'}}">€{{ selectedProduct.price | number : "1.2-2" }}</span>
            <span class="font-weight-300 font-size-transition {{ isMoreInfoView ? 'font-14' : ''}}"><i>Eerste {{ selectedProduct.free_trial_period }} dagen gratis</i></span>
          </ng-container>
        </div>
      <div class="mb-3" [@displayHide]="isMoreInfoView ? animationState.DISPLAYED : animationState.HIDDEN">
        <div *ngFor="let item of comparisonItems">
          <ng-container *ngTemplateOutlet="comparisonItemTemplate; context: { $implicit: item, selected: true }"></ng-container>
        </div>
      </div>
        <shared-button
          class="d-block {{ isMoreInfoView ? 'mb-2' : 'mb-4'}}"
          text="Premium account aanmaken"
          size="medium"
          [fullWidth]="true"
          (click)="goToShop()"
        ></shared-button>
        <div
          [@fadeOut]="isMoreInfoView ? animationState.FADED : animationState.INITIAL"
          [@displayHide]="isMoreInfoView ? animationState.HIDDEN : animationState.DISPLAYED"
        >
          <span><strong>Unlock alle features</strong></span>
          <ul class="mb-0">
            <li>Alles uit het basispakket</li>
            <li>Video's, begrippen en oefenopgaven</li>
            <li>Toetsen, hulp van docenten, en meer!</li>
          </ul>
        </div>
    </div>
  </div>
  <div
    class="d-flex flex-column text-center"
    [@fadeOut]="isMoreInfoView ? animationState.FADED : animationState.INITIAL"
    [@displayHide]="isMoreInfoView ? animationState.HIDDEN : animationState.DISPLAYED"
  >
    <span>Voor meer details en alle features</span>
    <span class="os-link os-bold" (click)="isMoreInfoView = true">Bekijk hier het overzicht</span>
  </div>
</div>

<ng-template #loadingTemplate>
  <span class="font-size-transition os-semi-bold d-flex align-items-center gap-2 font-24">Premium</span>
  <span class="font-weight-600 font-size-transition font-28">€9,50</span>
  <span class="font-weight-300 font-size-transition"><i>Eerste 7 dagen gratis</i></span>
</ng-template>

<ng-template #comparisonItemTemplate let-item let-selected="selected">
  <div class="d-flex gap-3 align-items-center" style="margin-bottom: 6px;">
    <i *ngIf="selected" style="width: 20px" class="fa fa-circle-check fa-primary-color font-18 align-fa-icon"></i>
    <i *ngIf="!selected" style="width: 20px" class="fa fa-circle-thin text-secondary font-18 align-fa-icon"></i>

    <span class="font-weight-500" [class.text-secondary]="!selected">{{ item }}</span>
  </div>
</ng-template>
