import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({ standalone: true, name: "secondsToMMSS" })
export class SecondsToMMMSSPipe implements PipeTransform {
  transform(seconds: number, useSemicolon = true): string {
    if (!seconds) return useSemicolon ? "00:00" : "0000";

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedMinutes}${useSemicolon ? ':' : ''}${formattedSeconds}`;
  }
}
