import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { MenuItem } from "@app/shared/models/MenuItem";
import { CommonModule } from "@angular/common";

@Component({
  standalone: true,
  selector: "shared-sidebar-menu",
  templateUrl: "./sidebar-menu.component.html",
  styleUrls: ["./sidebar-menu.component.scss"],
  imports: [CommonModule]
})
export class SidebarMenuComponent implements OnInit {
  @Input() menuItems!: MenuItem[];
  @Input() set selectedMenuItem(selectedMenuItem: MenuItem) {
    if (
      selectedMenuItem &&
      this.menuItems.find((menuItem) => menuItem.id === selectedMenuItem.id)
    ) {
      this._selectedMenuItem = selectedMenuItem;
    } else {
      this._selectedMenuItem = undefined;
    }
  }
  @Input() isCollapsable: boolean = false;
  @Input() placeHolder!: string;

  @Output() menuItemSelect: EventEmitter<MenuItem> = new EventEmitter();

  _selectedMenuItem?: MenuItem;

  toggleMenu!: boolean;

  constructor() {}

  ngOnInit(): void {}

  onToggleMenuClicked() {
    this.toggleMenu = !this.toggleMenu;
  }

  onMenuItemClicked(menuItem: MenuItem) {
    this.toggleMenu = false;
    this.menuItemSelect.emit(menuItem);
  }
}
