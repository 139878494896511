import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, Renderer2 } from "@angular/core";

import { CommonModule, DOCUMENT } from "@angular/common";
import { SubTitleComponent } from "../../headers/sub-title/sub-title.component";
import { LoadingComponent } from "../../loading/loading/loading.component";
import { ButtonComponent } from "../../buttons/button/button.component";
import { ClickOutsideDirective } from "@app/shared/directives/click-outside.directive";

type ModalSize = "sm" | "md" | "lg" | "xl" | "fullScreen";

@Component({
  standalone: true,
  selector: "base-modal",
  templateUrl: "./base-modal.component.html",
  styleUrls: ["./base-modal.component.scss"],
  imports: [CommonModule, SubTitleComponent, LoadingComponent, ButtonComponent, ClickOutsideDirective]
})
export class BaseModalComponent implements OnInit, OnDestroy {
  @Input() title!: string;
  @Input() submitDisabled = false;
  @Input() closeOnClickOutside = false;
  @Input() showCloseIcon = true;
  @Input() isLoading = false;
  @Input() modalSize: ModalSize = "md";
  @Input() showFooter = true;
  @Input() verificationButtonText: string = "Versturen";

  @Output() modalClosed = new EventEmitter<void>();
  @Output() modalSubmitted = new EventEmitter<void>();

  get modalBodyClass(): string {
    switch (this.modalSize) {
      case "sm":
        return 'scrollable p-4 p-xl-5 col-md-8 col-xl-4 col-xxl-3';
      case "fullScreen":
        return 'p-3 col-12 full-screen';
      default:
        return 'scrollable p-4 p-xl-5 col-md-8 col-xl-5 col-xxl-5';
    }
  }

  constructor(
    private readonly renderer: Renderer2,
    @Inject(DOCUMENT) private readonly document: Document
  ) { }

  ngOnInit(): void {
    this.renderer.addClass(this.document.body,'os-modal-open-no-scroll');
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body,'os-modal-open-no-scroll');
  }

  onSubmit(): void {
    this.modalSubmitted.emit();
  }

  onCloseWindow(): void {
    this.modalClosed.emit();
  }

  onClickOutside(): void {
    if (this.closeOnClickOutside) {
      this.modalClosed.emit();
    }
  }
}
