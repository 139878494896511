import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { debounceTime, distinctUntilChanged, merge, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  standalone: true,
  selector: "search-bar",
  templateUrl: "./search-bar.component.html",
  styleUrls: ["./search-bar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, FormsModule]
})
export class SearchBarComponent implements OnInit, OnDestroy {
  @Input() placeholder = "Zoek";
  @Output() search: EventEmitter<string> = new EventEmitter();

  protected searchValue = "";

  private searchDebounced$ = new Subject<string>();
  private searchOnEnter$ = new Subject<string>();

  private destroyed = new Subject<void>();

  ngOnInit(): void {
    merge(
      this.searchDebounced$.pipe(debounceTime(800)),
      this.searchOnEnter$
    ).pipe(takeUntil(this.destroyed), distinctUntilChanged())
      .subscribe(() => this.search.emit(this.searchValue));
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  protected onClearClicked() {
    this.searchValue = "";
    this.searchOnEnter$.next(this.searchValue);
  }

  protected onValueUpdated(): void {
    this.searchDebounced$.next(this.searchValue);
  }

  protected onSearch() {
    this.searchOnEnter$.next(this.searchValue);
  }
}
