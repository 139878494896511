import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { forwardRef, Provider, Type } from '@angular/core';

export function getValueAccessorProvider(component: Type<unknown>): Provider {
  return {
    multi: true,
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => component)
  };
}
