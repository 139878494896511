<div class="d-flex gap-3">
  <input
    #input
    class="form-control"
    [ngClass]="{
      small: size === 'small',
      medium: size === 'medium',
      readonly: readonly,
      'font-18': size === 'medium',
      'font-16': size === 'small',
      'is-invalid': isControlInvalid
    }"
    [id]="formControlName"
    [attr.list]="'dataset-' + formControlName"
    [attr.data-form-type]="dataFormType"
    [autocomplete]="autocomplete"
    [placeholder]="placeholder"
    [readonly]="readonly"
    [disabled]="disabled"
    [ngModel]="value"
    (ngModelChange)="onValueChange($event)"
    (keyup)="onKeyUp($event)"
  />

  <div *ngIf="readonlyByDefault" class="d-flex gap-3">
    <i
      *ngIf="readonly"
      class="font-16 fa fa-pencil fa-primary-color cursor-pointer align-fa-icon"
      (click)="onEdit()"
    ></i>

    <ng-container *ngIf="!readonly">
      <i
        class="font-16 fa fa-check os-text-success cursor-pointer align-fa-icon"
        (click)="onConfirmEdit()"
      ></i>
      <i
        class="font-16 fa fa-times os-text-red cursor-pointer align-fa-icon"
        (click)="onCancelEdit()"
      ></i>
    </ng-container>
  </div>
</div>
<shared-input-error [controlName]="formControlName" [controlGroup]="formGroup" [customErrorMessages]="customErrorMessages"></shared-input-error>

<datalist *ngIf="autocompleteData" id="dataset-{{ formControlName }}">
  <option *ngFor="let option of autocompleteData" [value]="option"></option>
</datalist>
