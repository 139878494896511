import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";

@Component({
  standalone: true,
  selector: "shared-archive-button",
  templateUrl: "./archive-button.component.html",
  styleUrls: ["./archive-button.component.scss"],
  imports: [CommonModule, NgbTooltip]
})
export class ArchiveButtonComponent {
  @Input() isArchived = false;

  @Output() clicked = new EventEmitter<void>();
}
