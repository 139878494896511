<div class="d-flex gap-2 align-items-center">
  <div><i class="fas fa-star star"></i></div>
  <div>{{ data }}</div>
  <div class="ms-auto">
    <i class="fas fa-question-circle-o cursor-pointer fa-primary-color font-18" (click)="onOpenXpInfoModal()"></i>
  </div>
  <div matSnackBarActions>
    <button mat-button matSnackBarAction (click)="snackBarRef.dismissWithAction()">
      <i class="fa fa-times"></i>
    </button>
  </div>
</div>
