export enum OSRoles {
  SchoolAdmin = "school_admin",
  Teacher = "teacher",
  DemoTeacher = "demo_teacher",
  Student = "student",
  CustomerStudent = "customer",
  DemoStudent = "demo_student",
  NoRole = "no_role",
  Admin = "admin",
}
