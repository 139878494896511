<div class="avatar-wrapper {{ size }}">
  <img
    *ngIf="avatarUrl; else avatarPlaceholderTemplate"
    [src]="avatarUrl"
    alt="avatar"
  />

  <ng-template #avatarPlaceholderTemplate>
    <div class="avatar-placeholder flex-center-center {{ colorClass }}">
      <span class="os-semi-bold os-text-white">{{
        userName ? userName[0].toUpperCase() : ""
      }}</span>
    </div>
  </ng-template>

  <div
    *ngIf="displayEditButton"
    class="edit-button flex-center-center text-decoration-none os-background-blue"
  >
    <i class="os-text-white fa fa-pencil align-fa-icon cursor-pointer"></i>
  </div>
</div>
