import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthService } from "@app/auth/auth.service";

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public router: Router, public authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!this.authService.isAuthenticated()) {
      if (state.url.includes("examenbuddy")) {
        this.router.navigate(["examenbuddy/auth"], {
          queryParams: { next: state.url },
        });
      } else {
        this.router.navigate(["auth/"], {
          queryParams: { next: state.url },
        });
      }
      return false;
    }
    return true;
  }
}
