import {
  Directive,
  ElementRef,
  Output,
  EventEmitter,
  HostListener,
} from "@angular/core";

@Directive({
  standalone: true,
  selector: "[clickOutside]",
})
export class ClickOutsideDirective {
  constructor(private _elementRef: ElementRef) {}

  @Output()
  public clickOutside = new EventEmitter<MouseEvent>();

  @HostListener("document:mouseup", ["$event", "$event.target"])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    event.preventDefault();
    event.stopPropagation();

    if (targetElement) {
      const clickedInside = this._elementRef.nativeElement.contains(
        targetElement
      );
      if (!clickedInside) {
        this.clickOutside.emit(event);
      }
    }
  }
}
