import { Injectable } from "@angular/core";
import { Location } from '@angular/common';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RouterService {
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private location: Location) { }

  public navigateTo(commands: any[]): void {
    this.router.navigate(commands);
  }

  public navigateBack(): void {
    this.location.back()
  }

  public addQueryParamsToRoute(queryParams: Record<string, unknown>): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: "merge"
    })
  }

  public getQueryParamFromRoute(paramName: string): string {
    return this.activatedRoute.snapshot.queryParamMap.get(paramName) as string;
  }

  public getQueryParams$(): Observable<Params> {
    return this.activatedRoute.queryParams;
  }

  public getParamFromRoute(activatedRoute: ActivatedRoute, paramName: string): string {
    return activatedRoute.snapshot.paramMap.get(paramName) as string;
  }

  public getParamsFromRoute(activatedRoute: ActivatedRoute, paramsName: string[]): (string | null)[]{
    return paramsName.map(paramName => this.getParamFromRoute(activatedRoute, paramName));
  }
}
