import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  standalone: true,
  name: "fullTimePipe",
})
export class FullTimePipe implements PipeTransform {
  transform(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secondsLeft = seconds % 60;
    return (
      hours +
      ":" +
      (minutes < 10 ? "0" : "") +
      minutes +
      ":" +
      (secondsLeft < 10 ? "0" : "") +
      secondsLeft
    );
  }
}
