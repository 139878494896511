import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ButtonComponent, OffcanvasSidebarComponent } from "@app/shared/basic-components";

export interface AdaptiveMenuItem {
  id: number | string;
  text: string;
  additionalText?: string;
  additionalTextClass?: string;
  leftIconClass?: string;
  rightIconClass?: string;
  children?: AdaptiveMenuItem[];
  expanded?: boolean;
}

export interface AdaptiveMenuLink {
  text: string;
  handler: () => void;
}

@Component({
  standalone: true,
  selector: "shared-adaptive-menu",
  templateUrl: "./adaptive-menu.component.html",
  styleUrls: ["./adaptive-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, OffcanvasSidebarComponent, ButtonComponent]
})
export class AdaptiveMenuComponent {
  @Input() menuItems: AdaptiveMenuItem[] = [];
  @Input() links: AdaptiveMenuLink[] = [];
  @Input() selectedMenuItem?: AdaptiveMenuItem;
  @Input() displayPreviousButton = true;
  @Input() displayNextButton = true;

  @Output() itemSelected: EventEmitter<AdaptiveMenuItem> = new EventEmitter();
  @Output() navigationButtonClicked: EventEmitter<boolean> = new EventEmitter();

  constructor(private readonly cdr: ChangeDetectorRef) {}

  public updateMenuItems(): void {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  protected onMenuItemClicked(menuItem: AdaptiveMenuItem) {
    if (!menuItem.children) {
      this.itemSelected.emit(menuItem);
    } else {
      menuItem.expanded = !menuItem.expanded;
    }
  }
}
