<base-modal
  modalSize="sm"
  title="Hoe verdien je XP?"
  [closeOnClickOutside]="true"
  [showFooter]="false"
  (modalClosed)="closeModal()"
>
  <p class="mb-3">Door te leren verdien je XP en ga je levels omhoog.</p>
  <div>
    <shared-sub-sub-title
      class="d-block mb-2"
      title="XP per actie"
    ></shared-sub-sub-title>
    <div>
      <div
        *ngFor="let action of xpGainActions; let i = index"
        class="d-flex gap-2 mb-1"
      >
        <span class="os-semi-bold">{{ i + 1 }}.</span>
        <span>{{ action.name }}</span>
        <span class="ms-auto os-semi-bold os-gold">{{ action.xp }} XP</span>
      </div>
    </div>
  </div>
</base-modal>
