import { TableCellType } from "../enums/table-cell-types-enum";
import { TableCell } from "./TableCell";

export class TableHeader {
  type!: TableCellType;
  value!: string;
  icon?: string;
  showMobile?: boolean;
  hoverText?: string;
  summary?: TableCell;
}
