<div class="content-wrapper os-background-blue" [class.br-6]="roundBorders">
  <div class="close-icon-wrapper p-3 flex-center-center">
    <i
      class="fa-solid fa-xmark os-text-white font-20 cursor-pointer"
      (click)="closeModal()"
    ></i>
  </div>

  <!--  No account layout  -->
  <div *ngIf="accountType === 'none' && !isLoading">
    <div class="footer-header os-background-blue os-text-white p-3">
      <h2 class="font-24 os-bold d-block mb-2">
        Maak een gratis account
      </h2>
      <p class="mb-0">En krijg toegang tot 5000+ samenvattingen.</p>
      <p class="mb-0">Voor altijd <strong>gratis.</strong></p>
    </div>

    <div class="footer-content os-bg-white p-3 py-3 py-xl-4">
      <div class="col-11 col-xl-10 mx-auto">
        <div class="d-flex os-semi-bold">
          <label>E-mail</label>
        </div>
        <shared-input
          class="d-block mb-4"
          [group]="signUpForm"
          [controlName]="'email'"
          [type]="'email'"
          [placeHolder]="'Vul hier je e-mail in...'"
          [errorMessage]="'Vul een geldig e-mailadres in'"
          (enterPress)="submitForm()"
        ></shared-input>
      </div>
      <shared-button
        text="Account aanmaken"
        [isLoading]="isLoading"
        (click)="submitForm()"
      ></shared-button>
      <div class="mt-1">
        <div *ngIf="errorMessage" class="text-danger font-14 os-semi-bold">
          {{ errorMessage }}
        </div>
        <a class="font-12 os-link os-semi-bold" href="/auth">
          Of log hier in
        </a>
      </div>
    </div>
  </div>

  <!--  Not verified account layout  -->
  <div *ngIf="accountType === 'unverified' && !isLoading" id="s24-demo-account-created">
    <div class="footer-header os-background-blue os-text-white p-3">
      <h2 class="font-24 os-bold d-block mb-2">
        Gelukt! Verifieer je account
      </h2>
      <p class="mb-0">
        Het kan 1 tot 5 minuten duren voordat de verificatie e-mail binnenkomt.
      </p>
    </div>

    <div class="footer-content os-bg-white p-3 py-3 py-xl-4">
      <shared-button
        class="d-block mb-2"
        [text]="'Pagina opnieuw laden'"
        [isLoading]="isLoading"
        (click)="refresh()"
      ></shared-button>
      <a
        class="mt-1 font-14 os-link os-semi-bold"
        (click)="onCreateNewAccount()"
      >
        Maak opnieuw een gratis account aan.
      </a>
    </div>
  </div>

  <div *ngIf="isLoading">
    <loading-spinner *ngIf="isLoading"></loading-spinner>
  </div>
</div>
