import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { PreSignUpUser } from "@app/models/PreSignUpUser";
import { UserExtended } from "@app/user/models/UserExtended";
import { UserService } from "@app/shared/services/user.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { EmployeeNrModalComponent } from "./components/employee-nr-modal.component";
import { UtilService } from "@app/shared/services/util.service";

@Component({
  selector: "ah-complete-account",
  templateUrl: "./ah-complete-account.component.html",
  styleUrls: ["./ah-complete-account.component.scss"],
})
export class AhCompleteAccountComponent implements OnInit {
  studentSignUpForm = new FormGroup({
    employeeID: new FormControl("", [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(10),
    ]),
  });

  private unsubscribe = new Subject();

  loadingMessage!: string;
  errorMessage!: string;
  isLoading!: boolean;

  userExtended!: UserExtended;

  user!: PreSignUpUser;

  showPassword!: boolean;
  workLocations: string[] = ["AH Winkel", "AH ToGo", "Etos"];
  selectedWorkLocation = "AH Winkel";

  constructor(
    private utilService: UtilService,
    private router: Router,
    private userService: UserService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.utilService.setTitle("Account compleet maken");
    this.isLoading = false;
  }

  onStudentSignUpFormSubmit() {
    if (this.studentSignUpForm.valid) {
      this.getUser();
    }
  }

  getUser() {
    this.userService
      .getUserExtended()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((userExtended) => {
        this.userExtended = userExtended;
        this.userExtended.control_answer_1 =
          this.studentSignUpForm.get("employeeID")?.value as string;
        this.userExtended.control_answer_2 =
          this.selectedWorkLocation + " " + this.router.url;
        this.updateUser();
      });
  }

  updateUser() {
    this.userService.updateUserExtended(this.userExtended).subscribe(() => {
      this.setState("", "Je wordt nu doorverwezen naar de trainingen", true);
      this.router.navigateByUrl("/home/vakken");
    });
  }

  onWorkLocationClicked(workLocation: string) {
    this.selectedWorkLocation = workLocation;
  }

  onHowEmployeeNrClicked() {
    var modalRef = this.modalService.open(EmployeeNrModalComponent, {
      size: "l",
    });
  }

  setState(errorMessage: string, loadingMessage: string, isLoading: boolean) {
    this.errorMessage = errorMessage;
    this.loadingMessage = loadingMessage;
    this.isLoading = isLoading;
  }

  ngOnDestroy() {
    (window as any).fcWidget.destroy();
    this.unsubscribe.next({});
    this.unsubscribe.complete();
  }
}
