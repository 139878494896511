export const environment = {
  production: false,
  environmentName: "staging",
  apiEndpoint: "https://staging.onlineslagen.nl/api",
  apiEndpointV2: "https://staging.onlineslagen.nl/api/v2",
  apiEndpointV3: "https://staging.onlineslagen.nl/api/v3",
  staticFilesEndpoint: "https://ams3.digitaloceanspaces.com/os-file-storage",
  staticFieldCdnEndpoint:
    "https://os-file-storage.ams3.cdn.digitaloceanspaces.com",
  wordpressApiKey: "d8dqNeKPLYmQBT77PP73f4rcyp4B",
  djangoApiKey: "NMktRSNa8fXe3EeipLtt3LFxMqKT",
};
