import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { NgIf } from "@angular/common";

@Component({
  standalone: true,
  selector: "shared-title",
  templateUrl: "./title.component.html",
  styleUrls: ["./title.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf]
})
export class TitleComponent {
  @Input() title!: string;
  @Input() titlePart2!: string;
}
