import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Chip } from "@app/models/Chip";
import { CommonModule } from "@angular/common";

@Component({
  standalone: true,
  selector: "shared-chip-cloud-v2",
  templateUrl: "./chip-cloud-v2.component.html",
  styleUrls: ["./chip-cloud-v2.component.scss"],
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipCloudV2Component {
  @Input('chips') set _chips(chips: Chip[]) {
    this.chips = chips;
    this.selectedKeys = [];
  }
  @Input('selectedChips') set _selectedChips(chips: Chip[]) {
    this.setSelectedKeys(chips);
  }
  @Input() disabled: boolean = false;
  @Input() mode: "single" | "multiple" = "single";
  @Input() direction: "horizontal" | "vertical" = "horizontal";
  @Input() justify: "start" | "end" | "center" | "between" | "around" = "start";
  @Input() title?: string;

  @Output() chipSelected = new EventEmitter<Chip>();
  @Output() selectedChipsChange = new EventEmitter<Chip[]>();

  protected selectedKeys: any[] = [];
  protected chips!: Chip[];
  protected selectedChips!: Chip[];

  onChipClicked(chip: Chip) {
    if (!this.disabled) {
      if (this.mode === "single") {
        this.selectedChips = [chip];
      } else {
        if (this.selectedChips.includes(chip)) {
          this.selectedChips = this.selectedChips.filter((c) => c !== chip);
        } else {
          this.selectedChips = [...this.selectedChips, chip];
        }
      }
    }

    this.selectedKeys = this.selectedChips.map((c) => c.key);
    this.chipSelected.emit(chip);
    this.selectedChipsChange.emit(this.selectedChips);
  }

  private setSelectedKeys(selectedChips: Chip[]): void {
    this.selectedChips = selectedChips;

    if (selectedChips && selectedChips.length > 0) {
      this.selectedKeys = selectedChips.map((c) => c?.key);
    } else {
      this.selectedKeys = [];
    }
  }
}
