import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

enum Intervals {
  YEAR,
  MONTH,
  WEEK,
  DAY,
  HOUR,
  MINUTE,
  SECOND
}

@Pipe({ standalone: true, name: "dateAgo" })
export class DateAgoPipe implements PipeTransform {
  private readonly intervals: Record<Intervals, number> = {
    [Intervals.YEAR]: 31536000,
    [Intervals.MONTH]: 2592000,
    [Intervals.WEEK]: 604800,
    [Intervals.DAY]: 86400,
    [Intervals.HOUR]: 3600,
    [Intervals.MINUTE]: 60,
    [Intervals.SECOND]: 1
  };

  private readonly intervalsSignature: Record<Intervals, { singular: string, plural: string }> = {
    [Intervals.YEAR]: { singular: 'jaar', plural: 'jaren' },
    [Intervals.MONTH]: { singular: 'maand', plural: 'maanden' },
    [Intervals.WEEK]: { singular: 'week', plural: 'weken' },
    [Intervals.DAY]: { singular: 'dag', plural: 'dagen' },
    [Intervals.HOUR]: { singular: 'uur', plural: 'uur' },
    [Intervals.MINUTE]: { singular: 'minuut', plural: 'minuten' },
    [Intervals.SECOND]: { singular: 'seconde', plural: 'seconden' }
  }

  transform(value: Date): string {
    const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);

    if (seconds < 10) // Just now
      return 'Net nu';

    let counter: number;
    for (const i in this.intervals) {
      counter = Math.floor(seconds / this.intervals[i as unknown as Intervals]);
      if (counter > 0)
        if (counter === 1) {
          return counter + ' ' + this.intervalsSignature[i as unknown as Intervals].singular + ' geleden';
        } else {
          return counter + ' ' + this.intervalsSignature[i as unknown as Intervals].plural  + ' geleden';
        }
    }

    return '';
  }
}
