import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "@app/auth/auth.service";
import { PreSignUpUser } from "@app/models/PreSignUpUser";
import { UserExtended } from "@app/user/models/UserExtended";
import { UtilService } from "@app/shared/services/util.service";
import { CookieService } from "ngx-cookie-service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SchoolService } from "../../school.service";

@Component({
  selector: "app-ah",
  templateUrl: "./ah.component.html",
  styleUrls: ["./ah.component.scss"],
})
export class AhComponent implements OnInit {
  studentSignUpForm = new FormGroup({
    firstName: new FormControl("", Validators.required),
    lastName: new FormControl("", Validators.required),
    email: new FormControl("", [Validators.required, Validators.email]),
    password: new FormControl("", [
      Validators.required,
      Validators.minLength(11),
    ]),
  });

  private unsubscribe = new Subject();

  loadingMessage!: string;
  errorMessage!: string;
  isLoading!: boolean;
  showLoginMessage!: boolean;

  userExtended!: UserExtended;

  user!: PreSignUpUser;

  showPassword!: boolean;

  constructor(
    private schoolService: SchoolService,
    private authService: AuthService,
    private cookieService: CookieService,
    private utilService: UtilService,
    private router: Router
  ) {}

  ngOnInit() {
    if (UtilService.isSchool24()) {
      window.open("https://portal.onlineslagen.nl/ah", "_self");
    }
    this.utilService.setTitle("AH - Aanmelden");
    this.isLoading = false;
  }

  onStudentSignUpFormSubmit() {
    if (this.studentSignUpForm.valid && !this.isLoading) {
      this.user = this.utilService.createPreSignUpUser(
        (this.studentSignUpForm.get("email")?.value ?? '').toLowerCase(),
        this.studentSignUpForm.get("password")?.value ?? '',
        this.studentSignUpForm.get("firstName")?.value ?? '',
        this.studentSignUpForm.get("lastName")?.value ?? '',
        "",
        "",
        ""
      );
      this.studentSignUpForm.disable();
      this.setState("", "Account aanmaken...", true, false);
      this.enrollSchool();
    }
  }

  enrollSchool() {
    this.schoolService.enrollAhSchool(this.user).subscribe({
      next: (response) => {
        if (response.status === 201) {
          this.setState("", "Account aangemaakt! Inloggen...", true, false);
          this.loginUserPortal();
        } else {
          this.setState("Email is al in gebruik", "", false, false);
          this.studentSignUpForm.enable();
        }
      },
      error: () => {
        this.handleError();
        this.studentSignUpForm.enable();
      },
    });
  }

  loginUserPortal() {
    this.authService
      .learningPortalLogin(this.user)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (output_portal) => {
          this.cookieService.set("token", output_portal.key);
          this.setState(
            "",
            "Account aangemaakt! Nu nog je informatie invullen",
            true,
            false
          );
          this.router.navigateByUrl("/ah-account-afmaken");
        },
        error: (error) => {
          this.handleLoginError(error);
        },
      });
  }

  onGoToLogin() {
    this.router.navigate(["auth", "login"], {
      queryParams: { email: this.studentSignUpForm.get("email")?.value },
    });
  }

  handleLoginError(error: HttpErrorResponse) {
    if (error.status == 401) {
      this.setState("", "Accountgegevens kloppen niet.", false, true);
    } else {
      this.handleError();
    }
  }

  handleError() {
    this.setState(
      "",
      "Er ging iets onverwacht fout. Probeer het opnieuw of neem contact met ons op.",
      false,
      true
    );
  }

  setState(
    errorMessage: string,
    loadingMessage: string,
    isLoading: boolean,
    showLoginMessage: boolean
  ) {
    this.errorMessage = errorMessage;
    this.loadingMessage = loadingMessage;
    this.isLoading = isLoading;
    this.showLoginMessage = showLoginMessage;
  }

  ngOnDestroy() {
    (window as any).fcWidget.destroy();
    this.unsubscribe.next({});
    this.unsubscribe.complete();
  }
}
