<div class="">
  <div class="d-flex align-items-center">
    <div *ngIf="label" class="font-14 me-2">
      {{ label }}
    </div>
    <i *ngIf="icon" class="{{ icon }} font-16 me-2 os-text-blue"></i>
    <div class="progress-bar {{ size }}">
      <span
        class="progress-bar-piece os-background-blue"
        [style.width.%]="width"
      ></span>
      <span
        class="progress-bar-piece"
        style="background-color: #dddddd"
        [style.width.%]="100 - width"
      ></span>
    </div>
  </div>
  <div *ngIf="caption" class="flex-between-center font-14 os-semi-bold ms-4">
    <div>{{ caption }}</div>

    <div>
      <span
        [ngClass]="{
          'text-secondary': _achieved < potential
        }"
        >{{ _achieved | number : "1.0-1" }}</span
      >
      <span class="text-secondary" style="padding-left: 4px; padding-right: 4px"
        >|</span
      >
      <span>{{ potential | number : "1.0-1" }}</span>
    </div>
  </div>
</div>

<!-- [ngClass]="{
  'os-text-red': width > 0 && width < 25,
  'os-text-orange': width >= 25 && width < 50,
  'os-text-yellow': width >= 50 && width < 75,
  'os-text-lightgreen': width >= 75
}"

[ngClass]="{
  'os-background-danger': width < 25,
  'os-background-orange': width >= 25 && width < 50,
  'os-background-yellow': width >= 50 && width < 75,
  'os-background-lightgreen': width >= 75
}" -->
