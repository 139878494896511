<span
  *ngIf="size === 'regular' || size === 'large' || size === 'xl'"
  class="d-flex"
  [ngClass]="{
    'os-light-red-border': grade < 5.5,
    'os-light-orange-border': grade < 6.5 && grade >= 5.5,
    'os-light-green-border': grade >= 6.5,
    'os-light-grey-border': grade === undefined,
    'box-points ': size === 'regular',
    'box-points-large ': size === 'large',
    'box-points-xl ': size === 'xl'
  }"
  style="width: max-content"
>
  <span
    class="os-semi-bold"
    [ngClass]="{
      'red-background': grade < 5.5,
      'orange-background': grade < 6.5 && grade >= 5.5,
      'green-background': grade >= 6.5,
      'lightgrey-background': grade === undefined,
      'points os-bold font-14': size === 'regular',
      'points-large os-bold': size === 'large',
      'points-xl os-bold font-18': size === 'xl'
    }"
  >
    <span
      *ngIf="grade !== undefined"
      [ngClass]="{
        'px-05': grade === 10
      }"
    >
      {{ grade !== 10 ? (grade | number : "1.1-1") : grade }}
    </span>
    <span *ngIf="grade === undefined" class="px-2"> - </span>
  </span>
</span>

<span
  *ngIf="size === 'small'"
  class="d-flex os-semi-bold font-14 points"
  style="box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05)"
  [ngClass]="{
    'red-background': grade < 5.5,
    'orange-background': grade < 6.5 && grade >= 5.5,
    'green-background': grade >= 6.5,
    'lightgrey-background': grade === undefined
  }"
>
  <span *ngIf="grade !== undefined">
    {{ grade | number : "1.0-1" }}
  </span>
  <span *ngIf="grade === undefined" class="px-2"> - </span>
</span>
