import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoadingSpinnerComponent } from "../loading-spinner/loading-spinner.component";

@Component({
  standalone: true,
  selector: "loading",
  templateUrl: "./loading.component.html",
  styleUrls: ["./loading.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, LoadingSpinnerComponent]
})
export class LoadingComponent {
  @Input() loadingTitle!: string;
  @Input() loadingSubtitle!: string;
}
