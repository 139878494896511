<div class="dark-background"></div>

<div class="no-background ">
  <div
    (clickOutside)="onClickOutside(); $event.stopPropagation()"
    class="modal-body mx-2 mx-md-auto col-md-8 col-xl-4 col-xxl-3"
  >
    <div class="py-4 px-0 col-10 mx-auto">
      <div
        class="os-semi-bold font-16 mb-4 text-center"
        [innerText]="title"
      ></div>
      <div class="flex-between">
        <ng-container *ngTemplateOutlet="customButtonsTemplate ? customButtonsTemplate : defaultButtonsTemplate"></ng-container>

        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>

<ng-template #defaultButtonsTemplate>
  <shared-button
    [text]="cancelButtonText"
    [style]="'secondary'"
    (click)="closeWindow()"
  >
  </shared-button>
  <shared-button
    [text]="verifyButtonText"
    [isLoading]="isLoading"
    (click)="onVerifyClicked()"
  >
  </shared-button>
</ng-template>
